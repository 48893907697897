import React from 'react'

import { IonIcon } from '@ionic/react'
import { logOutOutline, createSharp } from 'ionicons/icons'
import content from 'util/content'

import styles from './NavbarWorkspaces.module.scss'
// import EditableNavLink from 'components/basepaws/utils/EditableNavLink'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

const NavbarWorkspaces = (props) => {
  const { user, onLogout, closePopover, onBlur } = props
  // const { organizations, organizationActive, user, onLogout, isAdminOrVet, closePopover } = props

  return (
    <>
      {/* {isAdminOrVet &&
      <>
        <div className={styles.content}>
          <h3 className="Running_head_3 text_medium_grey">{content.get('NAVBAR.POPOVER.OPEN.SECTION1')}</h3>
          {(organizations || []).map(organization =>
            <a key={organization.id} className={`${styles.workspaces} ${parseInt(organizationActive) === parseInt(organization.id) ? styles.active : ''}`}
            // onClick={(e) => {
            //   onChangeWorkspace(organization)
            //   setShowPopover({ showPopover: false, event: e })
            // }
            // }
            >
              <IonAvatar slot="start">
                <IonImg src="assets/images/basepaws-mycats-add-picture.svg" alt="Pet avatar"/>
              </IonAvatar>
              <h5 className="ion-no-margin">{organization.name}</h5>
            </a>
          )}
          <a className={`${styles.workspaces} ${parseInt(organizationActive) === 0 ? styles.active : ''}`}
            // onClick={
            //   (e) => {
            //     onChangeWorkspace(0)
            //     setShowPopover({ showPopover: false, event: e })
            //   }
            // }
            >
            <IonAvatar slot="start">
              <IonImg src="assets/images/basepaws-mycats-add-picture.svg" alt="Pet avatar"/>
            </IonAvatar>
            <h5 className="ion-no-margin">{content.get('NAVBAR.POPOVER.OPEN.MY_WORKSPACE')}</h5>
          </a>
          {
          <div className="ion-margin-top">
            <p className="Body_2 ion-no-margin"><span>{content.get('NAVBAR.POPOVER.OPEN.HOSPITAL.QUESTION')}</span><br/>
              <EditableNavLink
                to=""
                className="Body_2_bold"
              >
                {content.get('NAVBAR.POPOVER.OPEN.HOSPITAL.LINK_NEW')}
              </EditableNavLink>
            </p>
          </div>
          }
        </div>
      <hr />
      </>
      } */}
      <div className={styles.content}>
        <h3 className="Running_head_3 text_dark_grey">{content.get('NAVBAR.POPOVER.OPEN.SECTION2')}</h3>
        <h4 className="Heading_6 text_black mb-0">{user?.first_name} {user?.last_name}</h4>
        <p className="Body_2 text_dark_grey mt-0">{user?.email}</p>
        <div className="d-flex ion-justify-content-between ion-align-items-center">
          <EditableIonButton
            mode="md"
            fill="outline"
            shape="round"
            className="logout default_button"
            onClick={closePopover}
            routerLink="/profile/edit"
          >
            <IonIcon slot="start" icon={createSharp}/>
            {content.get('NAVBAR.POPOVER.OPEN.BUTTON.EDIT_PROFILE')}
          </EditableIonButton>
          <EditableIonButton
            fill="clear"
            className={`${styles.logout} default_button`}
            onClick={onLogout}
            onIonBlur={onBlur}
            >
            {content.get('NAVBAR.POPOVER.OPEN.BUTTON.LOGOUT')}
            <IonIcon slot="end" icon={logOutOutline}/>
          </EditableIonButton>
        </div>
      </div>
    </>
  )
}

export default NavbarWorkspaces
