import { updateObject } from '../../util/updateObject'

const initialState = {
  test: 'lalala'
}

const changeHere = (state = initialState, action) => {
  return updateObject(state, {
    test: 'lelele'
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'testAction': return changeHere(state, action)

    default: return state
  }
}

export default reducer
