import React from 'react'

import { IonCardContent, IonIcon, IonImg, IonCardHeader, IonRow, IonCol, IonCardTitle } from '@ionic/react'
import { arrowRedoCircle, arrowForward } from 'ionicons/icons'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

import styles from './ChartCard.module.scss'

const ChartCard = () => {
  return (
    <>
      <IonCardHeader className={styles.cat_single_card_header}>
        <div><IonImg src="/assets/icon/basepaws-icon-report-type-health-markers.svg" alt="Report icon" /></div>
        <div className={`${styles.cat_single_card_subheader} w-100`}>
          <IonRow className="ion-justify-content-between ion-align-items-center">
            <IonCol size-md="6" size="12">
              <IonCardTitle className="Heading_4 ion-text-start">Card Title</IonCardTitle>
            </IonCol>
            <IonCol size-md="6" size="12" className="ion-text-end">
              <div className="d-flex ion-justify-content-end">
                <h4 className="Running_head_3 text_medium_grey ion-no-margin mr-3">Last updated</h4>
                <h4 className="Running_head_3 text_grey ion-no-margin">2020 may 30</h4>
              </div>
            </IonCol>
          </IonRow>
        </div>
      </IonCardHeader>
      <IonCardContent className={styles.card_content}>
        <div><IonImg src="/assets/icon/basepaws-icon-report-type-health-markers.svg" alt="Report icon" /></div>
        <p className="Body_1 text_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam gravida vitae justo sagittis suscipit. Donec sollicitudin erat et lacinia cursus. </p>
      </IonCardContent>

      <div className={styles.card_footer}>
        <EditableIonButton
          mode="md"
          color="primary"
          fill="clear"
          className="button-link default_button"
        >
          <IonIcon color="primary" slot="start" icon={arrowRedoCircle}/>
          Share Insights
        </EditableIonButton>
        <EditableIonButton
          mode="md"
          color="primary"
          fill="solid"
          shape="round"
          className="default_button"
        >
          View Report
          <IonIcon color="light" slot="end" icon={arrowForward}/>
        </EditableIonButton>
      </div>
    </>
  )
}

export default ChartCard
