import React from 'react'
import { IonRow, IonCol } from '@ionic/react'

const DetailSection = (props) => {
  return (
    <>
      <IonRow className="ion-justify-content-center ion-margin-vertical">
        <IonCol size-lg="8" size-md="10" size="12" className="ion-text-center">
          <h3 className="Heading_3 text_sad_purple ion-text-center">{props.title}</h3>
          <p className="Lead_2 text_dark_grey ion-text-center">{props.description}</p>
        </IonCol>
        <IonCol size-lg="8" size-md="10" size="12" className="ion-text-center">
            {props.children}
        </IonCol>
      </IonRow>
    </>
  )
}

export default DetailSection
