import Resizer from 'react-image-file-resizer'

import store from '../store/store'

import { isUserAVet, getVetPortalUrl } from './utils'

class Services {
  constructor (gateway, ws) {
    this.gateway = gateway
    this.ws = ws
  }

  async get (url) {
    if (url.startsWith('/')) url = `${this.gateway}${url}`
    const result = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    })
    if (result.status !== 200) { throw await result.json() }

    return await result.json()
  }

  async delete (url) {
    if (url.startsWith('/')) url = `${this.gateway}${url}`
    const result = await fetch(url, {
      method: 'DELETE',
      credentials: 'include'
    })
    if (result.status !== 200) { throw await result.json() }

    // reload the user
    this.user()
    console.log('user reloaded')

    return await result.json()
  }

  async put (url, data, options = {}) {
    if (url.startsWith('/')) url = `${this.gateway}${url}`
    const result = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      ...options
    })

    // reload the user
    this.user()
    console.log('user reloaded')

    if (result.status !== 200) { throw await result.json() }

    return await result.json()
  }

  async post (url, data) {
    if (url.startsWith('/')) url = `${this.gateway}${url}`
    const result = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })

    // reload the user
    this.user()
    console.log('user reloaded')

    if (result.status !== 200) { throw await result.json() }

    return await result.json()
  }

  async logout () {
    return this.get('/auth/logout').then(() => {
      localStorage.removeItem('user')
      this._user = null
      store.setUser(this._user)
    })
  }

  reloadUser () {
    this._user = null
    store.setUser(this._user)
  }

  getUser () {
    if (!this._user) {
      this._user = this.user()
    }
    return this._user
  }

  async content (key, value) {
    if (!key && !value) {
      return servicesNew.get('/content/ui-account')
    } else {
      return servicesNew.put(`/content/ui-account/${key}`, value, { headers: { 'Content-Type': 'text/plain' } })
    }
  }

  async contentDeleteKey (key) {
    if (key) {
      return servicesNew.delete(`/content/ui-account/${key}`)
    } else {
      return false
    }
  }

  async getResearchPrograms () {
    const programs = await servicesNew.get('/researchPrograms')
    return programs
  }

  async login (data) {
    // data {
    //   email: string
    //   password: string
    // }
    const _user = await services.post('/auth/login', data)
    this._user = _user

    return _user
  }

  async user () {
    try {
      const fromBackend = true
      const user = fromBackend
        ? await servicesNew.get('/user/me')
        : {
            id: '0e13ef13-a428-4a7f-92b6-f774cbf18831',
            first_name: 'ivan',
            last_name: 'moka',
            email: 'ivan+basepaws@moka.tv',
            phone_number: '',
            reports: [
              {
                id: '8df4ec88-7ad1-484b-a701-40a4a0309d30',
                type: ['breed'],
                code: '19151248385042',
                productName: 'type_breed',
                share:
                'https://my.basepaws.com/2020-09/report/8df4ec88-7ad1-484b-a701-40a4a0309d30/share',
                view: 'https://my.basepaws.com/2020-09/report/8df4ec88-7ad1-484b-a701-40a4a0309d30/view',
                url: 'https://my.basepaws.com/2020-09/report/8df4ec88-7ad1-484b-a701-40a4a0309d30/view',
                activated: '2022-03-22T17:02:02.237Z',
                history: [
                  {
                    status: 'registered',
                    time: '2022-03-22T17:02:02.237Z'
                  }
                ]
              }
            ],
            pets: [
              {
                id: '03176145-b010-40ce-8dbf-e36c8f5f3cd6',
                name: 'moka cat1',
                gender: 'male',
                born_on:
                'Tue Mar 22 2022 13:55:15 GMT-0300 (hora estándar de Argentina)',
                born_date:
                'Tue Mar 22 2022 13:55:15 GMT-0300 (hora estándar de Argentina)',
                birth_location: {
                  country: null,
                  state: null,
                  city: null,
                  adopted: null
                },
                handle: 'mokacat1',
                images: [],
                created: '2022-03-22T16:55:39.661Z',
                updated_at: '2022-03-22T16:55:39.661Z',
                surveys: [
                  {
                    id: 42,
                    name: 'Phenotype Survey',
                    complete: false,
                    url: 'https://basepaws.typeform.com/to/VXlnEYDF#email=ivan+basepaws@moka.tv&source=account&animal=mokacat1'
                  }
                ],
                reports: [],
                researchPrograms: [{
                  id: 'gama',
                  complete: true
                },
                {
                  id: 'beta',
                  complete: true
                }]
              },
              {
                id: '965dbfea-995b-4b33-ae31-2a83b2439898',
                name: 'moka cat 2',
                gender: 'female',
                born_on:
                'Mon Mar 21 2022 13:55:00 GMT-0300 (hora estándar de Argentina)',
                born_date:
                'Mon Mar 21 2022 13:55:00 GMT-0300 (hora estándar de Argentina)',
                birth_location: {
                  country: null,
                  state: null,
                  city: null,
                  adopted: null
                },
                handle: 'mokacat2',
                images: [],
                created: '2022-03-22T16:56:11.931Z',
                updated_at: '2022-03-22T16:56:11.931Z',
                surveys: [
                  {
                    id: 42,
                    name: 'Phenotype Survey',
                    complete: false,
                    url: 'https://basepaws.typeform.com/to/VXlnEYDF#email=ivan+basepaws@moka.tv&source=account&animal=mokacat2'
                  }
                ],
                reports: [],
                researchPrograms: [{
                  id: 'alpa',
                  complete: true
                }]
              },
              {
                id: 'd8635780-3f50-445d-9d19-b9ee9ea18188',
                name: 'cat moka 3',
                gender: 'male',
                born_on:
                'Tue Mar 08 2022 13:56:00 GMT-0300 (hora estándar de Argentina)',
                born_date:
                'Tue Mar 08 2022 13:56:00 GMT-0300 (hora estándar de Argentina)',
                birth_location: {
                  country: null,
                  state: null,
                  city: null,
                  adopted: null
                },
                handle: 'catmoka3',
                images: [],
                created: '2022-03-22T16:56:55.273Z',
                updated_at: '2022-03-22T16:56:55.273Z',
                surveys: [
                  {
                    id: 42,
                    name: 'Phenotype Survey',
                    complete: false,
                    url: 'https://basepaws.typeform.com/to/VXlnEYDF#email=ivan+basepaws@moka.tv&source=account&animal=catmoka3'
                  }
                ],
                reports: [
                  {
                    id: '8df4ec88-7ad1-484b-a701-40a4a0309d30',
                    type: ['breed'],
                    code: '19151248385042',
                    productName: 'type_breed',
                    share:
                    'https://my.basepaws.com/2020-09/report/8df4ec88-7ad1-484b-a701-40a4a0309d30/share',
                    view: 'https://my.basepaws.com/2020-09/report/8df4ec88-7ad1-484b-a701-40a4a0309d30/view',
                    url: 'https://my.basepaws.com/2020-09/report/8df4ec88-7ad1-484b-a701-40a4a0309d30/view',
                    activated: '2022-03-22T17:02:02.237Z',
                    history: [
                      {
                        status: 'registered',
                        time: '2022-03-22T17:02:02.237Z'
                      }
                    ]
                  }
                ],
                researchPrograms: [{
                  id: 'alpa',
                  complete: true
                }]
              }
            ],
            products: [
              {
                id: '8df4ec88-7ad1-484b-a701-40a4a0309d30',
                type: ['breed'],
                code: '19151248385042',
                productName: 'type_breed',
                share:
                'https://my.basepaws.com/2020-09/report/8df4ec88-7ad1-484b-a701-40a4a0309d30/share',
                view: 'https://my.basepaws.com/2020-09/report/8df4ec88-7ad1-484b-a701-40a4a0309d30/view',
                url: 'https://my.basepaws.com/2020-09/report/8df4ec88-7ad1-484b-a701-40a4a0309d30/view',
                activated: '2022-03-22T17:02:02.237Z',
                history: [
                  {
                    status: 'registered',
                    time: '2022-03-22T17:02:02.237Z'
                  }
                ]
              }
            ]
          }

      console.log('user: ', user)

      this._user = {
        ...user,
        roles: user.roles ?? []
      }
    } catch (e) {
      this._user = null
    } finally {
      if (this._user) {
        // This will redirect vet users to the vetPortal, preventing them to access the consumerApp. See https://github.com/basepaws/ui-accountApp/issues/160
        const isVet = isUserAVet(this._user)
        if (isVet) {
          location.href = getVetPortalUrl()
        }

        try {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'authentication',
            userId: this._user.id
          })
        } catch (e) { }

        this._user.pets = this._user.pets?.sort((a, b) => a.name.localeCompare(b.name))
        this._user.phone_number = this._user?.phone_number ?? ''

        store.setUser(this._user)
        store.calculatePendingTasks(this._user.pets)
        if (this._user?.lastOrganization) {
          store.setActiveOrganization(this._user?.lastOrganization)
          store.setOrganization(this._user?.organizations.filter((organization) => { return organization.id === this._user.lastOrganization })[0])
        }
      }
    }
    return this._user
  }

  async setActiveOrganization (organization) {
    try {
      // const organizationData = await services.post(`/users/${handle}`,{ lastOrganization: organization})

      return organization
    } catch (e) {
      return null
    }
  }

  async getSummary (organizationId) {
    try {
      const orgSummary = await services.get(`/organization/${organizationId}`)

      // this._organizationActiveData.patients = orgSummary.patients
      if (Object.keys(orgSummary.kitsInfo).length === 0) {
        orgSummary.kitsInfo = {
          available: 0,
          activated: 0,
          progress: 0,
          done: 0
        }
      }

      store.setOrganization(orgSummary)
      store.setKitsInfo(orgSummary.kitsInfo)

      return orgSummary
    } catch (e) {
      return false
    }
  }

  async getCountries () {
    const countryList = await servicesNew.get('/geographic')
    countryList.forEach((country, index) => {
      countryList[index] = {
        ...country,
        label: country.name,
        value: country.id
      }
    })

    return countryList
  }

  async getStates (country) {
    const stateList = await servicesNew.get(`/geographic/${country}/states`)
    stateList.forEach((state, index) => {
      stateList[index] = {
        ...state,
        label: state.name,
        value: state.id
      }
    })

    return stateList
  }

  async getCities (country, state) {
    const cityList = await servicesNew.get(`/geographic/${country}/${state}/cities`)
    cityList.forEach((city, index) => {
      cityList[index] = {
        ...city,
        label: city.name,
        value: city.id
      }
    })

    return cityList
  }

  async getPatients (
    organizationId,
    listParams = {
      itemsPerPage: 10,
      cursor: 0,
      search: '',
      orderBy: 'created_des'
    }
  ) {
    if (!listParams.itemsPerPage) listParams.itemsPerPage = 10
    if (!listParams.cursor) listParams.cursor = 0
    if (!listParams.orderBy) listParams.orderBy = 'created_des'
    if (!listParams.search) listParams.search = ''

    let urlParams = `?p=${listParams.cursor}&n=${listParams.itemsPerPage}`
    urlParams += `&o=${listParams.orderBy}`

    if (listParams.search) {
      const search = encodeURIComponent(listParams.search)
      urlParams += `&q=${search}`
    }

    try {
      const vetPatients = await services.get(`/organization/${organizationId}/patients${urlParams}`)

      store.setPatients(vetPatients.patients)

      return {
        orderBy: listParams.orderBy,
        search: listParams.search,
        cursor: listParams.search !== 'NO_MORE' ? vetPatients.next : 'NULL',
        itemsPerPage: vetPatients.items_per_page
      }
    } catch (e) {
      return {
        orderBy: '',
        search: '',
        cursor: '',
        itemsPerPage: ''
      }
    }
  }

  async getPatient (handle) {
    try {
      const patient = await services.get(`/animal/${handle}`)
      return patient
    } catch (e) {
      return null
    }
  }

  async getResources (organizationId) {
    try {
      const vetResources = await services.get(`/organization/${organizationId}/resources/`)
      store.setResources(vetResources)
      return true
    } catch (e) {
      return null
    }
  }

  async getKitsInfo (
    organizationId,
    listParams = {
      itemsPerPage: 10,
      cursor: 0,
      search: '',
      orderBy: 'created_des'
    }) {
    if (!listParams.itemsPerPage) listParams.itemsPerPage = 10
    if (!listParams.cursor) listParams.cursor = 0
    if (!listParams.orderBy) listParams.orderBy = 'created_des'
    if (!listParams.search) listParams.search = ''

    let urlParams = `?p=${listParams.cursor}&n=${listParams.itemsPerPage}`
    urlParams += `&o=${listParams.orderBy}`

    if (listParams.search) {
      const search = encodeURIComponent(listParams.search)
      urlParams += `&q=${search}`
    }

    try {
      const vetKits = await services.get(`/organization/${organizationId}/kit${urlParams}`)
      console.log(vetKits.kitsInfo)

      // const vetKitsInfo = await services.get(`/organization/${organizationId}/kit/info`)

      const returnObject = {
        orderBy: listParams.orderBy,
        search: listParams.search,
        cursor: listParams.search !== 'NO_MORE' ? vetKits.next : 'NULL',
        itemsPerPage: vetKits.items_per_page
      }
      const kitsInfo = {
        available: vetKits.kitsInfo.available, // old one was in its own endpoint and saved in vetKitsInfo
        activated: vetKits.kitsInfo.activated,
        progress: vetKits.kitsInfo.progress,
        done: vetKits.kitsInfo.done
      }

      store.setKitsInfo(kitsInfo)
      // store.setKits(vetKits.list) // old one
      store.setKits(vetKits.kits)

      return returnObject
    } catch (e) {
      return {
        orderBy: '',
        search: '',
        cursor: '',
        itemsPerPage: ''
      }
    }
  }

  async getContributors (
    organizationId,
    listParams = {
      itemsPerPage: 10,
      cursor: 0,
      search: '',
      orderBy: 'created_des'
    }
  ) {
    if (!listParams.itemsPerPage) listParams.itemsPerPage = 10
    if (!listParams.cursor) listParams.cursor = 0
    if (!listParams.orderBy) listParams.orderBy = 'created_des'
    if (!listParams.search) listParams.search = ''

    let urlParams = `?p=${listParams.cursor}&n=${listParams.itemsPerPage}`
    urlParams += `&o=${listParams.orderBy}`

    if (listParams.search) {
      const search = encodeURIComponent(listParams.search)
      urlParams += `&q=${search}`
    }
    try {
      const vetContributors = await services.get(`/organization/${organizationId}/invites${urlParams}`)

      store.setContributors(vetContributors.contributors)
      return {
        orderBy: listParams.orderBy,
        search: listParams.search,
        cursor: listParams.search !== 'NO_MORE' ? vetContributors.next : 'NULL',
        itemsPerPage: vetContributors.items_per_page
      }
    } catch (e) {
      return null
    }
  }

  async setContributors (organizationId, emails) {
    // eslint-disable-next-line prefer-const
    let emailsStrings = '?'

    emails.forEach(
      (email) => {
        emailsStrings += `email[]=${email}&`
      }
    )
    const emailsStringsClean = emailsStrings.slice(0, -1)
    console.log(`/organizations/${organizationId}/contributors${emailsStringsClean}`)
    const response = await services.post(`/organizations/${organizationId}/contributors${emailsStringsClean}`)
    console.log(response)
    console.log(store.contributors)
    if (response.length >= 1) {
      store.setContributors(response.contributors)
      return 'success'
    } else {
      return 'error'
    }
  }

  async setDeceased (pet, data) {
    let response
    try {
      pet.death_on = data.death_on
      pet.contact = data.contact
      if (pet.id) {
        response = await servicesNew.put(`/animal/${pet.id ?? ''}`, pet)
      }
    } catch (e) {
      console.log(e)
      response = e
    }

    store.calculatePendingTasks(this._user.pets)

    return response
  }

  async setVisible (pet, visible) {
    let response
    try {
      pet.visible = visible
      pet.hidden = !visible // backwards compatibility
      if (pet.id) {
        response = await services.put(`/animal/${pet.id ?? ''}`, pet)
      }
    } catch (e) {
      console.log(e)
      response = e
    }

    store.calculatePendingTasks(this._user.pets)

    return response
  }

  async setPets (pet) {
    let retStatus = false
    try {
      if (pet.profilePic !== null) {
        const avatar = await this.toBase64(pet.profilePic, 300, 300, 100)
        if (!pet.images) {
          pet.images = []
        }
        if (avatar) {
          pet.images[0] = { src: avatar, hit: 0 }
        }
      }

      if (pet.id) {
        await services.put(`/animal/${pet.id ?? ''}`, pet)
      } else {
        await services.post('/animal', pet)
      }
      retStatus = true
    } catch (e) {
      console.log(e)
    }

    store.calculatePendingTasks(this._user.pets)

    return retStatus
  }

  async setUser (User) {
    let retState = false
    try {
      await servicesNew.put(`/user/${User.id}`, User)
      retState = true
    } catch (e) { console.log(e) }
    return retState
  }

  async activateKit (kit, pet) {
    let retState = false
    try {
      // /kit/<kit id or barcode>/active/<animal id or handle>
      retState = await services.get(`/kit/${kit.id}/active/${pet.id}`)
    } catch (e) {
      console.log(e)
      // reject('Something bad happened')
    }
    // reload the user
    this.user()
    console.log('user reloaded')
    return retState
  }

  async setGallery (pet, images) {
    let avatar
    let response = null
    try {
      for (let index = 0; index < images.length; index++) {
        avatar = await this.toBase64(images[index], 1200, 1200, 75)
        pet.images.push({ src: avatar })
      }

      response = await services.put(`/animal/${pet.id}`, pet)
    } catch (e) {
      console.log(e)
      response = e
    }

    store.calculatePendingTasks(this._user.pets)

    return response
  }

  async removeImageGallery (pet, key) {
    try {
      await services.put(`/animal/${pet.id}`, pet)
    } catch (e) {
      console.log(e)
    }

    store.calculatePendingTasks(this._user.pets)

    return true
  }

  async getVetSharesReports () {
    // TODO: add pagination
    return await servicesNew.get('/vetshareReports')
  }

  async addPet (pet) {
    let response = null
    try {
      if (pet.profilePic) {
        if (pet.profilePic !== null) {
          const avatar = await this.toBase64(pet.profilePic, 300, 300, 100)
          if (!pet.images) {
            pet.images = []
          }
          if (avatar) {
            pet.images[0] = { src: avatar, hit: 0 }
          }
        }
      }

      response = await services.post('/animal', pet)
      console.log(response)
    } catch (e) {
      console.log(e)
      response = e
    }

    store.calculatePendingTasks(this._user.pets)

    return response
  }

  toBase64 (file, maxHeight, maxWidth, compression) {
    if (!file) return

    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(file, maxHeight, maxWidth, 'JPEG', compression, 0,
        uri => {
          resolve(uri)
        }, 'base64')
    })
  }

  async addContributor (organizationId, contributor) {
    const response = await services.post(`/organizations/${organizationId}/contributors`, contributor)
    if (response) {
      return true
    } else {
      return false
    }
  }

  async removeContributor (invitationId, organizationId) {
    store.removeContributor(invitationId)

    const response = await services.delete(`/organizations/${organizationId}/contributors/${invitationId}`)
    if (response) {
      return 'success'
    } else {
      return 'error'
    }
  }

  async resendInvitation (invitationId, organizationId) {
    try {
      const response = await services.post(`/organizations/${organizationId}/contributors/${invitationId}/send`)

      if (response.contributors) {
        return 'success'
      }
    } catch (error) {
      return 'error'
    }
  }

  async cancelInvitation (invitationId, organizationId) {
    store.cancelContributorInvitation(invitationId)
    const response = await services.post(`/organizations/${organizationId}/contributors/${invitationId}/cancel`)
    if (response) {
      return 'success'
    } else {
      return 'error'
    }
  }

  topbarDismiss (text) {
    const today = new Date()
    const nextShow = today.setDate(today.getDate() + 2) // this returns the next date in milliseconds
    localStorage.setItem('topbarAdds', btoa(JSON.stringify({ content: text, show: nextShow })))
  }

  topbarCheck (text) {
    const topbarAdds = localStorage.getItem('topbarAdds') && JSON.parse(atob(localStorage.getItem('topbarAdds')))
    const topbarText = text
    const today = new Date().getTime()
    let ret = true
    if (topbarAdds && topbarAdds.content === topbarText && topbarAdds.show > today) {
      ret = false
    }
    return ret
  }

  trackEvent (data) {
    // data = { category: string, action: string, label: string }
    try {
      window.dataLayer.push({
        event: 'gtm-event',
        'event-category': data.category,
        'event-action': data.action,
        'event-label': data.label
      })
    } catch (e) {
      console.log(e)
    }
  }

  link (url) {
    if (url.startsWith('/')) return `${this.gateway}${url}`
    return url
  }

  thumb (img, typePet = 'cat') {
    if (!img) return this.DEGAULT_PLACEHOLDERS[typePet]
    if (img.src?.startsWith('data:') || img.src?.startsWith('http')) return img.src
    return `${this.image(img.src)}?size=small`
  }

  big (img, typePet = 'cat') {
    if (!img) return this.DEGAULT_PLACEHOLDERS[typePet]
    if (img.src.startsWith('data:') || img.src.startsWith('http')) return img.src
    return this.image(img.src) + '?size=large'
  }

  image (url, typePet = 'cat') {
    if (!url) return this.DEGAULT_PLACEHOLDERS[typePet]
    if (url.startsWith('data:') || url.startsWith('http')) return url
    return `${this.gateway}${url}`
  }

  DEGAULT_PLACEHOLDERS = {
    cat: '/assets/images/basepaws-cat-default.svg',
    dog: '/assets/images/basepaws-dog-default.svg'
  }
}

const services = new Services('/2020-09', '')
const servicesNew = new Services('/2021-08', '')
export default services
export { servicesNew }
