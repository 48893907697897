import React from 'react'

import { IonRow, IonCol, IonIcon } from '@ionic/react'
import { checkmarkSharp } from 'ionicons/icons'
import styles from './SucessState.module.scss'

const SucessState = (props) => {
  const { title, text, content } = props

  return (
    <>
      <IonRow className="ion-justify-content-center w-100">
        <IonCol className="ion-text-center"
          size='11'
          size-md="10">
            <span className={`${styles.sucess_section_circle}`}>
              <IonIcon className={`${styles.sucess_section_icon}`} src={checkmarkSharp} />
            </span>
            <h1 className="Heading_2 text_sad_purple ion-text-center">
              {title}
            </h1>
          <IonRow className="ion-justify-content-center w-100">
              <IonCol className="ion-text-center"
                size="10"
                size-md="10">
                <p className="Lead_1 text_grey mt-0">
                  {text}
                </p>
              </IonCol>
          </IonRow>
          {content}
        </IonCol>
      </IonRow>
    </>
  )
}

export default SucessState
