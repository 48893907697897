import React from 'react'

import SimpleSlider from '../../../utils/SimpleSlider'
import UploadDraggedFile from '../../../utils/UploadDraggedFile'

const AccountCatGallery = ({ images, handleImageSubmit, handleImageRemove, handleOnClick }) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    centerPadding: '10px',
    centerMode: false,
    speed: 500,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          slidesToShow: 2
        }
      }
    ]
  }
  if (images) {
    const big = (images.filter((im) => im.hit !== 0) || []).length === 0
    return (
    <>
      <SimpleSlider
        images={images}
        settings={settings}
        handleImageRemove={handleImageRemove}
        handleOnClick={handleOnClick}
      />
      {handleImageSubmit && <UploadDraggedFile handleImageSubmit={handleImageSubmit} big={big}/>}
    </>
    )
  } else {
    return ''
  }
}

export default AccountCatGallery
