import React, { useState } from 'react'
import Slider from 'react-slick'
import content from 'util/content'

import { IonModal, IonContent, IonHeader, IonCol, IonRow, IonIcon } from '@ionic/react'
import { trashSharp, closeOutline } from 'ionicons/icons'
import FsLightbox from 'fslightbox-react'
import services from 'util/services'
import styles from './SimpleSlider.module.scss'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

const SimpleSlider = ({ images, settings, handleImageRemove }) => {
  const handleImageRemoveLocal = async (key) => {
    if (key !== false) {
      const leap = images.length - imageGallery.length
      const index = parseInt(key) + leap

      images.splice(index, 1)

      handleImageRemove(index)

      setRemove(false)
    }
  }

  const [remove, setRemove] = useState(false)
  const [keyRemove, setKeyRemove] = useState(false)

  const closeRemove = () => {
    setRemove(false)
  }

  const openRemove = (key) => {
    setKeyRemove(key)
    setRemove(true)
  }

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  })

  const openLightboxOnSlide = (number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    })
  }

  const onOpenLighbox = () => {
    const headers = document.getElementsByClassName('header-navbar')
    for (let index = 0; index < headers.length; index++) {
      const element = headers[index]
      element.classList.add('hide-navbar')
    }
  }

  const onCloseLighbox = () => {
    const headers = document.getElementsByClassName('header-navbar')
    for (let index = 0; index < headers.length; index++) {
      const element = headers[index]
      element.classList.remove('hide-navbar')
    }
  }

  const imageGallery = (images.filter((im) => im.hit !== 0) || []).map((e, i) => { return { index: i, src: `${e.src}` } })

  const removeVisible = (handleImageRemove !== undefined)

  return (
    <>
    {!removeVisible &&
      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={imageGallery.map((e) => e.src)}
        onClose={onCloseLighbox}
        onOpen={onOpenLighbox}
      />
    }
    <Slider {...settings} className="ion-margin-bottom">
      {imageGallery.map((element, key) =>
        <div
          key={`${key}`}
          className={`${styles.cat_single_gallery} ${removeVisible ? styles.cat_single_gallery_gradient : ''} ion-padding-horizontal`}
          onClick={() => !removeVisible && openLightboxOnSlide(key + 1)}
        >
          <div style={{ backgroundImage: `url(${services.big({ src: element.src })})` }}></div>
          {removeVisible &&
            <EditableIonButton
              mode="md"
              fill="clear"
              color="light"
              className={styles.remove_image_button}
              onClick={() => { openRemove(`${element.index}`) }}
            >
              {content.get('CAT_DETAIL.GALLERY.CTA_REMOVE_IMAGE')}
              <IonIcon slot="start" color="light" icon={trashSharp} />
            </EditableIonButton>
            }
        </div>
      )}
    </Slider>
    <IonModal isOpen={remove} onDidDismiss={closeRemove} class="modal-share-report modal-confirmation">
      <IonHeader>
        <EditableIonButton
          mode="md"
          fill="clean"
          color="dark"
          onClick={closeRemove}
          className={styles.modal_close_button}
        >
          <IonIcon color="dark" icon={closeOutline}/>
          Close
        </EditableIonButton>
      </IonHeader>
      <IonContent>
        <IonRow class="ion-justify-content-center">
          <IonCol size="11" size-md="7" className="ion-text-center ion-margin-bottom ion-padding-bottom">
            <h3 className="Heading_3 text_sad_purple ion-text-center">{content.get('CAT_DETAIL.GALLERY.MODAL.TITLE')}</h3>
            <p className="Lead_1 ion-text-center">{content.get('CAT_DETAIL.GALLERY.MODAL.TEXT')}</p>
            <EditableIonButton
              mode="md"
              fill="outline"
              color="dark"
              shape="round"
              className="mr-2 big_button"
              onClick={closeRemove}
            >
              {content.get('CAT_DETAIL.GALLERY.MODAL.CTA_CANCEL')}
            </EditableIonButton>
            <EditableIonButton
              mode="md"
              fill="solid"
              color="danger"
              shape="round"
              className="big_button"
              onClick={() => { handleImageRemoveLocal(keyRemove) }}
            >
              {content.get('CAT_DETAIL.GALLERY.MODAL.CTA_REMOVE')}
            </EditableIonButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonModal>
    </>
  )
}

export default SimpleSlider
