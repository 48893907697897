import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import EditableNavLink from 'components/basepaws/utils/EditableNavLink'

import { IonRow, IonCol, IonList, IonItem, IonInput, IonLabel, IonIcon, IonListHeader, IonCardContent } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import content from 'util/content'

import { Formik } from 'formik'
import * as Yup from 'yup'
import services from 'util/services'
import styles from './RecoverPasswordFormEmail.module.scss'
import Toaster from 'components/basepaws/utils/Toaster'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'

const RecoverPasswordFormEmail = ({ user }) => {
  const registerSchema = Yup.object().shape({})

  const [edited, setEdited] = useState(false)
  // const [isChanged, setIsChanged] = useState(false)

  const history = useHistory()

  // const onCancel = () => {
  //   history.goBack()
  // }

  const onSubmit = async (values, setSubmitting) => {
    try {
      const changePass = services.post('/auth/resetPassword', values)
    } catch (e) {
      console.log(e)
    }
    setSubmitting(false)
    history.push({
      pathname: '/recoverPasswordEmail/confirmation',
      state: { detail: emailAttempt.email }
    })
  }

  const [emailAttempt, setEmailAttempt] = useState({ email: '' })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        email: ''
      }}
      validationSchema={registerSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting)
        setEdited(true)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
        setFieldValue
      }) => (
        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && values.email !== '') {
              handleSubmit()
            }
          }}
        >
        <Toaster
          status={edited}
          onDidDismiss={() => {
            setEdited(false)
          }}
          header={content.get('RECOVERPASSWORD.EMAIL.TOAST.TITLE')}
          message={content.get('RECOVERPASSWORD.EMAIL.TOAST.TEXT')}
        />
          <IonCardContent className={styles.card_content}>
            <IonRow className="ion-justify-content-center">
              <IonCol size-md="12" size="12">
                <IonList className="form ion-margin-top">
                  <IonItem lines="none" className="item-no-padding ion-margin-bottom">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('RECOVERPASSWORD.EMAIL.FORM.EMAIL_TITLE')}</h4>
                        <p className="Body_2 text_dark_grey">{content.get('RECOVERPASSWORD.EMAIL.FORM.EMAIL_DESCRIPTION')}</p>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonInput
                      type="email"
                      name="email"
                      required
                      onIonChange={(event) => {
                        handleChange(event)
                        setEmailAttempt({ email: event.target.value })
                      }}
                    >
                    </IonInput>
                        {errors.born_on && touched.born_on
                          ? (
                          <IonItem className="ion-margin-bottom input-error-message">
                            <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.born_on}</div>
                          </IonItem>
                            )
                          : null}
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
          </IonCardContent>
          <div className={`${styles.card_footer} ion-justify-content-center`}>
            <LoaderButton
              type="submit"
              disabled={isSubmitting}
              color="primary"
              className="default_button"
              isSubmitting={isSubmitting}
            >
              {content.get('RECOVER_PASSWORD.EMAIL.FORM.SEND_EMAIL')}
            </LoaderButton>
            {/* <IonButton
              type="submit"
              disabled={isSubmitting}
              color="primary"
              fill="solid"
              shape="round"
              className="default_button"
              >
                Send Email
              </IonButton> */}
          </div>
          <p className="ion-text-center Body_1">
            {content.get('RECOVERPASSWORD.EMAIL.FORM.QUESTION.TEXT')}
            <EditableNavLink
              to="/login"
              className="ml-2"
            >
              {content.get('RECOVERPASSWORD.EMAIL.FORM.QUESTION.LINK_TEXT')}
            </EditableNavLink>
          </p>
          <hr className={styles.divider}/>
          <p className="ion-text-center Body_1">
            {content.get('RECOVERPASSWORD.EMAIL.FORM.QUESTION2.TEXT')}
            <EditableNavLink
              to="/register"
              className="ml-2"
            >
              {content.get('RECOVERPASSWORD.EMAIL.FORM.QUESTION2.LINK_TEXT')}
            </EditableNavLink>
          </p>
        </form>
      )}
    </Formik>
  )
}

export default RecoverPasswordFormEmail
