import React from 'react'
import { IonButton, IonPopover } from '@ionic/react'
import { NavLink } from 'react-router-dom'
import contentEditableAware from '../hooks/contentEditableAware'
import styles from './EditableIonButton.module.scss'

const EditableIonButton = (props) => {
  const editableContent = contentEditableAware()
  const [popoverState, setPopoverState] = React.useState({
    showPopover: false,
    event: null
  })
  const showOnClickButton = props.showOnClickButton ?? true

  const onMouseOverHandler = (e) => {
    e.persist()
    setPopoverState({ showPopover: true, event: e })
  }

  const handleClick = (e) => {
    if (editableContent) {
      e.preventDefault()
    } else {
      if (props.type !== 'submit') {
        props.onClick && props.onClick(e)
      }
    }
    setPopoverState({ showPopover: false, event: undefined })
  }

  return (
    <>
    {editableContent &&
      <IonPopover
        cssClass={styles.editableBackdrop}
        mode="ios"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setPopoverState({ showPopover: false, event: undefined })}
      >
        { props.type !== 'submit'
          ? <>
          {(props.routerLink || props.href) &&
          <NavLink
            to={`${props.routerLink || props.href}#key`}
            onClick={() => setPopoverState({ showPopover: false, event: undefined })}
          >
            Go to link ➚
          </NavLink>
          }
          {(props.onClick) && showOnClickButton &&
          <IonButton
            onClick={props.onClick}
          >
            Use button ➚
          </IonButton>
          }
        </>
          : <IonButton
              className={`${props.className}`}
              onClick={props.handleSubmit}
            >
              Submit
            </IonButton>
        }
      </IonPopover>
    }
    <IonButton
      {...props}
      ref={props.pref}
      type={editableContent ? 'button' : (props.type || 'button')}
      routerLink={editableContent && props.routerLink ? undefined : props.routerLink}
      onClick={handleClick}
      href={editableContent && props.href ? undefined : props.href}
      onMouseEnter={ editableContent ? onMouseOverHandler : undefined }
    >
      {props.children}
    </IonButton>
    </>
  )
}

export default EditableIonButton
