import React from 'react'
import { withIonLifeCycle, IonRow, IonCol, IonGrid, IonCard } from '@ionic/react'
import { connect } from 'react-redux'
import services from 'util/services'

import content from 'util/content'
import ChangePasswordForm from 'components/basepaws/account/ChangePasswordForm'

import styles from './AccountChangePassword.module.scss'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage.js'

class AccountChangePassword extends React.Component {
  render () {
    const user = this.props.user

    const form = (
      <IonCard className={styles.form_card}>
        <IonRow className="ion-justify-content-center">
          <IonCol size-md="6" size-lg="4" size="11">
            <ChangePasswordForm/>
          </IonCol>
        </IonRow>
      </IonCard>
    )

    return (
      <GeneralPage
        user={user}
        title={content.get('CHANGEPASSWORD.HEADER.TITLE')}
        headerStyle='center'
        actionStyle='left'
        pageClass={`${styles.cat_single}`}
        isNotFixed={true}>
        <IonGrid fixed>
          {form}
        </IonGrid>
      </GeneralPage>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(mapStateToProps, null)(withIonLifeCycle(AccountChangePassword))
