import React from 'react'
import EditableNavLink from 'components/basepaws/utils/EditableNavLink'
import styles from './LearnLinks.module.scss'

const LearnLinks = (props) => {
  const { closePopover } = props

  return (
    <div className={styles.content}>
      <div>
        <h3 className="Running_head_3 text_dark_grey">Cat Library</h3>
        <div className={styles.linksContainer}>
          <EditableNavLink
            to={'https://basepaws.com/cat-breeds'}
            aria-label={'Open Cat Breeds page'}
            onClick={closePopover}
          >
            Cat Breeds
          </EditableNavLink>
          <EditableNavLink
            to={'https://basepaws.com/cat-diseases'}
            aria-label={'Open Cat Diseases page'}
            onClick={closePopover}
          >
            Cat Diseases
          </EditableNavLink>
          <EditableNavLink
            to={'https://basepaws.com/cat-traits'}
            aria-label={'Open Cat Traits page'}
            onClick={closePopover}
          >
            Cat Traits
          </EditableNavLink>
        </div>
      </div>
      <div>
        <h3 className="Running_head_3 text_dark_grey">Dog Library</h3>
        <div className={styles.linksContainer}>
          <EditableNavLink
            to={'https://basepaws.com/dog-breeds'}
            aria-label={'Open Dog Breeds page'}
            onClick={closePopover}
          >
            Dog Breeds
          </EditableNavLink>
          <EditableNavLink
            to={'https://basepaws.com/dog-diseases'}
            aria-label={'Open Dog Diseases page'}
            onClick={closePopover}
          >
            Dog Diseases
          </EditableNavLink>
          <EditableNavLink
            to={'https://basepaws.com/dog-traits'}
            aria-label={'Open Dog Traits page'}
            onClick={closePopover}
          >
            Dog Traits
          </EditableNavLink>
        </div>
      </div>
    </div>
  )
}

export default LearnLinks
