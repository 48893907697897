import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { IonRow, IonCol, IonCard, IonIcon, IonAvatar, IonImg, IonCardContent, IonLabel, IonItem } from '@ionic/react'
import { checkmarkCircleSharp, ellipseOutline, peopleSharp, arrowForward, sendSharp, printSharp } from 'ionicons/icons'

import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import Accordion from 'components/basepaws/utils/Accordion'
import services from 'util/services'
import content from 'util/content'

import { ModalShare } from '../catDetail/DetailCard/ReportCard/ModalShare'
import { ModalSend } from '../catDetail/DetailCard/ReportCard/ModalSend'

import { KitStatus } from './constants'
import styles from './OrderListItem.module.scss'

const OrderListItem = (props) => {
  const { user, report, kit, pet, toggleFunction, toggleVar, owner } = props
  const { shareDigitalReportWithVet } = useFlags()

  const [modalShare, setModalShare] = useState(false)
  const [modalSend, setModalSend] = useState(false)

  const kitStatus = KitStatus()
  const localKit = kit

  const realProductStatus = () => kitStatus.find((el) => el.backStatus.includes(localKit.history[lastStatus]?.status))
  const statuses = []
  let lastStatus = null

  localKit.history.map((history, i) => {
    const realStatus = kitStatus.find((el) => el.backStatus.includes(history.status))
    const isLast = (!history[i + 1]?.time) && (history.time != null && history.time !== undefined)
    if (isLast) {
      lastStatus = i
    }
    if (statuses[statuses.length - 1]?.status !== realStatus.text) {
      const icon = history.time ? (realStatus.icon ?? checkmarkCircleSharp) : ''
      statuses.push({
        status: realStatus.text,
        statusDetail: realStatus.statusDetail,
        code: realStatus?.frontStatus,
        icon: icon,
        time: history.time,
        estimated: history.estimated,
        last: isLast
      })
    }
    return true
  })

  const petImage = pet?.images.find((i) => i.hit === 0)

  let realStatus = null

  useEffect(() => {
    if (report) {
      report.history.map((history, i) => {
        const isLast = (!history[i + 1]?.time) && (history.time != null && history.time !== undefined)
        if (isLast) {
          lastStatus = i
        }
        realStatus = kitStatus.find((el) => el.backStatus.includes(report.history[lastStatus].status))
        return true
      })
    }
  }, [report])

  return (
    <>
      {localKit &&
      <IonCard className={`${styles.my_orders_card} card`}>
        <Accordion
          list={[localKit]}
          toggleFunction={() => toggleFunction(localKit.id)}
          toggleVar={toggleVar}
          renderHeader={ (item) =>
            <>
              <IonCol size-lg="3" size-md="5" size="12">
                <div className="d-flex ion-justify-content-start">
                  <IonAvatar>
                    <IonImg src={`${services.thumb(petImage, pet?.organism)}${petImage ? '&' : '?'}${pet.updated_at}`} alt={`${pet?.name} profile image`} />
                  </IonAvatar>
                  <h2 className={`Lead_2 ion-no-margin ${pet?.death_on ? 'text_medium_grey' : ''}`}>
                    {pet?.name}
                  </h2>
                  {pet?.death_on &&
                    <IonAvatar className={`ion-no-margin ml-2 ${styles.deceased}`}>
                      <IonImg className={`${styles.deceased_img}`} src="/assets/icon/basepaws-icon-deceased-cats.svg" alt="Deceased pet" />
                    </IonAvatar>
                  }
                  {owner && <h2 className="Lead_2 ion-no-margin ml-2"> • {`${owner?.first_name} ${owner?.last_name}`}</h2> }
                </div>
              </IonCol>
              <IonCol size-lg="5" size-md="7" size="12">
                <h3 className={`Heading_6 ion-no-margin ${pet?.death_on ? 'text_medium_grey' : 'text_black'}`}>
                  {content.get(`REPORT_CARD.${item.productName}.NAME`)}
                </h3>
              </IonCol>
              <IonCol size-lg="3" size-md="6" size="6">
                <div className={`${styles.my_orders_card_header_state} d-flex ion-align-items-center ion-justify-content-between`}>
                  <span className={`tags ${realProductStatus()?.frontStatus}`}>{realProductStatus()?.simpleText}</span>
                </div>
              </IonCol>
            </>
          }
          renderPanel={ (item) =>
            <IonCardContent>
              <IonRow>
                <IonCol size-lg="3" size-md="5" size="12" className={styles.pe_md}>
                  <div className={styles.id_tag}><p className="Running_head_3 text_sad_purple">ID {item.code}</p></div>
                  {/* <p className="Body_3 text_grey mt-2">{content.get('MY_ORDERS.ORDERLIST.RECEIVE_REPORT_TEXT')} {content.get(item.productName)}</p> */}
                </IonCol>
                <IonCol size-lg="5" size-md="7" size="12" className={`${styles.pe_md} ${styles.col_actions}`}>
                  <p className="Running_head_3 text_medium_grey mb-2">{content.get('MY_ORDERS.ORDERLIST.STATUS')}</p>
                  {item.note_for_customer
                    ? <p className="Body_2 text_dark_grey">{ item.note_for_customer }</p>
                    : <p className="Body_2 text_dark_grey" dangerouslySetInnerHTML={{ __html: realProductStatus()?.statusDetail }}></p>
                }
                  {realProductStatus()?.frontStatus === 'completed' &&
                  <>
                    <div className={styles.space} />
                    <div className={`${styles.report_actions} d-flex ion-justify-content-between ion-align-items-center`}>
                      <EditableIonButton
                        mode="md"
                        color="primary"
                        fill="solid"
                        shape="round"
                        aria-label={`View ${content.get(`REPORT_CARD.${report.type}.NAME`)} report`}
                        onClick={() => {
                          services.trackEvent({
                            category: 'Cat Detail Page - Reports',
                            action: `View Report - ${content.get(`REPORT_CARD.${report.type}.NAME`)}`,
                            label: 'Button'
                          })
                        }}
                        href={report?.url_view}
                        target='_blank'
                        className={`default_button ion-margin-end ${report.isDigitalReport ? styles.ctaDesktop : ''} `}
                        >
                          {content.get('MY_ORDERS.ORDERLIST.BUTTONS.VIEW_REPORT')}
                          <IonIcon color="light" slot="end" icon={arrowForward}/>
                      </EditableIonButton>
                      {!report.isDigitalReport
                        ? <div>
                        <EditableIonButton
                          mode="md"
                          color="primary"
                          fill="clear"
                          className="button-link ion-margin-end default_button"
                          aria-label={`Print ${content.get(`REPORT_CARD.${report.type}.NAME`)} report`}
                          onClick={() => {
                            services.trackEvent({
                              category: 'Cat Detail Page - Reports',
                              action: `Print - ${content.get(`REPORT_CARD.${report.type}.NAME`)}`,
                              label: 'Button'
                            })
                          }}
                          href={report?.url_print}
                          target='_blank'
                        >
                          <IonIcon color="primary" slot="start" icon={printSharp}/>
                          {content.get('MY_ORDERS.ORDERLIST.BUTTONS.PRINT')}
                        </EditableIonButton>
                        <EditableIonButton
                          mode="md"
                          color="primary"
                          fill="clear"
                          className="button-link default_button"
                          aria-label={`Send ${content.get(`REPORT_CARD.${report.type}.NAME`)} report`}
                          onclick={() => {
                            setModalSend(true)
                            services.trackEvent({
                              category: 'Cat Detail Page - Reports',
                              action: `Send - ${content.get(`REPORT_CARD.${report.type}.NAME`)}`,
                              label: 'Button'
                            })
                          }}
                          target='_blank'
                        >
                          <IonIcon color="primary" slot="start" icon={sendSharp}/>
                          {content.get('MY_ORDERS.ORDERLIST.BUTTONS.SEND')}
                        </EditableIonButton>
                      </div>
                        : null
                    }
                    </div>
                  </>
                  }
                </IonCol>
                <IonCol size-lg="4" size-md="12" size="12">
                {realProductStatus()?.frontStatus !== 'completed'
                  ? <>
                    {statuses.map((e, i) => {
                      const isLast = (!statuses[i + 1]?.time) && (e.time != null && e.time !== undefined)
                      const data = {
                        activeClass: (isLast ? styles[`${e.code}_active`] : styles[`${e.code}_inactive`]),
                        icon: e.estimated ? ellipseOutline : e.icon
                      }
                      return <IonItem key={i} lines="none" className={`${styles.item_state} ${styles[`${e.name}`]} ${data.activeClass} item-no-padding`}>
                        <div className={`${styles.item} mr-2`}>
                          <IonIcon icon={data.icon} className={styles.item_state_icon}></IonIcon>
                          <span className={styles.item_state_icon_line}></span>
                        </div>
                        <IonLabel className={styles.label}>
                          <p className="Body_2_bold">{e.status}</p>
                          <span className="Body_2">
                            {e.time
                              ? moment(e.time).format('MMM Do')
                              : (e.estimated && e.estimated.length > 1
                                  ? `${moment(new Date(e.estimated[0])).format('MMM Do')} - ${moment(new Date(e.estimated[1])).format('MMM Do')}`
                                  : moment(e.estimated[0]).format('MMM Do')
                                )
                            }
                          </span>
                        </IonLabel>
                      </IonItem>
                    })}
                    <div className="alert_message bg_grey text_dark_grey">
                      <p className="Body_3">{content.get('MY_ORDERS.ORDERLIST.ESTIMATION')}</p>
                    </div>
                  </>
                  : shareDigitalReportWithVet || pet?.organism === 'cat'
                    ? <>
                        <div className={styles.share_vets_banner}>
                          <h6 className="Heading_6 text_sad_purple">{content.get('MY_ORDERS.ORDERLIST.SHARE_VET.TITLE')}</h6>
                          <p className="Body_2 text_dark_grey">{content.get('MY_ORDERS.ORDERLIST.SHARE_VET.TEXT')}</p>
                          <EditableIonButton
                            mode="md"
                            color="primary"
                            fill="clear"
                            shape="round"
                            aria-label={`Share ${content.get(`REPORT_CARD.${report.type}.NAME`)} report with your Vet`}
                            onclick={() => setModalShare(true)}
                            className="default_button"
                          >
                            <IonIcon color="primary" slot="start" icon={peopleSharp}/>
                            {content.get('MY_ORDERS.ORDERLIST.SHARE_VET.CTA')}
                          </EditableIonButton>
                        </div>
                        <ModalShare
                          modalState={modalShare}
                          close={() => setModalShare(false)}
                          report={report}
                          user={user}
                          organism= {pet?.organism}
                        />
                        <ModalSend
                          modalState={modalSend}
                          close={() => setModalSend(false)}
                          report={report}
                        />
                      </>
                    : null
                }
                </IonCol>
              </IonRow>
            </IonCardContent>
          }
        />
      </IonCard>
      }
    </>
  )
}

export default OrderListItem
