import React from 'react'
import { IonToast, isPlatform } from '@ionic/react'
import styles from './Toaster.module.scss'

interface IProps {
  status: boolean
  onDidDismiss: any
  position: 'top' | 'bottom' | 'middle' | null | undefined
  duration: number | null | undefined
  message: string
  header: any
}

const Toaster = (props: IProps) => {
  const { status, onDidDismiss, position, duration, message, header } = props

  const toastPosition = position ?? (isPlatform('desktop') ? 'bottom' : 'bottom')

  const toastDuration = duration ?? 6000

  return (
    <IonToast
      isOpen={status}
      onDidDismiss={onDidDismiss}
      header={header}
      message={message}
      position={toastPosition}
      duration={toastDuration}
      cssClass={`${styles.toast}`}
      animated={true}
      mode="ios"
    />
  )
}

export default Toaster
