import React, { createContext, useState } from 'react'
import { IonRow, IonCol, IonGrid, IonCard, useIonViewWillLeave } from '@ionic/react'
import { useSelector } from 'react-redux'

import content from 'util/content'

import OrderNewForm from 'components/basepaws/account/OrderNewForm'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage.js'

import styles from './AccountOrdersNew.module.scss'

const HandlerStepContext = createContext()

const AccountOrdersNew = (props) => {
  const user = useSelector(state => state.userInReducer.user)
  const [actualFormStep, setActualFormStep] = useState(1)

  useIonViewWillLeave(() => {
    setActualFormStep(1)
  })

  const form = (
      <IonCard className={styles.cat_card_edit}>
        <IonRow className="ion-justify-content-center">
          <IonCol size-lg="4" size-md="6" size="12">
            <HandlerStepContext.Provider value={[actualFormStep, setActualFormStep]}>
              <OrderNewForm HandlerStepContext={HandlerStepContext} pets={user?.pets?.filter(pet => !pet.death_on)}/>
            </HandlerStepContext.Provider>
          </IonCol>
        </IonRow>
      </IonCard>
  )

  return (
    <HandlerStepContext.Provider value={[actualFormStep, setActualFormStep]}>
      <GeneralPage
        user={user}
        title={content.get('MY_ORDERS_NEW.HEADER.TITLE')}
        headerStyle='center'
        actionStyle='left'
        pageClass={`${styles.cat_single}`}
        isNotFixed={true}
        HandlerStepContext={HandlerStepContext}
      >
        <IonGrid fixed>
          {form}
        </IonGrid>
      </GeneralPage>
    </HandlerStepContext.Provider>
  )
}

export default AccountOrdersNew
