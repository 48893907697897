import React from 'react'
import { IonRow, IonCol, IonCard, IonIcon, IonButton, IonCardContent, IonCardTitle } from '@ionic/react'

import styles from './ActionBlock.module.scss'
import { arrowForward } from 'ionicons/icons'

const ActionBlock = (props) => {
  return (
    <>
      <IonCard className={styles.card_action}>
        <IonCardTitle className="Heading_3 text_sad_purple">{props.title}</IonCardTitle>
        <IonCardContent>
          <IonRow>
            {/* Item 1 */}
            <IonCol size-lg="6" size="12">
              <div className="d-flex ion-align-items-start no-wrap">
                <div className={styles.icon_wrapper}>
                  <IonIcon color="primary" icon={props.item_icon1}></IonIcon>
                </div>
                <div className={styles.content_wrapper}>
                  <h4 className="Heading_4 text_sad_purple mb-1">{props.item_title1}</h4>
                  <p className="Body_1 text_grey">{props.item_text1}</p>
                  <IonButton
                    disabled={props.item_disabled1}
                    mode="md"
                    fill="outline"
                    color="primary"
                    shape="round"
                    className="icon-end"
                    onclick={props.item_button_onclick1}
                    routerLink={props.item_button_href1}
                  >
                    {props.item_button_text1}
                    <IonIcon slot="end" icon={arrowForward}/>
                  </IonButton>
              </div>
              </div>
            </IonCol>
            {/* Item 2 */}
            <IonCol size-lg="6" size="12">
              <div className="d-flex ion-align-items-start no-wrap">
                <div className={styles.icon_wrapper}>
                  <IonIcon color="primary" icon={props.item_icon2}></IonIcon>
                </div>
                <div className={styles.content_wrapper}>
                  <h4 className="Heading_4 text_sad_purple mb-1">{props.item_title2}</h4>
                  <p className="Body_1 text_grey">{props.item_text2}</p>
                  <IonButton
                    disabled={props.item_disabled2}
                    mode="md"
                    fill="outline"
                    color="primary"
                    shape="round"
                    className="icon-end"
                    onclick={props.item_button_onclick2}
                    routerLink={props.item_button_href2}
                  >
                    {props.item_button_text2}
                    <IonIcon slot="end" icon={arrowForward}/>
                  </IonButton>
              </div>
              </div>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    </>
  )
}

export default ActionBlock
