import React from 'react'
import { IonIcon, IonAvatar, IonRow, IonCol, IonList } from '@ionic/react'
import { arrowForwardSharp } from 'ionicons/icons'

import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import services from 'util/services'
import content from 'util/content'

import styles from './ProfileMyCatsCard.module.scss'

const ProfileCatCard = (props) => {
  const { pet } = props
  const petImage = pet?.images.find((i) => i.hit === 0)

  return <IonList key={pet.id}>
    <li className={styles.my_profile_welcome_card_mainitem}>
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonCol size="2" size-md="3" size-lg="2" className="ion-text-center">
          <IonAvatar>
            <img src={`${services.thumb(petImage, pet?.organism)}${petImage ? '&' : '?'}${pet.updated_at}`} alt="Pet avatar" />
          </IonAvatar>
        </IonCol>
        <IonCol size="7" size-md="5" size-lg="6">
          <h4 className="Heading_4">{pet.name}</h4>
        </IonCol>
        <IonCol size="3" size-md="4" size-lg="4" className="ion-text-end">
          <EditableIonButton
            mode="md"
            shape="round"
            color="primary"
            fill="clear"
            routerLink={`/pets/${pet.handle}`}
            aria-label={`View ${pet.name} detail page`}
          >
            <IonIcon slot="end" icon={arrowForwardSharp} />{content.get('dashboard.my_cats.cta_viewcat')}
          </EditableIonButton>
        </IonCol>
      </IonRow>
    </li>
  </IonList>
}

export default ProfileCatCard
