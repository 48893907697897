import React, { useState, useEffect } from 'react'
import { IonItem, IonSelect, IonSelectOption, IonIcon, IonCol } from '@ionic/react'
import content from 'util/content'
import { reorderTwoSharp } from 'ionicons/icons'

const SortByButton = (props) => {
  const { options, sortIn, setResultFunction, defaultOrder } = props
  const [orderValue, setOrderValue] = useState(defaultOrder ?? 'Select One')

  const sortFunction = (a, b, field) => {
    if (a[field] < b[field]) {
      return -1
    }
    if (a[field] > b[field]) {
      return 1
    }
    return 0
  }

  useEffect(() => {
    const sortedArray = sortIn.sort((a, b) => sortFunction(a, b, orderValue))
    setResultFunction([...sortedArray])
    return () => null
  }, [orderValue])

  return (
    <IonCol size-md="4">      
	<div className="d-flex ion-justify-content-end">
      <p className="Body_1 text_grey ion-no-margin mr-2">{content.get('MY_CATS.SORT.TITLE')}</p>
      <IonItem
        lines="none"
        className="item-no-padding">  
          <IonSelect
            value={orderValue}
            onIonChange={(e) => {
              setOrderValue(e.detail.value)
            }}
            interface="popover"
            placeholder="Select One"
            className="Body_1_bold text_black"
          >
            {options && options.map(
              (option, index) => {
                return (
                  <IonSelectOption
                    key={index}
                    value={option.value}
                  >
                    {option.label}
                  </IonSelectOption>
                )
              })
            }
          </IonSelect>
          <IonIcon color="dark" icon={reorderTwoSharp}/>
        </IonItem>
      </div>
    </IonCol>
  )
}

export default SortByButton
