import React from 'react'

import { IonRow, IonCol, IonCardContent, IonIcon, IonCardHeader, IonImg, IonCardTitle } from '@ionic/react'
import { enter, create } from 'ionicons/icons'
import routes from '../../../../../../constants/routes.json'
import content from 'util/content'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

import styles from './ReportEmptyCard.module.scss'

const ReportEmptyCard = (props) => {
  const { type } = props
  const showActivate = props.showActivate ?? true
  const showBuy = props.showBuy ?? true
  const emptyReportStates = {
    genome: {
      title: 'REPORTS.EMPTY.GENOME.TITLE',
      description: 'REPORTS.EMPTY.GENOME.DESCRIPTION',
      icon: '/assets/icon/basepaws-icon-report-type-whole.svg',
      image: '/assets/images/basepaws-kit-genomeKit.png',
      alt: 'Whole genome sequencing test box',
      shopUrl: routes.LINKS.EXTERNAL.SHOP_GENOME_KIT
    },
    breed: {
      title: 'REPORTS.EMPTY.BREEDS.TITLE',
      description: 'REPORTS.EMPTY.BREEDS.DESCRIPTION',
      icon: '/assets/icon/basepaws-icon-report-type-health-markers.svg',
      image: '/assets/images/basepaws-kit-catKit.png',
      alt: 'Catkit test box',
      shopUrl: routes.LINKS.EXTERNAL.SHOP_BREED_KIT
    },
    dental: {
      title: 'REPORTS.EMPTY.DENTAL.TITLE',
      description: 'REPORTS.EMPTY.DENTAL.DESCRIPTION',
      icon: '/assets/icon/basepaws-icon-report-type-dental.svg',
      image: '/assets/images/basepaws-kit-dentalKit.png',
      alt: 'Dental kit test box',
      shopUrl: routes.LINKS.EXTERNAL.SHOP_DENTAL_KIT
    }
  }

  return (
    <>
      {/* MVP Empty state */}
      <IonCardHeader className={`${styles.cat_single_card_header} ${styles.cat_single_card_header_empty}`}>
        <div><IonImg src={emptyReportStates[type].icon} alt="" /></div>
        <div className={`${styles.cat_single_card_subheader} ${styles.cat_single_card_subheader_empty} w-100`}>
          <IonRow className="ion-justify-content-between ion-align-items-center">
            <IonCol size="12">
              <IonCardTitle className="Heading_4 ion-text-start">{content.get(emptyReportStates[type].title)}</IonCardTitle>
            </IonCol>
          </IonRow>
        </div>
      </IonCardHeader>
      <IonCardContent className={`${styles.card_content} ${styles.card_content_empty}`}>
        <IonRow className="ion-align-items-center">
          <IonCol size-md="7" size-sm="10" size="12">
            <p className="Body_1 text_grey">{content.get(emptyReportStates[type].description)}</p>
          </IonCol>
          <IonCol size-md="5" size="12">
            <IonImg className="w-100" src={emptyReportStates[type].image} alt={emptyReportStates[type].alt} />
          </IonCol>
          <IonCol size-md="7" size="12">
            <div className="d-flex">
              {showBuy &&
              <EditableIonButton
                mode="md"
                color="primary"
                fill="solid"
                shape="round"
                href={emptyReportStates[type].shopUrl}
                target='_blank'
                className="default_button"
                >
                  {content.get('MY_ORDERS.REPORTCARD.EMPTY.CTA')}
                  <IonIcon color="light" slot="start" icon={enter} />
              </EditableIonButton>
              }
              {showActivate &&
              <EditableIonButton
                mode="md"
                color="dark"
                fill="clear"
                className="button-link default_button"
                href='/kit/register'
              >
                <IonIcon color="dark" slot="start" icon={create} />
                {content.get('MY_ORDERS.REPORTCARD.EMPTY.CTA_ACTIVATE_KIT')}
              </EditableIonButton>
              }
            </div>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </>
  )
}

export default ReportEmptyCard
