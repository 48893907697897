import React, { useState } from 'react'
import { IonHeader, IonImg, IonIcon, IonAvatar, IonPopover, IonButton } from '@ionic/react'
import { chevronDown, closeSharp } from 'ionicons/icons'
import { useHistory } from 'react-router'

import EditableNavLink from 'components/basepaws/utils/EditableNavLink'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import content from 'util/content'
import { storeUrl } from 'util/constants.ts'
import services, { servicesNew } from 'util/services'

import BackButton from '../../BackButton'
import NavbarWorkspaces from '../NavbarWorkspaces'

import styles from './Navbar.module.scss'
import { isHexColorValid } from 'util/constants'
import LearnLinks from '../LearnLinks'

const Navbar = (props) => {
  const { user, mobileBackButton, topbarStatus, topbarToggle, goBackUrl } = props
  const history = useHistory()

  const [popoverState, setPopoverState] = useState({ showPopover: false, event: undefined })

  const [topbarDisplay, setTopbarDisplay] = useState(true)

  const [transition, setTransition] = useState(false)

  const [height, setHeight] = useState(null)

  const [popoverParameters, setPopoverParameters] = useState({
    show: false,
    height: null,
    size: null
  })

  const [showLearnLinks, setShowLearnLinks] = useState(false)

  const isAdminOrVet = () => {
    return user?.roles?.[0]?.name === 'vet' || user.roles?.[0]?.name === 'admin'
  }

  const onToggle = () => {
    setPopoverParameters({
      ...popoverParameters,
      show: !popoverParameters.show
    })
    setPopoverState({ ...popoverState, showPopover: !popoverState.showPopover })
  }

  const onLogout = async () => {
    onClose()
    await servicesNew.logout().finally(() => {
      history.push('/login')
    })
  }

  const onOpen = () => {
    setTransition(true)
    setTimeout(() => {
      // setHeight(user?.roles?.[0]?.name === 'vet' || user.roles?.[0]?.name === 'admin' ? styles.nav_not_common_user : styles.nav_common_user)
      setHeight(styles.nav_common_user)
    }, 50)
    setTimeout(() => {
      setPopoverParameters({
        ...popoverParameters,
        show: true,
        size: null
      })
      setTransition(false)
    }, 350)
  }

  const onClose = () => {
    setTransition(true)
    setTimeout(() => {
      setHeight(null)
    }, 50)
    setTimeout(() => {
      setPopoverParameters({
        ...popoverParameters,
        show: false,
        size: null
      })
      setTransition(false)
    }, 350)
  }

  const onOpenMenu = () => {
    if (transition) return
    if (popoverParameters.show) onClose()
    else { onOpen() }
  }

  const ownerLinks = [
    {
      title: 'NAVBAR.OWNER.DASHBOARD',
      url: '/profile'
    },
    {
      title: 'NAVBAR.OWNER.CATS',
      url: '/pets'
    },
    {
      title: 'NAVBAR.OWNER.REFER_LINK',
      url: 'https://share.basepaws.com/dashboard'
    }
  ]

  const onCloseAdds = () => {
    const topbarAdds = document.querySelector('.topbar_adds')
    topbarAdds.style.display = 'none'

    const pageHeaderDomObject = document.querySelector('ion-toolbar > .Display_4.text_sad_purple')
    pageHeaderDomObject.classList.add('pageHeaderWithOptionalBarOff')

    topbarToggle(false)
    services.topbarDismiss(content.get('NAVBAR.TOPBAR.TEXT'))
  }

  const basepawsLogo = <IonImg src="assets/images/basepaws-logo-navbar.svg" alt="Basepaws logo" />

  // #25292E is the current value for the --black color in the variables.scss
  const rawBgColorContent = content.get('NAVBAR.TOPBAR.BACKGROUND.COLOR')
  const topBarBackgroundColor = isHexColorValid(rawBgColorContent) ? rawBgColorContent : '#25292E'
  const rawTextColorContent = content.get('NAVBAR.TOPBAR.TEXT_COLOR')
  const topBarTextColor = isHexColorValid(rawTextColorContent) ? rawTextColorContent : '#FFFFFF'

  console.log('[Navbar]', { storeUrl })

  return (
    <>
      {(!user || !user.id)
        ? <IonHeader className={`${styles.header_navbar} header-navbar`}>
          <nav className={`${styles.navbar} ${styles.navbar_expand_lg}`}>
            <a className={`mx-auto ${styles.navbar_brand}`} href={`${storeUrl}`}>
              {basepawsLogo}
            </a>
          </nav>
        </IonHeader>
        : <IonHeader className={`${styles.header_navbar} header-navbar`}>
          {topbarStatus && topbarDisplay &&
          <div className={`${styles.topbar_adds} topbar_adds`} style={{ backgroundColor: topBarBackgroundColor }}>
            <p className="Body_1"
            style={{ color: topBarTextColor }}
              dangerouslySetInnerHTML={ { __html: content.get('NAVBAR.TOPBAR.TEXT') } }>
              {/* Message here with and <b>EXAMPLE</b> <a href="#">link</a> */}
            </p>
            <IonButton
              fill="clear"
              onClick={onCloseAdds}
              color="light"
              aria-label="Close advertising top bar"
            >
              <IonIcon icon={closeSharp}/>
            </IonButton>
          </div>
          }
          <nav className={`${styles.navbar} ${styles.navbar_expand_lg}`}>
            <div className={styles.container}>

              <a className={`${styles.navbar_brand} ${mobileBackButton ? 'no_mobile' : ''}`} href="/profile">
                {basepawsLogo}
              </a>

              {(mobileBackButton) &&
                <div className="no_desktop">
                  <BackButton goBackUrl={goBackUrl} />
                </div>
              }

              {/* My account button MOBILE */}
              <div className='d-flex'>
                {/* Please add a condition here: if there a back button on the page don't show this button. */}
                {(!mobileBackButton) &&
                <EditableIonButton
                  shape="round"
                  color="primary"
                  fill="solid"
                  className={`${styles.no_desktop} no_desktop default_button ion-margin-end`}
                  routerLink="/kit/register"
                  aria-label="Register a new kit for testing"
                  showOnClickButton={false}
                  onClick={() => {
                    setTimeout(() => {
                      onClose()
                    }, 1000)
                  }}
                >
                    {content.get('NAVBAR.OWNER.REGISTER_KIT')}
                </EditableIonButton>
                }
                <EditableIonButton
                  mode="md"
                  className={`${styles.navbar_toggler} ${styles.button_popover} no_desktop`}
                  shape="round"
                  slot="end"
                  onClick={onOpenMenu}
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Open my account details"
                >
                  <IonAvatar slot="start">
                    <IonImg src='assets/images/navbar-pets-owner.svg' alt="Default placeholder for profile avatar: cat's illustration with white background" />
                  </IonAvatar>
                  <span className="no_mobile">{content.get('NAVBAR.POPOVER.BUTTON.MAIN')}</span>
                  <IonIcon slot="end" icon={chevronDown} className={`${styles.button_popover_icon} ${(popoverParameters.show) ? styles.open : ''}`} />
                </EditableIonButton>
              </div>

              <div className={`${styles.backdrop} ${height ? styles.open : ''} ${transition ? styles.opening : ''}`} onClick={onClose}></div>
              <div
                className={`navbar-collapse w-100 ${popoverParameters.show ? styles.show : ''} ${transition ? styles.collapsing : styles.collapse} ${height}`}
                id="navbarSupportedContent"
              >
                <ul className={`${styles.navbar_nav}`}>
                  {ownerLinks.map(
                    (link, index) => {
                      return (
                        <li
                          key={index}
                          className={`${styles.nav_item} nav_item`}
                        >
                          <EditableNavLink
                            to={link.url}
                            activeClassName="active"
                            onClick={() => {
                              setTimeout(() => {
                                onClose()
                              }, 1000)
                            }}
                            aria-label={`Open ${content.get(link.title)} page`}
                          >
                            {content.get(link.title)}
                          </EditableNavLink>
                        </li>
                      )
                    }
                  )}
                  <li className={`${styles.nav_item} no_mobile`}>
                    <EditableIonButton
                      onClick={() => setShowLearnLinks(!showLearnLinks)}
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      mode="md"
                      aria-label="Links to learn more about cats and dogs"
                      color="none"
                      className={styles.learnLinks}
                    >
                      {content.get('navbar.owner.learn')}
                      <IonIcon slot="end" icon={chevronDown} className={`${styles.button_popover_icon} ${popoverParameters.show ? styles.open : ''}`} />
                    </EditableIonButton>
                    <IonPopover
                      cssClass={`header-popover common_user ${styles.linksBox}`}
                      mode="md"
                      isOpen={showLearnLinks}
                      onDidDismiss={() => setShowLearnLinks(false)}
                    >
                      <LearnLinks
                        closePopover={() => {
                          setShowLearnLinks(false)
                        }}
                      />
                    </IonPopover>
                  </li>
                  <li className={`${styles.nav_item} ${styles.nav_item_button} no_mobile`}>
                    <EditableIonButton
                      shape="round"
                      color="primary"
                      fill="solid"
                      className="default_button"
                      routerLink="/kit/register"
                      aria-label="Register a new kit for testing"
                      showOnClickButton={false}
                      onClick={() => {
                        setTimeout(() => {
                          onClose()
                        }, 1000)
                      }}
                    >
                        {content.get('NAVBAR.OWNER.REGISTER_KIT')}
                    </EditableIonButton>
                  </li>
                  {/* My account button DESKTOP */}
                  <li className={`${styles.nav_item} no_mobile`}>
                    <EditableIonButton
                      slot="end"
                      onClick={() => onToggle()}
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      shape="round"
                      mode="md"
                      aria-label="Open my account details"
                      className={`${styles.navbar_toggler} ${styles.button_popover} ion-padding-start`}
                    >
                      <IonAvatar slot="start">
                        <IonImg src="assets/images/navbar-pets-owner.svg" alt="Default placeholder for profile avatar: cat's illustration with white background" />
                      </IonAvatar>
                      {content.get('NAVBAR.POPOVER.BUTTON.MAIN')}
                      <IonIcon slot="end" icon={chevronDown} className={`${styles.button_popover_icon} ${popoverParameters.show ? styles.open : ''}`} />
                    </EditableIonButton>
                    <IonPopover
                      cssClass={`header-popover common_user ${topbarStatus ? 'popup_topbar' : ''}`}
                      mode="md"
                      event={popoverState.event}
                      isOpen={popoverState.showPopover}
                      onDidDismiss={() => {
                        if (popoverState.showPopover) {
                          setPopoverState({ showPopover: false, event: undefined })
                          onToggle()
                        }
                      }}
                    >
                      <NavbarWorkspaces
                        isAdminOrVet={isAdminOrVet()}
                        closePopover={() => {
                          onToggle()
                        }}
                        user={user}
                        onLogout={async () => await onLogout()}
                      />
                    </IonPopover>
                  </li>

                  {/* WORKSPACES MOBILE */}
                  <li
                    className={` no_desktop ${styles.learnMobile}`}
                  >
                    <LearnLinks
                      closePopover={() => {
                        setShowLearnLinks(false)
                      }}
                    />
                  </li>
                  <li className={`${styles.workspaces_wrapper} ${styles.no_desktop} no_desktop`}>
                    <NavbarWorkspaces
                      isAdminOrVet={isAdminOrVet()}
                      closePopover={() => {
                        setTimeout(() => {
                          onClose()
                        }, 1000)
                      }}
                      user={user}
                      onLogout={async () => await onLogout()}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </IonHeader>
      }
    </>
  )
}

export default Navbar
