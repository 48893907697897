import React, { useState } from 'react'
import { IonIcon, IonProgressBar, IonRow, IonCol, IonItem } from '@ionic/react'
import { share, alertCircleSharp } from 'ionicons/icons'
import content from 'util/content'

import styles from './UploadDraggedFile.module.scss'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

import { useDropzone } from 'react-dropzone'

const UploadDraggedFile = ({ handleImageSubmit, big = true }) => {
  const [errors, setErrors] = useState({
    server: false,
    size: false
  })

  const [progress, setProgress] = useState(null)
  // const onDrop = useCallback(acceptedFiles => {
  //   console.log(acceptedFiles)
  //   handleImageSubmit(acceptedFiles)
  // }, [])
  const onDrop = (acceptedFiles) => {
    const reader = new FileReader()
    const timeout = 2000 // 2 seconds
    const sizeLimit = 5242880 // 5MB
    setErrors({
      server: false,
      size: false
    })

    reader.onloadstart = (e) => {
      if (e.total > sizeLimit) {
        setErrors({
          ...errors,
          size: true
        })
      } else {
        setProgress(0)
      }
    }

    reader.onprogress = (e) => {
      if (!errors.server && e.total < sizeLimit) {
        setErrors({
          ...errors,
          size: false
        })
        for (let count = 100; count <= timeout; count += 100) {
          setTimeout(() => {
            setProgress(count / timeout)
          }, count)
        }
      }
    }

    reader.onloadend = (e) => {
      if (!errors.server && e.total < sizeLimit) {
        setTimeout(() => {
          setProgress(null)
        }, timeout + 1000)
        handleImageSubmit(acceptedFiles)
      }
    }

    reader.onerror = () => {
      setErrors({
        ...errors,
        server: true
      })
    }

    reader.readAsDataURL(acceptedFiles[0])
  }

  const onDropAccepted = (acceptedFiles) => {
    // setProgress(0.3)
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, onDropAccepted })

  return (
    <>
      <div {...getRootProps()} className={`${styles.Drag_block} ${big ? styles.big : ''}`}>

      {!progress &&
        <div className={styles.drag_image_content}>
          <div className="d-flex ion-justify-content-center">
            <input {...getInputProps()} />
            <EditableIonButton
              mode="md"
              fill="clear"
              size="default"
              color="primary"
            >
              {content.get('CAT_DETAIL.GALLERY.DRAG_BLOCK.UPLOAD_CTA')}
              <IonIcon slot="start" color="primary" icon={share} />
            </EditableIonButton>
            <p className={`${styles.ordrag_text} Body_1 text_dark_grey ion-no-margin`}> {content.get('CAT_DETAIL.GALLERY.DRAG_BLOCK.ORDRAG_TEXT')}</p>
          </div>
          <p className={`${styles.max_size} Body_2 text_dark_grey ion-no-margin ion-text-center`}>{content.get('CAT_DETAIL.GALLERY.DRAG_BLOCK.MAX_SIZE')}</p>
        </div>
      }

        <div className={styles.progress_content}>
          <IonRow className="ion-justify-content-center">
            <IonCol size-md="11" size="12">
              {progress > 0 &&
              <>
              <p className="Body_1 text_grey">{content.get('CAT_DETAIL.GALLERY.DRAG_BLOCK.UPLOADING')}</p>
              <IonProgressBar color="primary" value={progress} />
              </>
              }
              {errors.size &&
              <IonItem lines="none" className="ion-margin-top item-no-padding">
                <div className="alert_message alert_form danger w-100">
                  <div className="input-error-label Body_2_bold">
                    <IonIcon icon={alertCircleSharp} color="danger" slot="start"/>
                    {content.get('CAT_DETAIL.GALLERY.DRAG_BLOCK.ERROR.MAX_SIZE.TITLE')}
                  </div>
                  <p className="Body_2 text_dark_grey">{content.get('CAT_DETAIL.GALLERY.DRAG_BLOCK.ERROR.MAX_SIZE.TEXT')}</p>
                </div>
              </IonItem>
              }
              {errors.server &&
              <IonItem lines="none" className="ion-margin-top item-no-padding">
                <div className="alert_message alert_form warning w-100">
                  <div className="input-error-label Body_2_bold">
                    <IonIcon icon={alertCircleSharp} color="warning" slot="start"/>
                    {content.get('CAT_DETAIL.GALLERY.DRAG_BLOCK.ERROR.SERVER.TITLE')}
                  </div>
                  <p className="Body_2 text_dark_grey">{content.get('CAT_DETAIL.GALLERY.DRAG_BLOCK.ERROR.SERVER.TEXT')}</p>
                </div>
              </IonItem>
              }
              {/* <IonItem lines="none" className="ion-margin-top item-no-padding">
                <div className="alert_message alert_form warning w-100">
                  <div className="input-error-label Body_2_bold">
                    <IonIcon icon={alertCircleSharp} color="warning" slot="start"/>
                    Poor connection
                  </div>
                  <p className="Body_2 text_dark_grey">Try to find a stronger conexion and upload files again</p>
                </div>
              </IonItem> */}
            </IonCol>
          </IonRow>
        </div>

      </div>
    </>
  )
}

export default UploadDraggedFile
