import React from 'react'
import { IonPopover } from '@ionic/react'
import { NavHashLink } from 'react-router-hash-link'
import contentEditableAware from '../hooks/contentEditableAware'
import styles from './EditableNavLink.module.scss'

const EditableNavHashLink = (props) => {
  const editableContent = contentEditableAware()
  const [popoverState, setPopoverState] = React.useState({
    showPopover: false,
    event: null
  })

  const onMouseOverHandler = (e) => {
    e.persist()
    setPopoverState({ showPopover: true, event: e })
  }

  const handleClick = (e) => {
    if (editableContent) {
      e.preventDefault()
    } else {
      props.onClick && props.onClick(e)
    }
    setPopoverState({ showPopover: false, event: undefined })
  }

  return (
    <>
    {editableContent &&
    <IonPopover
      cssClass={styles.editableBackdrop}
      mode="ios"
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={() => setPopoverState({ showPopover: false, event: undefined })}
    >
      <NavHashLink
        {...props}
        to={`${props.to || props.href}#key`}
        onClick={() => setPopoverState({ showPopover: false, event: undefined })}
      >
        Go to link ➚
      </NavHashLink>
    </IonPopover>
    }
    <NavHashLink
      {...props}
      onClick={handleClick}
      onMouseEnter={ editableContent ? onMouseOverHandler : undefined }
    >
      {props.children}
    </NavHashLink>
    </>
  )
}

export default EditableNavHashLink
