import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { useSelector } from 'react-redux'

import { Error } from './pages'

import AccountCatSingle from './pages/account/AccountCatDetail'
import AccountCatSingleEdit from './pages/account/AccountCatDetailEdit'
import AccountCat from './pages/account/AccountCats'
import AccountOrders from './pages/account/AccountOrders'
import AccountProfile from './pages/account/AccountProfile'
import AccountCatNew from 'pages/account/AccountCatNew'
import AccountCatNewConfirmation from 'pages/account/AccountCatNewConfirmation'
import AccountOrdersNew from 'pages/account/AccountOrdersNew'
import AccountOrdersNewConfirmation from 'pages/account/AccountOrdersNewConfirmation'
import AccountChangePassword from 'pages/account/AccountChangePassword'
import AccountEditProfile from 'pages/account/AccountEditProfile'
import AccountLogin from 'pages/account/AccountLogin'
import AccountRegister from 'pages/account/AccountRegister'
import AccountRecoverPasswordEmail from 'pages/account/AccountRecoverPassword/AccountRecoverPasswordEmail'
import AccountRecoverPasswordPassword from 'pages/account/AccountRecoverPassword/AccountRecoverPasswordPassword'
import AccountRecoverPasswordEmailConfirmation from 'pages/account/AccountRecoverPassword/AccountRecoverPasswordEmailConfirmation'
import AccountRecoverPasswordPasswordConfirmation from 'pages/account/AccountRecoverPassword/AccountRecoverPasswordPasswordConfirmation'
import ContentPage from 'pages/other/ContentPage'
import VetShareReportPage from 'pages/other/VetShareReportPage'
import { isUserAccessingReportWithoutAuth } from 'util/utils'
import { launchDarklyOptions } from 'util/constants'
import { LDProvider } from 'launchdarkly-react-client-sdk';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/general.scss'

/* Slick carrousel css */
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import content from 'util/content'

import { polyfill } from 'seamless-scroll-polyfill'
import { getEnvVariable } from 'components/basepaws/utils/envVariables'

// eslint-disable-next-line no-unused-expressions
polyfill

const App: React.FC = () => {
  const [loaded, setLoaded] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const user = useSelector((state: any) => state.userInReducer.user)
  const launchDarklyId = getEnvVariable('REACT_APP_LAUNCH_DARKLY_ID');

  useEffect(() => {
    localStorage.removeItem('user')
    content.load().then((d) => {
      setLoaded(true)
    })
  }, [])

  useEffect(() => {
    if (user?.id !== '') {
      setLoggedIn(true)
    }
  }, [user])

  if (loaded === false) { return (<></>) }

  const userAccessingReportWithoutAuth = isUserAccessingReportWithoutAuth()

  return (
    <LDProvider clientSideID={launchDarklyId} options={launchDarklyOptions}>
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="*"><Redirect to='/error' /></Route>
          <Route exact path="/changePassword" component={AccountRecoverPasswordPassword}></Route>

          <Route exact path="/contentpage"><Redirect to='/admin/contentpage' /></Route>
          <Route exact path="/admin/contentpage" component={ContentPage}></Route>
          <Route exact path="/admin/reports/vetshares" component={VetShareReportPage}></Route>

          {/* account portal */}
          <Route exact path="/pets/:patientHandle" component={AccountCatSingle}></Route>
          <Route exact path="/pets/:patientHandle/edit" component={AccountCatSingleEdit}></Route>
          <Route exact path="/pet/new/confirmation/:handle" component={AccountCatNewConfirmation}></Route>
          <Route exact path="/pet/new" component={AccountCatNew}></Route>
          <Route exact path="/pets" component={AccountCat}></Route>
          {/* we need to handle fallbacks this way because ":patientHandle" is lost in the redirect */}
          {/* /cats/myCatHandle becomes /pets/:patientHandle */}
          {/* <Route exact path="/cats/:patientHandle"><Redirect to='/pets/:patientHandle' /></Route> */}
          {/* potential approach if this become an issue: https://stackoverflow.com/questions/43399740/react-router-redirect-drops-param */}
          <Route exact path="/cats/:patientHandle" component={AccountCatSingle}></Route>
          <Route exact path="/cats/:patientHandle/edit" component={AccountCatSingleEdit}></Route>
          <Route exact path="/cat/new/confirmation/:handle" component={AccountCatNewConfirmation}></Route>
          <Route exact path="/cat/new" component={AccountCatNew}></Route>
          <Route exact path="/cats" component={AccountCat}></Route>

          <Route exact path="/orders" component={AccountOrders}></Route>

          <Route exact path="/kit/register/:handle" component={AccountOrdersNew}></Route>
          <Route exact path="/kit/register" component={AccountOrdersNew}></Route>
          <Route exact path="/kit/register/confirmation" component={AccountOrdersNewConfirmation}></Route>
          <Route exact path="/kit/register/confirmation/:handle" component={AccountOrdersNewConfirmation}></Route>

          <Route exact path="/profile"
            render={(props) => {
              return userAccessingReportWithoutAuth ? <Error {...props} /> : <AccountProfile />
            }}

          />
          <Route exact path="/profile/ChangePassword" component={AccountChangePassword}></Route>
          <Route exact path="/profile/edit" component={AccountEditProfile}></Route>

          {/* routes without user */}
          <Route exact path="/login" component={AccountLogin}></Route>
          <Route exact path="/register" component={AccountRegister}></Route>

          <Route exact path="/recoverPasswordEmail" component={AccountRecoverPasswordEmail}></Route>
          <Route exact path="/recoverPasswordEmail/confirmation" component={AccountRecoverPasswordEmailConfirmation}></Route>
          <Route exact path="/recoverPasswordPassword/confirmation" component={AccountRecoverPasswordPasswordConfirmation}></Route>

          <Route exact path="/error" component={Error}></Route>
          <Route exact path="/"><Redirect to={`${loggedIn ? '/profile' : '/login'}`} /></Route>
          <Route ><Redirect to='/error' /></Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
    </LDProvider>
  )
}

export default App
