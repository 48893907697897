import React, { useState } from 'react'
import { IonRow, IonCol, IonCard } from '@ionic/react'

import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import content from 'util/content'
import styles from './CatFilters.module.scss'
import SortByButton from './SortByButton'

const CatFilters = (props) => {
  const { active, categoryHandler, children, orderHandler, sortingOptions, showFilters } = props
  const [displaySortingOptions, setSortingOptions] = useState(true)
  return (
    <>
      <IonRow>
        <IonCol size="12">
          <IonCard className={styles.card_cat_filters}>
            <IonRow>
              <IonCol size-sm="6" size-md="8" size="12">
                {showFilters &&
                  <>
                  <div className="d-flex">
                    <EditableIonButton
                      mode="md"
                      color="dark"
                      shape="round"
                      fill="clear"
                      className={`Running_head_1 ${active === 0 ? styles.active : ''}`}
                      aria-label={`Filter by ${content.get('MY_CATS.FILTERS.TITLE1')}`}
                      onClick={() => {
                        categoryHandler(0)
                        setSortingOptions(true)
                      }}
                    >
                      {content.get('MY_CATS.FILTERS.TITLE1')}
                    </EditableIonButton>
                    <EditableIonButton
                      mode="md"
                      color="dark"
                      fill="clear"
                      shape="round"
                      className={`Running_head_1 ${active === 1 ? styles.active : ''}`}
                      aria-label={`Filter by ${content.get('MY_CATS.FILTERS.TITLE2')}`}
                      onClick={() => {
                        categoryHandler(1)
                        setSortingOptions(false)
                      }}
                    >
                    {content.get('MY_CATS.FILTERS.TITLE2')}
                    </EditableIonButton>
                  </div>
                  </>
                }
                </IonCol>
                <IonCol size-sm="6" size-md="4" size="12">
                  <SortByButton
                    aria-label="Sort list by options"
                    orderHandler={orderHandler}
                    options={sortingOptions}
                    shouldDisplay={displaySortingOptions}
                  ></SortByButton>
                </IonCol>
              </IonRow>
          </IonCard>
        </IonCol>
      </IonRow>
      {children}
    </>
  )
}

export default CatFilters
