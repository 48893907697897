import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import content from 'util/content'

import { IonIcon } from '@ionic/react'
import { arrowBackSharp } from 'ionicons/icons'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

import styles from './BackButton.module.scss'

const BackButton = (props) => {
  const { goBackUrl, HandlerStepContext } = props
  const history = useHistory()

  let setActualFormStep = null
  let actualFormStep = null
  if (HandlerStepContext) {
    [actualFormStep, setActualFormStep] = useContext(HandlerStepContext)
  }

  return (
    <>
      <EditableIonButton
        mode="md"
        onClick={() => {
          if (HandlerStepContext && (actualFormStep > 1)) {
            setActualFormStep(actualFormStep - 1)
          } else {
            if (goBackUrl) {
              history.push(goBackUrl)
            } else {
              history.goBack()
            }
          }
        }}
        shape="round"
        fill="outline"
        color="primary"
        aria-label="Go back to the previous page"
        class={`${styles.back_button} icon-start default_button`}
      >
        <IonIcon icon={arrowBackSharp}></IonIcon>
        {content.get('CTA_BACK')}
      </EditableIonButton>
    </>
  )
}

export default BackButton
