import React, { useState } from 'react'
import { IonItem, IonSelect, IonSelectOption, IonIcon } from '@ionic/react'
import content from 'util/content'
import { reorderTwoSharp } from 'ionicons/icons'

const SortByButton = (props) => {
  const { orderHandler, options, shouldDisplay } = props
  const [orderValue, setOrderValue] = useState('name')

  return (
    shouldDisplay &&
    <div className="d-flex ion-justify-content-end">
      <p className="Body_1 text_dark_grey ion-no-margin mr-2">{content.get('MY_CATS.SORT.TITLE')}</p>
      <IonItem lines="none" className="item-no-padding">
        <IonSelect
          value={orderValue}
          onIonChange={(e) => {
            setOrderValue(e.detail.value)
            orderHandler(e.detail.value)
          }}
          interface="popover"
          placeholder="Select One"
          className="Body_1_bold text_black"
        >
          {options && options.map(
            (option, index) => {
              return (
                <IonSelectOption key={index} value={option.value}>{option.label}</IonSelectOption>
              )
            })
          }
        </IonSelect>
        <IonIcon color="dark" icon={reorderTwoSharp}/>
      </IonItem>
    </div>
  )
}

export default SortByButton
