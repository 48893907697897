import React, { useEffect, useState } from 'react'
import {
  IonGrid,
  IonCol,
  IonRow,
  useIonViewWillEnter,
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption
} from '@ionic/react'
import {
  arrowBackSharp,
  arrowForwardSharp,
  caretDownSharp,
  caretUpSharp,
  downloadSharp
} from 'ionicons/icons'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import Accordion from 'components/basepaws/utils/Accordion'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import SearchSortFilter from 'components/basepaws/general/SearchFilterSort/SearchFilterSort'
import services from 'util/services'

/* eslint-disable */
interface ReportFields {
  vet_name: string;
  vet_email: string;
  vet_phone: string;
  vet_clinic: string;
  customer_name: string;
  customer_email: string;
  pet_name: string;
  report: string;
  kit_barcode: string;
  date: string;
}
/* eslint-enable */

const VetShareReportPage = () => {
  const history = useHistory()

  // typing as any to avoid error
  const user = useSelector((state: any) => state.userInReducer.user)

  useIonViewWillEnter(async () => {
    let redirectToLogin = false
    if (!user?.id) {
      const load = async () => {
        const localUser = await services.user()
        redirectToLogin = !localUser?.id
      }
      await load()
    }
    if (redirectToLogin) {
      history.push('/login')
    }
  })

  const fieldsToSearch = [
    'vet_name',
    'vet_email',
    'vet_phone',
    'vet_clinic',
    'customer_name',
    'customer_email',
    'pet_name',
    'report',
    'kit_barcode',
    'date'
  ]

  const [reports, setReports] = useState([])

  const [reportsFiltered, setReportsFiltered] = useState([...reports])
  const [reportsToShow, setReportsToShow] = useState([...reportsFiltered])

  const [shareToggle, setShareToggle] = useState(
    new Array(reports.length).fill(false)
  )

  // states to order
  const [orderField, setOrderField] = useState('date')
  const [defaultOrderDirection, setDefaultOrderDirection] = useState('desc')

  // states to paginate (calculated over the reportsToShow)
  const [currentPage, setCurrentPage] = useState(1)
  const [reportsPerPage, setReportsPerPage] = useState(20)
  const [totalPages, setTotalPages] = useState(
    Math.ceil(reportsFiltered.length / reportsPerPage)
  )

  useEffect(() => {
    const fetchReports = async () => {
      const rep = await services.getVetSharesReports()
      setReports(rep)
      setReportsFiltered([...rep] as never[]) // to avoid error
    }
    fetchReports()
  }, [])

  useEffect(() => {
    setCurrentPage(1)
    setTotalPages(Math.ceil(reportsFiltered.length / reportsPerPage))
    const repToShow = reportsFiltered.slice(
      (currentPage - 1) * reportsPerPage,
      currentPage * reportsPerPage
    )
    setReportsToShow(repToShow)
  }, [reportsPerPage, reportsFiltered])

  useEffect(() => {
    const repToShow = reportsFiltered.slice(
      (currentPage - 1) * reportsPerPage,
      currentPage * reportsPerPage
    )
    setReportsToShow(repToShow)
  }, [reportsFiltered, defaultOrderDirection, currentPage])

  const orderHandler = (order: string = 'date') => {
    setOrderField(order)
    defaultOrderDirection === 'desc'
      ? setDefaultOrderDirection('asc')
      : setDefaultOrderDirection('desc')

    let rep = reportsFiltered.sort((a, b) => {
      if (a[order as keyof typeof a] < b[order as keyof typeof b]) {
        return -1
      }
      if (a[order as keyof typeof a] > b[order as keyof typeof b]) {
        return 1
      }
      return 0
    })

    rep = defaultOrderDirection === 'desc' ? rep.reverse() : rep
    setReportsFiltered(rep)
    setCurrentPage(1)
  }

  const toggleFunction = (toggleId: any) => {
    const toggles = shareToggle
    setShareToggle({ ...toggles, [toggleId]: !toggles[toggleId] })
  }

  const exportCSVFunction = (reports: any[]) => {
    const titles = [
      'vet_name',
      'vet_email',
      'vet_phone',
      'vet_clinic',
      'customer_name',
      'customer_email',
      'pet_name',
      'report',
      'kit_barcode',
      'date'
    ]

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      titles.join(';') +
      '\n' +
      reports
        .map((report) => {
          return titles
            .map((key: any) => report[key as keyof ReportFields])
            .join(';')
        })
        .join('\n')
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    const today = new Date().toLocaleDateString('en-US').replaceAll('/', '-')
    link.setAttribute('download', `vetshare-report-${today}.csv`)
    document.body.appendChild(link) // Required for FF
    link.click()
    document.body.removeChild(link) // Required for FF
  }

  const buttonFilteredCSV = (
    <IonCol className="text-align-sm-right">
      <IonButton
        fill="outline"
        color="primary"
        shape="round"
        onClick={() => {
          exportCSVFunction(reportsFiltered)
        }}
      >
        Download Filtered CSV
        <IonIcon slot="end" icon={downloadSharp} />
      </IonButton>
    </IonCol>
  )

  return (
    <GeneralPage
      user={user}
      title="Vet Sharing Report Tool"
      titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom"
      headerStyle="left"
      actionStyle="right"
      isNotFixed={true}
      actions={
        <IonButton
          fill="solid"
          color="primary"
          shape="round"
          className="default_button"
          onClick={() => {
            exportCSVFunction(reports)
          }}
        >
          Download Full CSV
          <IonIcon slot="end" icon={downloadSharp} />
        </IonButton>
      }
    >
      <div id="datepicker-portal"></div>
      <IonRow className="search-sort-fullscreen">
        <SearchSortFilter
          enabledFeatures={{
            search: true,
            sort: false,
            filter: false,
            filterByDate: true
          }}
          align="start"
          defaultOrder={'date'}
          sortingOptions={[
            {
              label: 'Date',
              value: 'date'
            },
            {
              label: 'Vet Name',
              value: 'vet_name'
            },
            {
              label: 'Customer Name',
              value: 'customer_name'
            },
            {
              label: 'Pet Name',
              value: 'pet_name'
            },
            {
              label: 'Report Name',
              value: 'report'
            }
          ]}
          searchPlaceholder="Search by cat, cat’s owner or vet"
          searchIn={reports}
          sortIn={reportsFiltered}
          fieldsToSearch={fieldsToSearch}
          setResultFunction={setReportsFiltered}
          rightElement={buttonFilteredCSV}
        />
      </IonRow>
      <IonGrid fixed>
        <IonRow className="mx-2 ion-justify-content-start ion-margin-top ion-padding-top no-tablet">
          <IonCol size="2">
            <IonButton
              fill="clear"
              mode="md"
              className={`table-sort-button ${
                orderField === 'vet_name' ? 'active' : ''
              }`}
              onClick={(e) => orderHandler('vet_name')}
            >
              <p className="Body_1 text_dark_grey ion-no-margin">Vet</p>
              <IonIcon
                color="dark"
                slot="end"
                icon={
                  defaultOrderDirection === 'desc'
                    ? caretUpSharp
                    : caretDownSharp
                }
              />
            </IonButton>
          </IonCol>
          <IonCol size="2">
            <IonButton
              fill="clear"
              mode="md"
              className={`table-sort-button ${
                orderField === 'customer_name' ? 'active' : ''
              }`}
              onClick={(e) => orderHandler('customer_name')}
            >
              <p className="Body_1 text_dark_grey ion-no-margin">Pet Owner</p>
              <IonIcon
                color="dark"
                slot="end"
                icon={
                  defaultOrderDirection === 'desc'
                    ? caretUpSharp
                    : caretDownSharp
                }
              />
            </IonButton>
          </IonCol>
          <IonCol size="2">
            <IonButton
              fill="clear"
              mode="md"
              className={`table-sort-button ${
                orderField === 'pet_name' ? 'active' : ''
              }`}
              onClick={(e) => orderHandler('pet_name')}
            >
              <p className="Body_1 text_dark_grey ion-no-margin">Pet</p>
              <IonIcon
                color="dark"
                slot="end"
                icon={
                  defaultOrderDirection === 'desc'
                    ? caretUpSharp
                    : caretDownSharp
                }
              />
            </IonButton>
          </IonCol>
          <IonCol size="3">
            <IonButton
              fill="clear"
              mode="md"
              className={`table-sort-button ${
                orderField === 'report' ? 'active' : ''
              }`}
              onClick={(e) => orderHandler('report')}
            >
              <p className="Body_1 text_dark_grey ion-no-margin">Test</p>
              <IonIcon
                color="dark"
                slot="end"
                icon={
                  defaultOrderDirection === 'desc'
                    ? caretUpSharp
                    : caretDownSharp
                }
              />
            </IonButton>
          </IonCol>
          <IonCol size="2">
            <IonButton
              fill="clear"
              mode="md"
              className={`table-sort-button ${
                orderField === 'date' ? 'active' : ''
              }`}
              onClick={(e) => orderHandler('date')}
            >
              <p className="Body_1 text_dark_grey ion-no-margin">Date</p>
              <IonIcon
                color="dark"
                slot="end"
                icon={
                  defaultOrderDirection === 'desc'
                    ? caretUpSharp
                    : caretDownSharp
                }
              />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow className="mx-2 ion-margin-bottom ion-padding-bottom mt-sm-5">
          <IonCol size="12">
            {reportsToShow.length > 0
              ? (
              <Accordion
                className="line"
                list={reportsToShow}
                toggleFunction={toggleFunction}
                toggleVar={shareToggle}
                renderHeader={(item) => (
                  <>
                    <IonCol size-lg="2" size="push">
                      <p className="Body_1_bold text_black ion-no-margin">
                        {item.vet_name}
                      </p>
                    </IonCol>
                    <IonCol size="push" className="no-desktop">
                      <span>-</span>
                    </IonCol>
                    <IonCol size-lg="2" size="push">
                      <p className="Body_1 text_black ion-no-margin">
                        {item.customer_name}
                      </p>
                    </IonCol>
                    <IonCol size="push" className="no-desktop">
                      <span>-</span>
                    </IonCol>
                    <IonCol size-lg="2" size="push">
                      <p className="Body_1 text_black ion-no-margin">
                        {item.pet_name}
                      </p>
                    </IonCol>
                    <IonCol size-lg="3" size="12">
                      <p className="Body_1_bold text_black ion-no-margin">
                        {item.report}
                      </p>
                    </IonCol>
                    <IonCol size-lg="2" size="6">
                      <p className="Body_1 text_black ion-no-margin">
                        {item.date}
                      </p>
                    </IonCol>
                  </>
                )}
                renderPanel={(item) => (
                  <>
                    <IonRow>
                      <IonCol size-lg="2" size="12">
                        <p className="Running_head_3 text_medium_grey no-desktop">
                          Vet data
                        </p>
                        <p className="Body_2 text_black mt-2 mb-2">
                          {item.vet_email}
                        </p>
                        <p className="Body_2 text_black mt-0 mb-2">
                          {item.vet_phone}
                        </p>
                        <p className="Body_2 text_black mt-0 mb-2">
                          {item.vet_clinic}
                        </p>
                      </IonCol>
                      <IonCol size-lg="2" size="12">
                        <p className="Running_head_3 text_medium_grey no-desktop">
                          Costumer data
                        </p>
                        <p className="Body_2 text_black mt-2 mb-2">
                          {item.customer_email}
                        </p>
                      </IonCol>
                      <IonCol size-lg="2"></IonCol>
                      <IonCol size-lg="3" size="12">
                        <p className="Running_head_3 text_medium_grey no-desktop">
                          Product ID
                        </p>
                        <p className="Body_2 text_black mt-2 mb-2">
                          {item.kit_barcode}
                        </p>
                      </IonCol>
                      <IonCol size-lg="2"></IonCol>
                    </IonRow>
                  </>
                )}
              />
                )
              : (
              <IonRow className={'ion-justify-content-center'}>
                <IonCol size-md="8" className="ion-text-center">
                  <h1 className="Heading_1 text_cyan">
                    There are no results for your search
                  </h1>
                  <p className="Lead_1 text_dark_grey">
                    Please change your search terms and try again 😺
                  </p>
                </IonCol>
              </IonRow>
                )}
          </IonCol>
        </IonRow>

        {/* Pagination */}
        <IonRow className="ion-justify-content-between">
          <IonCol size-md="6" size="12">
            <div className="d-flex ion-align-items-center">
              {currentPage > 1
                ? (
                <IonButton
                  mode="md"
                  fill="clear"
                  color="primary"
                  className="default-button"
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  Prev
                  <IonIcon icon={arrowBackSharp} slot="start" />
                </IonButton>
                  )
                : (
                    ''
                  )}

              <ul className="pagination_numbers">
                {[...Array(totalPages)].map((_, i) => {
                  return (
                    <li
                      key={`pagination-key-map-${i + 1}`}
                      className={`Body_1_bold ${
                        currentPage === i + 1 ? 'active' : ''
                      }`}
                      onClick={() => setCurrentPage(i + 1)}
                    >
                      {i + 1}
                    </li>
                  )
                })}
              </ul>

              {currentPage < totalPages
                ? (
                <IonButton
                  mode="md"
                  fill="clear"
                  color="primary"
                  className="default-button"
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Next
                  <IonIcon icon={arrowForwardSharp} slot="end" />
                </IonButton>
                  )
                : (
                    ''
                  )}
            </div>
          </IonCol>
          <IonCol size-md="6" size="12">
            <div className="d-flex ion-align-items-center justify-content-md-end">
              <IonSelect
                interface="popover"
                placeholder={`See ${reportsPerPage} results`}
                className="Body_1_bold text_black border-0"
                value={reportsPerPage}
                onIonChange={(e) => setReportsPerPage(parseInt(e.detail.value))}
              >
                {[20, 30, 50, 100].map((item, index) => {
                  return (
                    <IonSelectOption key={index} value={item}>
                      See {item} results
                    </IonSelectOption>
                  )
                })}
              </IonSelect>
              <IonIcon size="14" color="dark" icon={caretDownSharp} />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </GeneralPage>
  )
}

export default VetShareReportPage
