import React from 'react'
import { IonRow, IonCol, IonImg } from '@ionic/react'

import styles from './AccountOrders.module.scss'
import content from 'util/content'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import EmptyState from 'components/basepaws/general/EmptyState'

// Empty state - No Kits
const AccountOrdersEmptyKits = () => {
  return (
    <EmptyState
      asset='assets/icons/data/pet.svg'
      assetType='image'
      content={
        <EditableIonButton
          mode="md"
          color="primary"
          shape="round"
          className="ion-margin-top big_button"
          routerLink='/kit/register'
          >
            {content.get('MY_ORDERS.EMPTY_KITS.CTA_TITLE')}
          </EditableIonButton>
      }
      text={content.get('MY_ORDERS.EMPTY_KITS.DESCRIPTION')}
      title={content.get('MY_ORDERS.EMPTY_KITS.TITLE')}
    />
  )
}

export default AccountOrdersEmptyKits
