/* eslint-disable react/no-unescaped-entities */
import { withIonLifeCycle } from '@ionic/react'
import React from 'react'

import services from 'util/services'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import EmptyState from 'components/basepaws/general/EmptyState'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import content from 'util/content'
import { alertSharp } from 'ionicons/icons'
import { isUserAccessingReportWithoutAuth } from 'util/utils'

class Confirm extends React.Component {
  constructor () {
    super()
    this.state = {}
  }

  async load () {
    const user = await services.user()
    this.setState({ user: user })
  }

  async ionViewWillEnter () { this.load() }

  render (props) {
    const userAccessingReportWithoutAuth = isUserAccessingReportWithoutAuth()

    return (
      <GeneralPage
        user={this.state.user}
        pageClass="page-404"
        header='none'
      >
        <EmptyState
          asset={alertSharp}
          assetType='icon'
          content={
            <EditableIonButton
              mode="md"
              color="primary"
              shape="round"
              className="ion-margin-top big_button"
              routerLink="/profile"
            >
              {content.get('ERROR_PAGE.BACK_MY_PATIENTS')}
            </EditableIonButton>
          }
          text={userAccessingReportWithoutAuth ? content.get('ERROR_PAGE.ACCESS_REPORT_USER_DOES_NOT_OWN') : content.get('ERROR_PAGE.TEXT') }
          title={content.get('ERROR_PAGE.TITLE')}
        />
      </GeneralPage>
    )
  }
}

export default withIonLifeCycle(Confirm)
