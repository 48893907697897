import React from "react";
import { withIonLifeCycle, IonRow, IonCol } from "@ionic/react";

import { connect } from "react-redux";

import RecoverPasswordFormEmail from "components/basepaws/account/RecoverPasswordForms/RecoverPasswordFormEmail";
import GeneralPage from "components/basepaws/general/GeneralPage/GeneralPage";

class AccountRecoverPasswordEmail extends React.Component {
  render() {
    const user = this.props.user;

    return (
      <GeneralPage
        title="Recover Password"
        titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom ion-text-center"
        headerStyle="center"
        user={user}
      >
        <IonRow className="ion-justify-content-center">
          <IonCol size-lg="4" size-md="6" size="11">
            <RecoverPasswordFormEmail />
          </IonCol>
        </IonRow>
      </GeneralPage>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData,
  };
};

export default connect(
  mapStateToProps,
  null
)(withIonLifeCycle(AccountRecoverPasswordEmail));
