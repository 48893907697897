import React from 'react'
import { } from '@ionic/react'
import styles from './MessageBanner.module.scss'

const MessageBanner = (props) => {
  const { className, children } = props

  return (
    <>
    <div className={`${styles.message_banner} ${className ? { className } : ''}`}>
      <p className='Body_2'>
        {children}
      </p>
    </div>
    </>
  )
}

export default MessageBanner
