
import React, { useState } from 'react'
import { IonCol, IonIcon, IonImg, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow } from '@ionic/react'
import { arrowForward } from 'ionicons/icons'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import content from 'util/content'
import styles from './BigRadioGroup.module.scss'

const BigRadioGroup = (props) => {
  const { setSelection, onSubmit } = props

  const [currentSelection, setCurrentSelection] = useState()

  return (<>
    <IonRow className="ion-margin-vertical ion-justify-content-between">
      <IonCol size-lg="5" size-md="12" size="12">
        <h3 className={`Heading_3 text_sad_purple ${styles.selection_title}`}>{content.get('NEW_PET.WHICH_TYPE_PET')}</h3>
      </IonCol>
      <IonCol size-lg="6" size-md="12" size="12">
        <IonItem lines="none" className="ion-margin-top ion-margin-bottom item-no-padding-right form">
          <IonRadioGroup
            name="species"
            mode='md'
            className='big-radio-group'
            value={currentSelection}
            onIonChange={(event) => {
              setCurrentSelection(event.target.value)
            }}
          >
            <IonItem lines="none" className="item-no-padding">
              <div className={'input-radio-wrapper'}>
                <div className={' input-radio'}>
                  <IonRadio value="cat" />
                  <IonLabel>{content.get('NEW_PET.TYPE_CAT')}</IonLabel>
                  <IonImg src="/assets/icon/basepaws-icon-pet-feline.svg" alt="Pet feline" />
                </div>
              </div>
            </IonItem>
            <IonItem lines="none" className="item-no-padding">
              <div className={'input-radio-wrapper'}>
                <div className={'input-radio'}>
                  <IonRadio value="dog" />
                  <IonLabel>{content.get('NEW_PET.TYPE_DOG')}</IonLabel>
                  <IonImg src="/assets/icon/basepaws-icon-pet-canine.svg" alt="Pet canine" />
                </div>
              </div>
            </IonItem>
          </IonRadioGroup>
        </IonItem>
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol className={styles.selection_footer}>
        <EditableIonButton
          className='big_button'
          shape='round'
          fill='solid'
          mode='md'
          color='primary'
          disabled={!currentSelection}
          onClick={() => {
            setSelection(currentSelection)
            onSubmit()
          }}>
          <IonLabel>{content.get('NEW_PET.BUTTON_CONTINUE')}</IonLabel>
          <IonIcon icon={arrowForward} />
        </EditableIonButton>
      </IonCol>
    </IonRow></>
  )
}

export default BigRadioGroup
