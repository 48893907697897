import React from 'react'

import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import EmptyState from 'components/basepaws/general/EmptyState'
import content from 'util/content'

const AccountOrdersEmptyCats = () => {
  return (
    <EmptyState
      asset='assets/icons/data/pet.svg'
      assetType='image'
      content={
        <EditableIonButton
          mode="md"
          color="primary"
          shape="round"
          className="ion-margin-top big_button"
          routerLink='/pet/new'
          >
            {content.get('MY_ORDERS.EMPTY_CATS.CTA_TITLE')}
          </EditableIonButton>
      }
      text={content.get('MY_ORDERS.EMPTY_CATS.DESCRIPTION')}
      title={content.get('MY_ORDERS.EMPTY_CATS.TITLE')}
    />
  )
}

export default AccountOrdersEmptyCats
