import React from 'react'
import { IonRow, IonCol, IonCard, IonCardContent, IonIcon } from '@ionic/react'
import { enterSharp } from 'ionicons/icons'
import routes from 'constants/routes.json'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

import styles from './OrderAdvertisingCard.module.scss'
import content from 'util/content'
import services from 'util/services'

const OrderAdvertisingCard = () => {
  return (
    <IonCard className={`${styles.my_orders_advertising_card} card`}>
      <IonCardContent>
        <img src="assets/images/basepaws-dental-kit-banner.png" alt="Basepaws CatKit test box" />
        <IonRow className="ion-justify-content-start ion-align-items-center">
          <IonCol size="12" size-md="10" size-lg="5" offset-lg="4">
            <h4 className="Heading_3">{content.get('MY_ORDERS.BANNER.TITLE')}</h4>
            <p className="Body_1">{content.get('MY_ORDERS.BANNER.DESCRIPTION')}</p>
          </IonCol>
          <IonCol size="12" size-md="12" size-lg="3">
            <EditableIonButton
              mode="md"
              color="primary"
              shape="round"
              onClick={() => {
                services.trackEvent({
                  category: 'My Orders',
                  action: 'Buy more CatKits cta',
                  label: 'Button'
                })
              }}
              href={routes.LINKS.EXTERNAL.SHOP_ALL_PRODUCTS}
              target="_blank"
              className="default_button"
            >
              {content.get('MY_ORDERS.BANNER.CTA_TITLE')}
              <IonIcon slot="end" color="light" icon={enterSharp}></IonIcon>
            </EditableIonButton>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  )
}

export default OrderAdvertisingCard
