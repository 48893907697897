import React from 'react'

import { IonFooter, IonGrid, IonRow, IonCol } from '@ionic/react'
import content from 'util/content'

import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <>
      <IonFooter>
        <div className={styles.pattern}></div>
        <IonGrid fixed>
          <IonRow className="ion-margin-top ion-justify-content-center">
            <IonCol size-md="6" size="12">
              <div className={`${styles.email_us_wrapper}`}>
                <p className="Body_1">{content.get('FOOTER.EMAIL_US.TEXT')}</p>
                <p className="Body_1_bold"><a href={`mailto:${content.get('FOOTER.EMAIL_US.EMAIL')}`} aria-label={`Send a email to ${content.get('FOOTER.EMAIL_US.EMAIL')}`}>{content.get('FOOTER.EMAIL_US.EMAIL')}</a></p>
              </div>
            </IonCol>
            <IonCol size-md="6" size="12" className={styles.links_wrapper}>
              <p className={`Body_1_bold ${styles.footer_links}`}>
                <a
                  href={content.get('FOOTER.EXTERNALS.LINK_URL5', [], true)}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Go to ${content.get('FOOTER.EXTERNALS.LINK_TEXT5')} page`}
                >
                  {content.get('FOOTER.EXTERNALS.LINK_TEXT5')}
                </a>
                <span className="text_light_grey">  |  </span>
                <a
                  href={content.get('FOOTER.EXTERNALS.LINK_URL4', [], true)}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Go to ${content.get('FOOTER.EXTERNALS.LINK_TEXT4')} page`}
                >
                  {content.get('FOOTER.EXTERNALS.LINK_TEXT4')}
                </a>
                <span className="text_light_grey">  |  </span>
                <a
                  href={content.get('FOOTER.EXTERNALS.LINK_URL1', [], true)}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Go to ${content.get('FOOTER.EXTERNALS.LINK_TEXT1')} page`}
                >
                  {content.get('FOOTER.EXTERNALS.LINK_TEXT1')}
                </a>
                <span className="text_light_grey">  |  </span>
                <a
                  href={content.get('FOOTER.EXTERNALS.LINK_URL2', [], true)}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Go to ${content.get('FOOTER.EXTERNALS.LINK_TEXT2')} page`}
                >
                  {content.get('FOOTER.EXTERNALS.LINK_TEXT2')}
                </a>
                <span className="text_light_grey">  |  </span>
                <a
                  href={content.get('FOOTER.EXTERNALS.LINK_URL3', [], true)}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Go to ${content.get('FOOTER.EXTERNALS.LINK_TEXT3')} page`}
                >
                  {content.get('FOOTER.EXTERNALS.LINK_TEXT3')}
                </a>
              </p>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size-md="6" size="12">
              <img className={styles.footer_brand} src="assets/images/basepaws-logo-footer.svg" alt="Basepaws logo in grey color" />
            </IonCol>
            <IonCol size-md="6" size="12" className={styles.footer_credentials}>
              <p>{content.get('FOOTER.CREDENTIALS')}</p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </>
  )
}

export default Footer
