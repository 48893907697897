import { updateObject } from '../../util/updateObject'

const initialState = {
  user: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    handle: '',
    last_login: '',
    confirmed: '',
    pets: [],
    link: '',
    old_id: '',
    nick: '',
    phone_number: '',
    created: '',
    updated_at: ''
  },
  organizationActive: null,
  organizationActiveData: {
    patients: [],
    resources: []
  }
}

const setUser = (state = initialState, action) => {
  return updateObject(state, {
    user: action.user
  })
}

const setActiveOrganization = (state = initialState, action) => {
  return updateObject(state, {
    organizationActive: action.organization
  })
}

const setActiveOrganizationData = (state = initialState, action) => {
  return updateObject(state, {
    organizationActiveData: action.organization
  })
}

const setKitsInfo = (state = initialState, action) => {
  return updateObject(state, {
    kitsInfo: action.kitsInfo
  })
}

const setKits = (state = initialState, action) => {
  return updateObject(state, {
    kits: action.kits
  })
}

const setPatients = (state = initialState, action) => {
  return updateObject(state, {
    patients: action.patients
  })
}

const setResources = (state = initialState, action) => {
  return updateObject(state, {
    resources: action.resources
  })
}

const setContributors = (state = initialState, action) => {
  return updateObject(state, {
    contributors: action.contributors
  })
}

const removeContributor = (state = initialState, action) => {
  const contributors = state.contributors.filter((item) => item.id !== action.contributorId)

  return updateObject(state, {
    contributors: contributors
  })
}

const cancelContributorInvitation = (state = initialState, action) => {
  const contributors = state.contributors.filter((item) => item.id !== action.contributorId)
  return updateObject(state, {
    contributors: contributors
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setUser': return setUser(state, action)
    case 'setActiveOrganization': return setActiveOrganization(state, action)
    case 'setOrganization': return setActiveOrganizationData(state, action)
    case 'setKitsInfo': return setKitsInfo(state, action)
    case 'setKits': return setKits(state, action)
    case 'setPatients': return setPatients(state, action)
    case 'setResources': return setResources(state, action)
    case 'setContributors': return setContributors(state, action)
    case 'removeContributor': return removeContributor(state, action)
    case 'cancelContributorInvitation': return cancelContributorInvitation(state, action)
    default: return state
  }
}

export default reducer
