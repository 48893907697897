import React, { useState, createContext } from 'react'
import { IonRow, IonCol, IonGrid, IonCard, useIonViewDidEnter } from '@ionic/react'
import { connect } from 'react-redux'
import CatForm from '../../../components/basepaws/account/catDetail/CatForm'
import styles from './AccountCatNew.module.scss'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage.js'
import BigRadioGroup from 'components/basepaws/form/BigRadioGroup'
import content from 'util/content'

const HandlerStepContext = createContext()

const AccountCatNew = (props) => {
  const { user } = props

  const [petSelected, setPetSelected] = useState()
  const [actualFormStep, setActualFormStep] = useState(1)

  useIonViewDidEnter(() => {
    setPetSelected('select')
    setActualFormStep(1)
  })

  return (
    <HandlerStepContext.Provider value={[actualFormStep, setActualFormStep]}>
      <GeneralPage
        user={user}
        title={petSelected && content.get('ACCOUNT.ADD_A_PET.TITLE')}
        headerStyle='center'
        actionStyle='left'
        pageClass={`${styles.cat_single}`}
        isNotFixed={true}
        HandlerStepContext={HandlerStepContext}
      >
        {petSelected && <IonGrid fixed>
          <IonCard className={styles.cat_card_edit}>
            <IonRow className="ion-justify-content-center">
              <IonCol size-md="8" size="11">
                {(actualFormStep !== 1)
                  ? <CatForm patient={{ organism: petSelected }} newPet={true} setSelection={setPetSelected} />
                  : <BigRadioGroup setSelection={setPetSelected} onSubmit={() => setActualFormStep(2)}/>
                }
              </IonCol>
            </IonRow>
          </IonCard>
        </IonGrid>
        }
      </GeneralPage >
    </HandlerStepContext.Provider>
  )
}

const mapStateToProps = state => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(mapStateToProps, null)(AccountCatNew)
