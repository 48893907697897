import React from 'react'

import { IonCard } from '@ionic/react'

import ChartCard from './ChartCard'
import ReportCard from './ReportCard'
import SurveyCard from './SurveyCard'

import styles from './DetailCard.module.scss'
import ReportEmptyCard from './ReportEmptyCard'

const DetailCard = (props) => {
  const { type, details, user, showActivate, showBuy, pet } = props
  let cardContent = null
  const shareVet = props.shareVet ?? true

  switch (type) {
    case 'chart':
      cardContent = <ChartCard/>
      break

    case 'report':
      cardContent = <ReportCard report={details} user={user} shareVet={shareVet} pet={pet}/>
      break

    case 'report-empty':
      cardContent = <ReportEmptyCard type={details.type} showActivate={showActivate} showBuy={showBuy}/>
      break

    case 'survey':
      cardContent = <SurveyCard survey={details}/>
      break

    default:
      cardContent = <SurveyCard survey={details}/>
      break
  }

  return (
    <>
      <IonCard className={`${styles.cat_single_card} card`}>
        {cardContent}
      </IonCard>
    </>
  )
}

export default DetailCard
