export const setPendingTasks = (payload) => {
  return {
    type: 'setPendingTasks',
    pendingTasks: payload
  }
}

export const calculatePendingTasks = (payload) => {
  return {
    type: 'calculatePendingTasks',
    pets: payload
  }
}
