import services from 'util/services'

export const setUser = (user) => {
  return {
    type: 'setUser',
    user: user
  }
}

export const setActiveOrganization = (organization) => {
  services.setActiveOrganization(organization)
  return {
    type: 'setActiveOrganization',
    organization: organization
  }
}

export const setOrganization = (organization) => {
  return {
    type: 'setOrganization',
    organization: organization
  }
}

export const setKitsInfo = (kitsInfo) => {
  return {
    type: 'setKitsInfo',
    kitsInfo: kitsInfo
  }
}

export const setKits = (kits) => {
  return {
    type: 'setKits',
    kits: kits
  }
}

export const setPatients = (patients) => {
  return {
    type: 'setPatients',
    patients: patients
  }
}

export const setResources = (resources) => {
  return {
    type: 'setResources',
    resources: resources
  }
}

export const setContributors = (contributors) => {
  return {
    type: 'setContributors',
    contributors: contributors
  }
}

export const removeContributor = (contributorId) => {
  return {
    type: 'removeContributor',
    contributorId: contributorId
  }
}

export const cancelContributorInvitation = (contributorId) => {
  return {
    type: 'cancelContributorInvitation',
    contributorId: contributorId
  }
}
