import React from 'react'
import styles from './LoaderButton.module.scss'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

interface IProps {
  type: 'button' | 'submit' | 'reset' | undefined
  disabled: boolean
  color: any
  className: string
  onClick?: any
  href: string
  isSubmitting: boolean
  editableProps?: any
  children: any
}

const LoaderButton = (props: IProps) => {
  const { type, disabled, color, className, onClick, href, isSubmitting, editableProps } = props

  return (
    <EditableIonButton
      type={type}
      disabled={disabled}
      color={color}
      fill="solid"
      shape="round"
      mode="md"
      className={`${styles.loader_button} ${className}`}
      onClick={onClick}
      routerLink={href}
      {...editableProps}
    >
      {props.children}
      {isSubmitting && <div className="loader"></div>}
    </EditableIonButton>
  )
}

export default LoaderButton
