import React, { useEffect, useState, useRef } from 'react'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonButton,
  IonIcon,
  useIonViewWillEnter
} from '@ionic/react'
import { chevronDownSharp } from 'ionicons/icons'
import content from 'util/content'
import PendingTaskCard from './PendingTaskCard'
import EmptyStateDashaboardCard from 'components/basepaws/general/EmptyStateDashboardCard'
import styles from './ProfilePendingTasksCard.module.scss'
import services from 'util/services'
import { useSelector } from 'react-redux'
import { surveyCategories } from '../OrderListItem/constants'

const ProfilePendingTasksCard = (props) => {
  const { pets } = props
  const [lengthToShow, setLengthToShow] = useState(2)

  const pendingTasksArray = useSelector(
    (state) => state.pendingTasks.pendingTasks
  )
  const [pendingTasks, setPendingTasks] = useState(pendingTasksArray)

  const categories = surveyCategories()

  useEffect(() => {
    setPendingTasks(pendingTasksArray)
    setEmptyTasks(pendingTasksArray.length === 0)
  }, [pendingTasksArray])

  const [emptyTasks, setEmptyTasks] = useState(true)

  const [isLoadMore, setLoadMore] = useState(false)
  const cardRef = useRef(null)

  useEffect(() => {
    setLengthToShow(isLoadMore ? pendingTasksArray.length : 2)
  }, [isLoadMore])

  useIonViewWillEnter(() => {
    setLoadMore(false)
  })

  return (
    <IonCard
      ref={cardRef}
      className={`${styles.my_profile_pendingtask_card} ${
        emptyTasks ? styles.my_profile_pendingtask_card_empty : ''
      } card`}
    >
      <IonCardHeader>
        <IonCardTitle className="Running_head_1 text_dark_grey">
          {content.get('DASHBOARD.PENDING_TASKS.SUBHEAD')}
        </IonCardTitle>
        {!emptyTasks && (
          <div className={`${styles.message} alert_message bg_grey`}>
            <p>{content.get('DASHBOARD.PENDING_TASKS.DESCRIPTION')}</p>
          </div>
        )}
      </IonCardHeader>
      <IonCardContent className="card-dashboard-cats-content">
        {emptyTasks
          ? (
          <EmptyStateDashaboardCard
            text={content.get('DASHBOARD.PENDING_TASKS.EMPTY.TITLE')}
          />
            )
          : (
              pendingTasks.slice(0, lengthToShow).map((task, index) => {
                const category = categories[task.category]
                const pet = pets?.find((pet) => pet.handle === task.petHandle)
                if (pet) {
                  task.petName = pet.name
                  const petImage = pet.images?.find((i) => i.hit === 0)
                  task.petAvatar = `${services.thumb(petImage, pet?.organism)}${
                petImage ? '&' : '?'
              }${pet.updated_at}`
                }
                return (
              <PendingTaskCard key={index} task={task} category={category} />
                )
              })
            )}
      </IonCardContent>
      <div className="ion-padding-start ion-padding-bottom">
        {pendingTasksArray.length > 2 && (
          <IonButton
            mode="md"
            slot="end"
            shape="round"
            color="primary"
            fill="clear"
            aria-label="Load more/less pending tasks"
            onClick={() => {
              setLoadMore(!isLoadMore)
            }}
            className="icon-start default_button load_more"
          >
            <IonIcon
              slot="start"
              icon={chevronDownSharp}
              className={`${isLoadMore ? styles.icon_open : ''}`}
            ></IonIcon>
            {isLoadMore ? 'Load Less' : 'Load More'}
          </IonButton>
        )}
      </div>
    </IonCard>
  )
}

export default ProfilePendingTasksCard
