import React, { useCallback } from 'react'
import { IonCol, IonSearchbar } from '@ionic/react'

import debounce from 'lodash.debounce'

const SearchBar = (props) => {
  const { placeholder, searchText, setSearchText } = props

  const changeHandler = (e) => setSearchText(e.detail.value)

  const debouncedChangeHandler = useCallback(
    debounce(changeHandler, 70)
    , [])

  return (
    <>
      <IonCol size-lg="4" size-md="5" size="12">
        <div className="d-flex no-wrap ion-align-items-center">
          <div className="flex-fill">
            <IonSearchbar placeholder={placeholder} value={searchText} onIonChange={debouncedChangeHandler}/>
          </div>
          {/* <div className="loader grey"></div> */}
        </div>
      </IonCol>
    </>
  )
}

export default SearchBar
