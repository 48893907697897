import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { IonRow, IonCol, IonList, IonItem, IonInput, IonLabel, IonIcon, IonListHeader, IonCardContent, IonRadioGroup, IonRadio, IonAvatar, IonImg, useIonViewWillEnter } from '@ionic/react'
import { alertCircleSharp, closeCircle } from 'ionicons/icons'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import content from 'util/content'

import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import contentEditableAware from 'components/basepaws/utils/hooks/contentEditableAware'
import Slider from 'react-slick'
import { Formik } from 'formik'
import services, { servicesNew } from 'util/services'

import styles from './OrderNewForm.module.scss'
import { emojiRegex } from 'util/constants'

const OrderNewForm = ({ pets, HandlerStepContext }) => {
  const editableContent = contentEditableAware()
  const [isIDchanged, setIsIDchanged] = useState(false)
  const [isIDRepeated, setIsIDRepeated] = useState(false)
  const [selectedPet, setSelectedPet] = useState('')
  const [newPet, setNewPet] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorMessageActivated, setErrorMessageActivated] = useState('')
  const [errorMessageRepeated, setErrorMessageRepeated] = useState(null)
  const [nameError, setNameError] = useState('')
  const [checkRepeated, setCheckRepeated] = useState(false)
  const [barcode, setBarcode] = useState()
  const [kit, setKit] = useState()
  const history = useHistory()
  const slider = useRef(null)
  const [isFieldTouched, setFieldTouched] = useState(false)
  const [isCodeValidated, setCodeValidated] = useState(false)

  const [actualFormStep, setActualFormStep] = useContext(HandlerStepContext)

  useIonViewWillEnter(() => {
    setNewPet('')
    setKit(null)
    setBarcode()
    setActualFormStep(1)
    setErrorMessage('')
    setErrorMessageRepeated('')
    setNameError('')
    setSelectedPet('')
    setIsIDchanged(false)
    setIsIDRepeated(false)
    setCheckRepeated(false)
  })

  useEffect(async () => {
    setCodeValidated(false)
    if (barcode && barcode.length > 13) {
      await servicesNew.get(`/kit/${barcode}/register`)
        .then((kit) => {
          setKit(kit)
          setCodeValidated(true)
          if (kit.activated) {
            setErrorMessageActivated('MY_ORDERS_NEW.FORM.ERRORS.KIT_ACTIVATED')
            setCodeValidated(false)
          }
          if (kit.product.includes('breed')) {
            setCheckRepeated(true)
          }
        })
        .catch((response) => {
          if (response.msg && response.msg.toLowerCase() === 'kit doesn\'t exist') {
            setErrorMessage('MY_ORDERS_NEW.FORM.ERRORS.KIT_INVALID')
          } else if (response.error) {
            if (response.error === 'vet kit in consumer app') {
              setErrorMessage('consumer.kitregistration.vet_error')
            } else if (response.error === 'resource not found') {
              setErrorMessage('MY_ORDERS_NEW.FORM.ERRORS.KIT_BAD_NUMBER')
            }
          }
          setCodeValidated(false)
        })
    } else {
      if (barcode?.length < 14 && errorMessage === '') {
        setErrorMessage('MY_ORDERS_NEW.FORM.ERRORS.KIT_INVALID_MISSING_NUMBERS')
        setCodeValidated(false)
      }
    }
  }, [barcode])

  const onSubmit = async (values, setSubmitting) => {
    services.trackEvent({
      category: 'Register a Kit',
      action: 'Register - 2nd step',
      label: 'Button'
    })

    const newPet = values.pet === '_new'
    let pet = null
    let nextURL = '/kit/register/confirmation'
    if (newPet) {
      try {
        pet = await services.addPet({ name: values.newPet, organism: kitOrganism })
        nextURL = `${nextURL}/${pet.handle}`
      } catch (e) {
        console.log(e)
        setSubmitting(false)
      }
    } else {
      pet = { id: values.pet }
      const selCat = pets.find((p) => p.id === values.pet)
      nextURL = `${nextURL}/${selCat.handle}`
    }

    if (kit.id) {
      if (kit.activated) {
        setErrorMessageActivated('MY_ORDERS_NEW.FORM.ERRORS.KIT_ACTIVATED')
        setSubmitting(false)
      } else {
        services.activateKit(kit, pet)
          .then((response) => {
            if (response) {
              history.push({
                pathname: nextURL,
                state: { newPetCreated: newPet }
              })
            } else {
              setErrorMessage('MY_ORDERS_NEW.FORM.ERRORS.SERVER_ERROR')
            }
            setSubmitting(false)
          })
          .catch((response) => {
            if (response.msg) {
              setErrorMessage(response.msg)
            } else {
              console.log(response)
            }
            setSubmitting(false)
          })
      }
    } else {
      setErrorMessage('MY_ORDERS_NEW.FORM.ERRORS.KIT_BAD_NUMBER')
      setSubmitting(false)
    }
  }

  const kitOrganism = kit?.organism

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    centerPadding: '7px',
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    adaptiveHeight: true,
    swipe: false
  }

  useEffect(() => { // need force update to re-render slider
    actualFormStep === 1 && slider.current.slickGoTo(0)
  }, [actualFormStep])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        pet: '',
        barcodeId: '',
        barcodeIdRepeat: '',
        newPet: ''
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        onSubmit(values, setSubmitting)
        resetForm(
          {
            values: {
              pet: '',
              barcodeId: '',
              barcodeIdRepeat: '',
              newPet: ''
            }
          }
        )
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <IonCardContent className={styles.card_content}>
            <IonRow>
              <Slider {...settings} ref={slider} className={styles.slider_wrapper}>
                <IonCol size-md="12" size="12"
                  className={actualFormStep === 1 ? '' : styles.hidden}
                >
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding ion-margin-bottom">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('MY_ORDERS_NEW.FORM.STEP1.TITLE')}</h4>
                        <p className="Body_3 text_dark_grey">{content.get('MY_ORDERS_NEW.FORM.STEP1.SUBTITLE')}</p>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonList className="form ion-margin-top">
                    <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                      <IonInput
                        id="barcodeId"
                        type="text"
                        name="barcodeId"
                        onIonInput={(e) => {
                          handleChange(e)
                          setIsIDchanged(true)
                          setErrorMessageActivated('')
                          if (values.barcodeIdRepeat === e.target.value) {
                            setErrorMessage('')
                            setBarcode(e.target.value)
                          } else {
                            if (isFieldTouched) {
                              setErrorMessage((values.barcodeId.length > 13 && values.barcodeIdRepeat.length > 13) ? 'MY_ORDERS_NEW.FORM.ERRORS.ID_NOT_MATCH' : 'MY_ORDERS_NEW.FORM.ERRORS.KIT_INVALID_MISSING_NUMBERS')
                            }
                          }
                        }}
                        onIonBlur={() => {
                          setFieldTouched(true)
                        }}
                        placeholder={content.get('MY_ORDERS_NEW.FORM.STEP1.FIELD1_PLACEHOLDER', [], true)}
                        value={values.barcodeId}
                        className={(errors.barcodeId && touched.barcodeId) || errorMessage !== '' ? 'has-error' : null}
                      />
                      {errors.barcodeId && touched.barcodeId
                        ? (
                          <IonItem className="ion-margin-bottom input-error-message">
                            <div className="input-error-label">
                              <IonIcon
                                icon={closeCircle}
                                color="danger"
                                slot="start"
                              />
                              {errors.barcodeId}
                            </div>
                          </IonItem>
                          )
                        : null}
                    </IonItem>
                    <IonItem lines="none" className="item-no-padding">
                      <IonInput
                        id="barcodeIdRepeat"
                        type="text"
                        name="barcodeIdRepeat"
                        onIonInput={(e) => {
                          handleChange(e)
                          setIsIDRepeated(true)
                          setErrorMessageActivated('')
                          if (values.barcodeId === e.target.value) {
                            setErrorMessage('')
                            setBarcode(values.barcodeId)
                          } else {
                            setErrorMessage((values.barcodeId.length > 13 && values.barcodeIdRepeat.length > 13) ? 'MY_ORDERS_NEW.FORM.ERRORS.ID_NOT_MATCH' : 'MY_ORDERS_NEW.FORM.ERRORS.KIT_INVALID_MISSING_NUMBERS')
                          }
                        }}
                        placeholder={content.get('MY_ORDERS_NEW.FORM.STEP1.FIELD2_PLACEHOLDER', [], true)}
                        value={values.barcodeIdRepeat}
                        className={(errors.barcodeIdRepeat && touched.barcodeIdRepeat) || errorMessage !== '' ? 'has-error' : null}
                      />
                    </IonItem>
                  </IonList>
                </IonCol>
                <IonCol size-md="12" size="12"
                  className={actualFormStep === 2 ? '' : styles.hidden}
                >
                  {kit && <div className={`${styles.alert} alert_message bg_grey text_dark_grey`}>
                    {editableContent
                      ? <p className="Body_3" >
                        {content.get('MY_ORDERS_NEW.FORM.STEP2.ABOUT_TO_REGISTER')}
                        {/* You are about to register a <b>{kit.productName} ({kit.code})</b> for the pet you will select below. */}
                      </p>
                      : <p className="Body_3"
                        dangerouslySetInnerHTML={{
                          __html: content.get('MY_ORDERS_NEW.FORM.STEP2.ABOUT_TO_REGISTER',
                            {
                              name: kit?.productName?.includes('type_') ? content.get(`report_card.${kit.productName}.name`) : kit.productName,
                              code: kit.code
                            },
                            true)
                        }}
                      >
                      </p>
                    }
                  </div>
                  }
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding ion-margin-bottom">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('MY_ORDERS_NEW.FORM.STEP2.TITLE')}</h4>
                        <p className="Body_3 text_dark_grey">{content.get('MY_ORDERS_NEW.FORM.STEP2.SUBTITLE')}</p>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonList className="form ion-margin-top">
                    <IonItem lines="none" className="item-no-padding">
                      <IonRadioGroup
                        value={values.pet}
                        name="pet"
                        onIonBlur={handleBlur}
                        onIonChange={(e) => {
                          handleChange(e)
                          const catHandle = e.detail.value
                          setSelectedPet(catHandle)
                          const localPet = pets.find(pet => pet.id === catHandle)
                          if (checkRepeated && localPet && localPet.reports.length > 0) {
                            const hasKit = localPet.reports.some(report => report.type.includes('breed'))
                            if (hasKit) {
                              setErrorMessageRepeated('MY_ORDERS_NEW.FORM.ERRORS.KIT_ACTIVATED')
                            }
                          } else {
                            setErrorMessageRepeated(null)
                          }
                        }}
                        className="w-100"
                        allowEmptySelection={false}
                        required
                      >
                        {pets?.filter(pet => pet.organism === kitOrganism).map((pet, index) => {
                          const petImage = pet?.images.find((i) => i.hit === 0)
                          return <IonItem key={index} className="input-radio ion-margin-bottom">
                            <IonRadio name="pet" value={pet.id} />
                            <IonLabel className={styles.label_avatar}>
                              {pet.name}
                              <IonAvatar>
                                <IonImg src={`${services.thumb(petImage, pet?.organism)}${petImage ? '&' : '?'}${pet.updated_at}`} />
                              </IonAvatar>
                            </IonLabel>
                          </IonItem>
                        }
                        )}
                        <IonItem className="input-radio">
                          <IonRadio value="_new" />
                          <IonLabel>{content.get('MY_ORDERS_NEW.FORM.STEP2.NEWCAT.INPUT_LABEL')}</IonLabel>
                        </IonItem>
                      </IonRadioGroup>
                    </IonItem>
                  </IonList>
                </IonCol>
              </Slider>
            </IonRow>
          </IonCardContent>

          {/* This if is to add more conditions to show outside Slider component */}
          {(errorMessageRepeated || errorMessage !== '' || errorMessageActivated !== '' || selectedPet === '_new') &&
            <IonCardContent className={styles.card_content}>
              <IonRow>
                <IonCol size-md="12" size="12">
                  <IonList className={`${styles.out_form} form`}>
                    {selectedPet === '_new' &&
                      <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                        <IonInput
                          name="newPet"
                          placeholder={content.get('MY_ORDERS_NEW.FORM.STEP2.NEWCAT.INPUT_PLACEHOLDER', [], true)}
                          required
                          value={newPet}
                          onIonChange={(e) => {
                            handleChange(e)
                            const nameValue = e.detail.value
                            if (emojiRegex.test(nameValue)) {
                              setNameError('Please enter valid name')
                            } else if (!emojiRegex.test(nameValue) && nameError !== '') {
                              setNameError('')
                            }
                            setNewPet(e.detail.value)
                          }}
                        />
                      </IonItem>
                    }
                    {errorMessageActivated !== '' &&
                      <IonItem className="ion-margin-bottom input-error-message">
                        <IonRow className="alert_message alert_form danger">
                          <IonCol>
                            <div className="input-error-label Body_2_bold">
                              <IonIcon icon={alertCircleSharp} color="danger" slot="start" />
                              {content.get('MY_ORDERS_NEW.FORM.STEP1.ERROR_MESSAGE')}
                            </div>
                            {editableContent
                              ? <p className="Body_2 text_dark_grey" >
                                {content.get(errorMessageActivated)}
                              </p>
                              : <p className="Body_2 text_dark_grey"
                                dangerouslySetInnerHTML={{ __html: content.get(errorMessageActivated, [], true) }}
                              >
                              </p>
                            }
                          </IonCol>
                        </IonRow>
                      </IonItem>
                    }
                    {errorMessage !== '' &&
                      <IonItem className="ion-margin-bottom input-error-message">
                        <IonRow>
                          <IonCol>
                            <div className="input-error-label">
                              <IonIcon icon={closeCircle} color="danger" slot="start" />
                              {content.get(errorMessage)}
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonItem>
                    }
                    {nameError !== '' &&
                      <IonItem className="ion-margin-bottom input-error-message">
                        <IonRow>
                          <IonCol>
                            <div className="input-error-label">
                              <IonIcon icon={closeCircle} color="danger" slot="start" />
                              {nameError}
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonItem>
                    }
                    {errorMessageRepeated &&
                      <IonItem className="ion-margin-bottom item-no-padding">
                        <IonRow className="alert_message alert_form warning">
                          <IonCol>
                            <div className="input-error-label Body_2_bold">
                              <IonIcon icon={alertCircleSharp} color="warning" slot="start" />
                              {content.get('MY_ORDERS_NEW.FORM.STEP2.INFORMATION_MESSAGE.TITLE')}
                            </div>
                            {editableContent
                              ? <p className="Body_2 text_dark_grey" >
                                {content.get('MY_ORDERS_NEW.FORM.ERRORS.KIT_ALREADY_IN_ANIMAL')}
                              </p>
                              : <p className="Body_2 text_dark_grey"
                                dangerouslySetInnerHTML={{ __html: content.get('MY_ORDERS_NEW.FORM.ERRORS.KIT_ALREADY_IN_ANIMAL', [], true) }}
                              >
                              </p>
                            }
                          </IonCol>
                        </IonRow>
                      </IonItem>
                    }
                  </IonList>
                </IonCol>
              </IonRow>
            </IonCardContent>
          }

          <div className={styles.card_footer}>
            <EditableIonButton
              type="button"
              mode="md"
              className={`default_button ${actualFormStep === 1 ? '' : styles.hidden}`}
              disabled={errorMessage !== '' || errorMessageActivated !== '' || !(isIDchanged && isIDRepeated) || !isCodeValidated || !!nameError}
              color="primary"
              fill="solid"
              shape="round"
              aria-label="Register a kit"
              onClick={() => {
                services.trackEvent({
                  category: 'Register a Kit',
                  action: 'Register - 1st step',
                  label: 'Button'
                })
                setActualFormStep(2)
                slider.current.slickNext()
              }}
            >
              {content.get('MY_ORDERS_NEW.FORM.BUTTON.SUBMIT')}
            </EditableIonButton>
            <LoaderButton
              type="submit"
              className={`default_button ${actualFormStep === 2 ? '' : styles.hidden}`}
              disabled={isSubmitting || selectedPet === '' || (selectedPet === '_new' && newPet === '') || !!nameError}
              color="primary"
              isSubmitting={isSubmitting}
              aria-label="Continue in order to register a kit"
              editableProps={{ handleSubmit: handleSubmit }}
            >
              {content.get('MY_ORDERS_NEW.FORM.BUTTON.CONTINUE')}
            </LoaderButton>
          </div>

          {actualFormStep === 2 &&
            <div className="mt-4">
              <hr className={styles.divider} />
              <p className="ion-text-center Body_1">
                {content.get('MY_ORDERS_NEW.FORM.STEP2.QUESTION.TITLE')}
                <a
                  aria-label="Go back to the previous step"
                  onClick={() => {
                    setActualFormStep(1)
                    setErrorMessage('')
                    setErrorMessageRepeated('')
                    setNameError('')
                    setSelectedPet('')
                    services.trackEvent({
                      category: 'Register a Kit',
                      action: 'Register - Back step',
                      label: 'Button'
                    })
                    slider.current.slickPrev()
                  }}
                  className="ml-2 cursor_pointer"
                >
                  {content.get('MY_ORDERS_NEW.FORM.STEP2.QUESTION.LINK_TEXT')}
                </a>
              </p>
            </div>
          }
        </form>
      )}
    </Formik>
  )
}

export default OrderNewForm
