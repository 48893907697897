import React from 'react'
import {
  withIonLifeCycle,
  IonRow,
  IonCol,
  IonGrid,
  IonCard,
  isPlatform
} from '@ionic/react'
import { closeSharp, lockClosed } from 'ionicons/icons'

import { connect } from 'react-redux'
import services from 'util/services'

import EditProfileForm from 'components/basepaws/account/EditProfileForm'
import ActionBlock from 'components/basepaws/account/ActionBlock'

import styles from './AccountEditProfile.module.scss'
import content from 'util/content'
import { DeactivateAccountModal } from './DeactivateAccountModal.js'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage.js'

class AccountEditProfile extends React.Component {
  constructor () {
    super()
    this.state = {
      modal: false
    }
  }

  setModal = () => {
    this.setState({ modal: true })
  };

  scrollCallback = (e) => {
    const mobile = !isPlatform('desktop') || isPlatform('iphone')
    if (mobile) {
      const el = document.getElementById('footer2')
      const footerFixed = document.getElementById('footerFixed2')
      const coords = el.getBoundingClientRect().top

      if (coords >= window.innerHeight) {
        footerFixed.style.display = 'block'
        const ios = isPlatform('ios')
        const delta = 1
        if (ios && (e.detail.deltaY > delta || e.detail.deltaY < -delta)) {
          footerFixed.style.bottom = coords - window.innerHeight * 0.88 + 'px'
        }
      } else {
        footerFixed.style.display = 'none'
      }
    }
  };

  render () {
    const user = this.props.user

    const form = (
      <IonCard className={styles.form_card}>
        <IonRow className="ion-justify-content-center">
          <IonCol size-md="8" size-lg="8" size="11">
            <EditProfileForm user={user} />
          </IonCol>
        </IonRow>
      </IonCard>
    )

    return (
      <GeneralPage
        user={user}
        title={content.get('EDIT_PROFILE.HEADER.TITLE')}
        headerStyle="center"
        actionStyle="left"
        scrollEvents={true}
        scrollCallback={this.scrollCallback}
        pageClass={`${styles.cat_single}`}
        isNotFixed={true}
      >
        <IonGrid fixed>
          {form}
          <div className="ion-margin-top">
            <ActionBlock
              title={content.get('PROFILE.ACTION_BLOCK.HEADER')}
              item_icon1={lockClosed}
              item_title1={content.get('PROFILE.ACTION_BLOCK.TITLE1')}
              item_text1={content.get('PROFILE.ACTION_BLOCK.TEXT1')}
              item_button_href1={content.get('PROFILE.ACTION_BLOCK.CTA1.LINK')}
              item_button_text1={content.get('PROFILE.ACTION_BLOCK.CTA1.TITLE')}
              item_disabled1={false}
              item_icon2={closeSharp}
              item_title2={content.get('PROFILE.ACTION_BLOCK.TITLE2')}
              item_text2={content.get('PROFILE.ACTION_BLOCK.TEXT2')}
              item_button_onclick2={this.setModal}
              item_button_text2={content.get('PROFILE.ACTION_BLOCK.CTA2.TITLE')}
              item_disabled2={false}
            />
            <DeactivateAccountModal
              modalState={this.state.modal}
              close={() => this.setState({ modal: false })}
            />
          </div>
        </IonGrid>
      </GeneralPage>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(
  mapStateToProps,
  null
)(withIonLifeCycle(AccountEditProfile))
