import React from 'react'
import Fuse from 'fuse.js'
import SelectSearch from 'react-select-search'
import './InputSelect.scss'

const InputSelect = (props :any) => {
  const fuzzySearch = (options: any) => {
    const fuse = new Fuse(options, {
      keys: ['name', 'groupName', 'items.name'],
      threshold: 0.1
    })

    return (value: any) => {
      if (!value.length) {
        return options
      }

      return fuse.search(value)
    }
  }

  return <>
    <SelectSearch
      search={true}
      filterOptions={fuzzySearch}
      {...props}
    />
    </>
}

export default InputSelect
