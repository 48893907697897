import React from 'react'
import { IonRow, IonCol, IonCard, IonIcon, IonAvatar, IonCardContent } from '@ionic/react'
import { arrowForwardCircleSharp } from 'ionicons/icons'
import { Link } from 'react-router-dom'

import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import contentEditableAware from 'components/basepaws/utils/hooks/contentEditableAware'
import content from 'util/content'
import services from 'util/services'

import styles from './CatListItem.module.scss'

const CatListItem = (props) => {
  const { pet } = props
  const editableContent = contentEditableAware()

  let ageText = ''

  if (pet?.born_on) {
    let diffInYears = 0
    let diffInMonths = 0
    let diffInDays = 0

    const start = new Date(pet?.born_on)
    const end = new Date()

    const diffInMs = Math.abs(end - start)
    diffInYears = parseInt(diffInMs / (1000 * 60 * 60 * 24 * 365))

    if (diffInYears === 0) {
      diffInMonths = parseInt(diffInMs / (1000 * 60 * 60 * 24 * 30))
      if (diffInMonths === 0) {
        diffInDays = parseInt(diffInMs / (1000 * 60 * 60 * 24))
      }
    }
    if (diffInYears > 0) {
      ageText = diffInYears + (diffInYears === 1 ? ' year old' : ' years old')
    }
    if (diffInYears === 0 && diffInMonths > 0) {
      ageText = diffInMonths + (diffInMonths === 1 ? ' month old' : ' months old')
    }
    if (diffInYears === 0 && diffInMonths === 0 && diffInDays > 0) {
      ageText = diffInDays + (diffInDays === 1 ? ' day old' : ' days old')
    }
  }

  const petImage = pet?.images.find((i) => i.hit === 0)

  const card = (
    <IonCard className={`${styles.my_cats_card} ${pet.death_on ? styles.memorial : ''} card`}>
      <IonCardContent>
        <IonRow className="ion-align-items-center ion-justify-content-between">
          <IonCol size-lg="1" size-md="2" size="3" className="ion-no-padding">
            <IonAvatar className="ion-margin-end">
              <img src={`${services.thumb(petImage, pet?.organism)}${petImage ? '&' : '?'}${pet.updated_at}`} alt={pet.name} />
            </IonAvatar>
          </IonCol>
          <IonCol size-lg="7" size-md="7" size="8" className={styles.col_title_gender}>
            <IonRow className='ion-align-items-center'>
              <IonCol size-md="6" size="12">
                <h2 className="Heading_3 ion-no-margin">{pet.name}</h2>
              </IonCol>
              <IonCol size-md="6" size="12">
                <h6 className={`${styles.cat_gender} Body_1 text_dark_grey`}>
                  {pet.death_on
                    ? <span className="text_medium_grey">{content.get('MY_CATS.MEMORIAM.EMPTY')}</span>
                    : pet.gender &&
                    `${pet.gender[0]?.toUpperCase()}${pet.gender.slice(1)} · ${ageText}`
                  }
                </h6>
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol size-lg="4" size-md="3" size="1" className="ion-no-padding ion-text-md-right">
            <div className={`${styles.buttons_wrapper} d-flex`}>
              <EditableIonButton
                mode="md"
                shape="round"
                fill='clear'
                routerLink={`/pets/${pet?.handle}`}
                slot="end"
                className="default_button"
                aria-label={`Go to ${pet.name} detail page`}
              >
                <IonIcon color="dark" slot="end" icon={arrowForwardCircleSharp} />
              </EditableIonButton>
            </div>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  )

  return (
    <>
      {editableContent
        ? card
        : <Link to={editableContent ? undefined : `/pets/${pet?.handle}`}>
          {card}
        </Link>
      }
    </>
  )
}

export default CatListItem
