import React from 'react'
import { IonButton, IonCol } from '@ionic/react'

import content from 'util/content'
import styles from './CatFilters.module.scss'

const Filters = (props) => {
  const { active, categoryHandler, filterNames } = props

  const filterNamesTemp = filterNames || [
    content.get('MY_CATS.FILTERS.TITLE1'),
    content.get('MY_CATS.FILTERS.TITLE2')
  ]

  return (
    <>
      <div>
        <IonCol size-md="4">
          {filterNamesTemp.map(
            (filter, index) => {
              return (
                <IonButton
                  key={index}
                  mode="md"
                  color="dark"
                  shape="round"
                  fill="clear"
                  onClick={() => {
                    categoryHandler(index)
                  }}
                  className={`Running_head_1 ${active === index ? styles.active : ''}`}
                >
                  {filter}
                </IonButton>
              )
            }
          )
          }
        </IonCol>
        {/* <IonButton
          mode="md"
          color="dark"
          shape="round"
          fill="clear"
          className={`Running_head_1 ${active === 0 ? styles.active : ''}`}
          onClick={() => {
            categoryHandler(0)
          }}
        >
          {content.get('MY_CATS.FILTERS.TITLE1')}
        </IonButton>
        <IonButton
          mode="md"
          color="dark"
          fill="clear"
          shape="round"
          className={`Running_head_1 ${active === 1 ? styles.active : ''}`}
          onClick={() => {
            categoryHandler(1)
          }}
        >
        {content.get('MY_CATS.FILTERS.TITLE2')}
        </IonButton> */}
      </div>
    </>
  )
}

export default Filters
