import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonImg,
  IonDatetime,
  isPlatform,
  IonList,
  IonItem,
  IonListHeader,
  IonInput,
} from "@ionic/react";

import EditableIonButton from "components/basepaws/utils/EditableIonButton";
import contentEditableAware from "components/basepaws/utils/hooks/contentEditableAware";
import DateInputDesktop from "components/basepaws/general/DateInputDesktop";
import content from "util/content";

import { Modal } from "./Modal";

export const ModalDeceased = (props) => {
  const { onClick, cat, close } = props;

  const [deathDate, setDeathDate] = useState();
  const [deathContact, setDeathContact] = useState(false);
  const [deathSuccess, setDeathSuccess] = useState(false);

  const editableContent = contentEditableAware();
  const desktop = isPlatform("desktop");

  const buttonsDiv = (
    <div className="d-flex ion-justify-content-between">
      <EditableIonButton
        mode="md"
        fill="outline"
        shape="round"
        color="dark"
        onClick={close}
        className="default_button"
      >
        {content.get("EDIT_CAT.MODAL.DECEASED.DISCARD")}
      </EditableIonButton>
      <EditableIonButton
        mode="md"
        disabled={!deathDate}
        fill="solid"
        shape="round"
        color="primary"
        className="default_button icon-start"
        onClick={() => {
          onClick({ death_on: deathDate, contact: deathContact });
          setDeathSuccess(true);
        }}
      >
        {content.get("EDIT_CAT.MODAL.DECEASED.CONFIRM")}
      </EditableIonButton>
    </div>
  );

  return (
    <Modal {...props} isContentTrue={false}>
      {!deathSuccess
       ? (
        <form>
          <IonRow className="ion-justify-content-center ion-justify-content-between">
            <IonCol size-md="12" size="12">
              <IonList className="form">
                <IonItem lines="none" className="item-no-padding">
                  <IonListHeader className="ion-no-padding">
                    <div>
                      <h3 className="Heading_3 text_sad_purple ion-padding-bottom">
                        {content.get("EDIT_CAT.MODAL.DECEASED.TITLE")}
                      </h3>
                      <h4 className="Heading_5 text_sad_purple">
                        {content.get(
                          "EDIT_CAT.MODAL.DECEASED.DATE_FIELD",
                          { name: cat?.name },
                          true
                        )}
                      </h4>
                    </div>
                  </IonListHeader>
                </IonItem>
                <IonItem
                  lines="none"
                  className="ion-margin-bottom item-no-padding"
                >
                  {desktop || (
                    <IonDatetime
                      name="death_on"
                      placeholder="Select a date"
                      value={deathDate}
                      displayFormat="MM/DD/YYYY"
                      className="w-100"
                      onIonChange={(e) => setDeathDate(e.detail.value)}
                    />
                  )}
                  {desktop && (
                    <DateInputDesktop
                      customInput={
                        <IonInput
                          className="w-100 ion-no-margin"
                          name="death_on"
                        />
                      }
                      dateFormatCalendar="MMMM"
                      dateOptions={{
                        startDate: deathDate,
                        setStartDate: setDeathDate,
                      }}
                      format="MM/dd/yyyy"
                      label="Select a date"
                      maxDate={new Date()}
                      name="death_on"
                      onChange={(date) => {
                        setDeathDate(date);
                      }}
                      placeholder="Select a date"
                      portal="#datepicker-portal"
                      selectRange={false}
                      selected={deathDate}
                      showYearDropdown
                    />
                  )}
                </IonItem>
              </IonList>
              <div className="ion-margin-bottom">
                <div className="alert_message bg_grey text_dark_grey no-margin">
                  {editableContent
                   ? (
                    <p className="Body_3">
                      {content.get(
                        "EDIT_CAT.MODAL.DECEASED.CONFIRMATION_MESSAGE"
                      )}
                    </p>
                  )
                  : (
                    <p
                      className="Body_3"
                      dangerouslySetInnerHTML={{
                        __html: content.get(
                          "EDIT_CAT.MODAL.DECEASED.CONFIRMATION_MESSAGE",
                          { name: cat?.name },
                          true
                        ),
                      }}
                    ></p>
                  )}
                </div>
              </div>
            </IonCol>
          </IonRow>
          {buttonsDiv}
        </form>
      )
      : (
        <IonRow>
          <IonCol
            size="12"
            className="ion-text-center ion-margin-top ion-padding-top"
          >
            <IonImg
              className="deceased-pet"
              src="assets/icon/basepaws-icon-deceased-pets.svg"
              alt="Deceased pet"
            />
            <h3 className="Heading_3 text_sad_purple ion-text-center">
              {content.get("EDIT_CAT.MODAL.DECEASED.SUCCES.TITLE")}
            </h3>
            <p className="Lead_2 text_grey ion-text-center">
              {content.get("EDIT_CAT.MODAL.DECEASED.SUCCESS.TEXT")}
            </p>
          </IonCol>
        </IonRow>
      )}
    </Modal>
  );
};
