import React, { useState } from 'react'
import { IonRow, IonCol, IonIcon, IonInput, IonList, IonItem, IonListHeader } from '@ionic/react'
import { alertCircleSharp } from 'ionicons/icons'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

import { Modal } from './Modal'
import content from 'util/content'
import contentEditableAware from 'components/basepaws/utils/hooks/contentEditableAware'

export const ModalDelete = (props) => {
  const { modalState, close, cat, onSubmit } = props

  const editableContent = contentEditableAware()
  const [catNameValidator, setCatNameValidator] = useState('')

  const ButtonsDiv = (props) => {
    const { disabled } = props
    return (
      <div className="d-flex ion-justify-content-between">
        <EditableIonButton
          mode="md"
          fill="outline"
          shape="round"
          color="dark"
          onClick={close}
          className="default_button"
        >
          {content.get('EDIT_CAT.MODAL.DELETE.DISCARD')}
        </EditableIonButton>
        <EditableIonButton
          mode="md"
          fill="solid"
          shape="round"
          color="danger"
          disabled={disabled ?? (catNameValidator !== cat?.name)}
          onClick={() => {
            close()
            onSubmit()
          }}
          className="default_button icon-start"
        >
          {content.get('EDIT_CAT.MODAL.DELETE.DELETE')}
        </EditableIonButton>
      </div>
    )
  }

  const ionInputFunction = (e) => {
    setCatNameValidator(e.target.value)
  }

  // Without information
  const withoutInformation =
    <>
      <IonRow className="ion-justify-content-center ion-justify-content-between">
        <IonCol size-md="12" size="12">
          <div className="ion-margin-bottom ion-padding-top">
            <div className="alert_message bg_grey text_dark_grey no-margin">
                {editableContent
                  ? <p className="Body_3" >
                    {content.get('EDIT_CAT.MODAL.DELETE.NO_INFO.CONFIRMATION_FIELD')}
                  </p>
                  : <p className="Body_3"
                      dangerouslySetInnerHTML={ { __html: content.get('EDIT_CAT.MODAL.DELETE.NO_INFO.CONFIRMATION_FIELD', { name: cat?.name }, true) }}
                    >
                    </p>
                }
            </div>
          </div>
        </IonCol>
      </IonRow>
      <ButtonsDiv disabled={false} />
    </>

  // With profile information
  const withProfileInformation =
      <>
        <IonRow className="ion-justify-content-center ion-justify-content-between">
          <IonCol size-md="12" size="12">
            <IonList className="form">
              <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                <IonRow className="alert_message alert_form warning">
                  <IonCol>
                    <div className="input-error-label Body_2_bold">
                      <IonIcon icon={alertCircleSharp} color="warning" slot="start"/>
                      {content.get('EDIT_CAT.MODAL.DELETE.WITH_INFO.NO_KITS.CONFIRMATION_TITLE')}
                    </div>
                    <p className="Body_2 text_dark_grey">
                      {content.get('EDIT_CAT.MODAL.DELETE.WITH_INFO.NO_KITS.TEXT')}
                    </p>
                  </IonCol>
                </IonRow>
              </IonItem>
              <IonItem lines="none" className="item-no-padding">
                <IonListHeader className="ion-no-padding">
                  <div>
                    {editableContent
                      ? <p className="Body_3" >
                        {content.get('EDIT_CAT.MODAL.DELETE.WITH_INFO.FIELD')}
                      </p>
                      : <p className="Body_3"
                          dangerouslySetInnerHTML={ { __html: content.get('EDIT_CAT.MODAL.DELETE.WITH_INFO.FIELD', { name: cat?.name }, true) }}
                        >
                        </p>
                    }
                  </div>
                </IonListHeader>
              </IonItem>
              <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                <IonInput
                  id="catName"
                  type="text"
                  name="catName"
                  onIonInput={ionInputFunction}
                  className=""
                  />
              </IonItem>
            </IonList>
          </IonCol>
        </IonRow>
        <ButtonsDiv />
      </>

  // with reports or kits
  const withReportsOrKits =
      <>
        <IonRow className="ion-justify-content-center ion-justify-content-between">
          <IonCol size-md="12" size="12">
            <IonList className="form">
              <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                <IonRow className="alert_message alert_form warning">
                  <IonCol>
                    <div className="input-error-label Body_2_bold">
                      <IonIcon icon={alertCircleSharp} color="warning" slot="start"/>
                      {content.get('EDIT_CAT.MODAL.DELETE.WITH_KITS.CONFIRMATION_TITLE')}
                    </div>
                    <p className="Body_2 text_dark_grey">
                      {content.get('EDIT_CAT.MODAL.DELETE.WITH_KITS.CONFIRMATION_TEXT')}
                    </p>
                  </IonCol>
                </IonRow>
              </IonItem>
              <IonItem lines="none" className="item-no-padding">
                <IonListHeader className="ion-no-padding">
                  <div>
                    {editableContent
                      ? <p className="Body_3" >
                        {content.get('EDIT_CAT.MODAL.DELETE.WITH_INFO.FIELD')}
                      </p>
                      : <p className="Body_3"
                          dangerouslySetInnerHTML={ { __html: content.get('EDIT_CAT.MODAL.DELETE.WITH_INFO.FIELD', { name: cat?.name }, true) }}
                        >
                        </p>
                    }
                  </div>
                </IonListHeader>
              </IonItem>
              <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                <IonInput
                  id="catName"
                  type="text"
                  name="catName"
                  onIonInput={ionInputFunction}
                  className=""
                  />
              </IonItem>
            </IonList>
          </IonCol>
        </IonRow>
        <ButtonsDiv />
      </>

  let modalContent = withoutInformation

  if (cat?.kits?.length > 0 || cat?.reports?.length > 0) {
    modalContent = withReportsOrKits
  } else {
    if (cat?.description ||
      cat?.images.length > 0 ||
      cat?.born_on
    ) {
      modalContent = withProfileInformation
    }
  }

  return (
    <Modal
      {...props}
      headingText='EDIT_CAT.MODAL.DELETE.TITLE'
      headingParam={cat?.name}
      isContentTrue={true}
      modalState={modalState}
      close={close}
      >
      {modalContent}
    </Modal>
  )
}
