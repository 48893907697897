import React, { useState } from 'react'

import { IonRow, IonCol, IonList, IonItem, IonInput, IonLabel, IonIcon, IonListHeader, IonCardContent, IonButton } from '@ionic/react'
import { closeCircle, eye, eyeOff, checkmarkCircle, ellipseOutline } from 'ionicons/icons'
import content from 'util/content'

import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import services from 'util/services'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import styles from './RecoverPasswordFormPassword.module.scss'

const RecoverPasswordFormPassword = (otp) => {
  const [passwordFieldType, setPasswordFieldType] = useState({
    currentPassword: 'password',
    currentPasswordIcon: eye,
    password: 'password',
    passwordIcon: eye,
    passwordRepeat: 'password',
    passwordRepeatIcon: eye
  })
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [passwordCheck, setPasswordCheck] = useState({
    uppercase: null,
    number: null,
    size: null,
    equal: null
  })

  const registerSchema = Yup.object().shape({
    password: Yup.string().test(
      'password-valid',
      '',
      () => passwordCheck.uppercase && passwordCheck.number && passwordCheck.size && passwordCheck.equal
    )
  })

  const history = useHistory()

  const onSubmit = async (values, setSubmitting) => {
    const passwords = {
      temporaryCode: otp.otp,
      password: password,
      password_repeat: passwordRepeat
    }
    setSubmitting(true)
    await services.post('/auth/changePassword', passwords)
    history.push('/recoverPasswordPassword/confirmation')
  }

  const onChangePassword = (e) => {
    const passwordLocal = e.target.value || ''

    const check = passwordCheck
    check.uppercase = /[A-Z]/.test(passwordLocal)
    check.number = /[0-9]/.test(passwordLocal)
    check.size = passwordLocal.length >= 6
    check.equal = (passwordLocal === passwordRepeat)

    setPassword(passwordLocal)
    setPasswordCheck(check)
  }

  const onChangePasswordRepeat = (e) => {
    const passwordLocal = e.target.value || ''

    const passwordEqual = (passwordLocal === password)
    setPasswordRepeat(passwordLocal)
    setPasswordCheck({
      ...passwordCheck,
      equal: passwordEqual
    })
  }

  const changeType = (field) => {
    const currentType = passwordFieldType[field]
    let nextType = 'password'
    let icon = eye
    if (currentType === 'password') {
      nextType = 'text'
      icon = eyeOff
    }
    setPasswordFieldType({
      ...passwordFieldType,
      [field]: nextType,
      [`${field}Icon`]: icon
    })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        temporaryCode: otp.otp,
        password: '',
        passwordRepeat: ''
      }}
      validationSchema={registerSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <IonCardContent className={styles.card_content}>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12">
                <IonList className="form ion-margin-top">
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding ion-margin-bottom">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('RECOVERPASSWORD.PASSWORD.FORM.NEWPASSWORD_TITLE')}</h4>
                        <p className="Body_3 text_dark_grey">{content.get('RECOVERPASSWORD.PASSWORD.FORM.NEWPASSWORD_DESCRIPTION')}</p>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonInput
                      className={`input-w-icon ${passwordCheck.number === false || passwordCheck.uppercase === false || passwordCheck.size === false ? 'has-error' : null}`}
                      type={passwordFieldType.password}
                      onIonInput={onChangePassword}
                      name="password"
                      clearOnEdit={false}
                      value={values.password}
                    />
                      <IonButton
                        className="showPasswordEyeBtn"
                        mode="md"
                        slot="end"
                        fill="clear"
                        tabindex="-1"
                        onClick={() => changeType('password')}
                      >
                        <IonIcon slot="icon-only" color="dark" icon={passwordFieldType.passwordIcon}></IonIcon>
                      </IonButton>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding">
                    <IonRow className="w-100">
                      <IonCol size="auto">
                        <EditableIonButton
                          mode="md"
                          className="button-form-check"
                          size="small"
                          fill="clear"
                          disabled
                        >
                          {passwordCheck.uppercase === null && <IonIcon slot="start" icon={ellipseOutline} />}
                          {passwordCheck.uppercase === true && <IonIcon slot="start" icon={checkmarkCircle} color="success" />}
                          {passwordCheck.uppercase === false && <IonIcon slot="start" icon={closeCircle} color="danger" />}
                          1 uppercase
                        </EditableIonButton>
                      </IonCol>
                      <IonCol size="auto">
                        <EditableIonButton
                          mode="md"
                          className="button-form-check ml-2"
                          size="small"
                          fill="clear"
                          disabled
                        >
                          {passwordCheck.number === null && <IonIcon slot="start" icon={ellipseOutline} />}
                          {passwordCheck.number === true && <IonIcon slot="start" icon={checkmarkCircle} color="success" />}
                          {passwordCheck.number === false && <IonIcon slot="start" icon={closeCircle} color="danger" />}
                          1 number
                        </EditableIonButton>
                      </IonCol>
                      <IonCol size="auto">
                        <EditableIonButton
                          mode="md"
                          className="button-form-check ml-2"
                          size="small"
                          fill="clear"
                          disabled
                        >
                          {passwordCheck.size === null && <IonIcon slot="start" icon={ellipseOutline} />}
                          {passwordCheck.size === true && <IonIcon slot="start" icon={checkmarkCircle} color="success" />}
                          {passwordCheck.size === false && <IonIcon slot="start" icon={closeCircle} color="danger" />}
                          6 characters
                        </EditableIonButton>
                      </IonCol>
                    </IonRow>
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding ion-margin-bottom">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('RECOVERPASSWORD.PASSWORD.FORM.CONFIRMPASSWORD_TITLE')}</h4>
                        <p className="Body_3 text_dark_grey">{content.get('RECOVERPASSWORD.PASSWORD.FORM.CONFIRMPASSWORD_DESCRIPTION')}</p>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                    <IonInput
                      className={`input-w-icon ${(passwordCheck.equal != null && passwordCheck.equal === false) || passwordCheck.number === false || passwordCheck.uppercase === false || passwordCheck.size === false ? 'has-error' : null}`}
                      type={passwordFieldType.passwordRepeat}
                      name="passwordRepeat"
                      onIonInput={onChangePasswordRepeat}
                      clearOnEdit={false}
                      value={values.passwordRepeat}
                    />
                      <IonButton
                        className="showPasswordEyeBtn"
                        mode="md"
                        slot="end"
                        fill="clear"
                        tabindex="-1"
                        onClick={() => changeType('passwordRepeat')}
                      >
                        <IonIcon slot="icon-only" color="dark" icon={passwordFieldType.passwordRepeatIcon}/>
                      </IonButton>
                  </IonItem>
                  {passwordCheck.equal != null && !passwordCheck.equal &&
                    <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                      <IonRow>
                        <IonCol>
                          <div className="input-error-label">
                            <IonIcon icon={closeCircle} color="danger" slot="start" />
                            {content.get('RECOVERPASSWORD.PASSWORD.FORM.ERROR.TITLE')}
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonItem>
                  }
                </IonList>
              </IonCol>
            </IonRow>
          </IonCardContent>
          <div className={`${styles.card_footer} ion-justify-content-center`}>
            <LoaderButton
              type="submit"
              disabled={isSubmitting}
              color="primary"
              className="default_button"
              isSubmitting={isSubmitting}
            >
              Save new password
            </LoaderButton>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default RecoverPasswordFormPassword
