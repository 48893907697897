import React from "react";
import { withIonLifeCycle, IonGrid } from "@ionic/react";

import { connect } from "react-redux";
import content from "util/content";

import SucessState from "components/basepaws/general/SucessState";
import EditableIonButton from "components/basepaws/utils/EditableIonButton";
import GeneralPage from "components/basepaws/general/GeneralPage/GeneralPage";

class AccountRecoverPasswordFormEmailConfirmation extends React.Component {
  render() {
    const user = this.props.user;

    return (
      <GeneralPage user={user} isNotFixed={true} header="none">
        <IonGrid fixed>
          <SucessState
            title={content.get("RECOVERPASSWORD.EMAIL.SUCCESS.TITLE")}
            text={content.get("RECOVERPASSWORD.EMAIL.SUCCESS.MESSAGE")}
            content={
              <EditableIonButton
                mode="md"
                color="primary"
                shape="round"
                className="ion-margin-top big_button"
                routerLink="/login"
              >
                {content.get("RECOVERPASSWORD.EMAIL.SUCCESS.BUTTON_TEXT")}
              </EditableIonButton>
            }
          />
        </IonGrid>
      </GeneralPage>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData,
  };
};

export default connect(
  mapStateToProps,
  null
)(withIonLifeCycle(AccountRecoverPasswordFormEmailConfirmation));
