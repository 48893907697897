import React from 'react'
import { IonPopover } from '@ionic/react'
import { NavLink } from 'react-router-dom'
import contentEditableAware from '../hooks/contentEditableAware'
import styles from './EditableNavLink.module.scss'

const EditableNavLink = (props) => {
  const editableContent = contentEditableAware()
  const [popoverState, setPopoverState] = React.useState({
    showPopover: false,
    event: null
  })

  const onMouseOverHandler = (e) => {
    e.persist()
    setPopoverState({ showPopover: true, event: e })
  }

  const handleClick = (e) => {
    if (editableContent) {
      e.preventDefault()
    } else {
      props.onClick && props.onClick(e)
    }
    setPopoverState({ showPopover: false, event: undefined })
  }

  const isAbsoluteURL = (url) => /^https?:\/\//.test(url);

  if (isAbsoluteURL(props.to)) {
      return (
          <a href={props.to} target="_blank" rel="noopener noreferrer">
              {props.children}
          </a>
      );
  }

  return (
    <>
    {editableContent &&
    <IonPopover
      cssClass={styles.editableBackdrop}
      mode="ios"
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={() => setPopoverState({ showPopover: false, event: undefined })}
    >
      <NavLink
        to={`${props.to || props.href}#key`}
        onClick={() => setPopoverState({ showPopover: false, event: undefined })}
      >
        Go to link ➚
      </NavLink>
    </IonPopover>
    }
    <NavLink
      {...props}
      onClick={handleClick}
      onMouseEnter={ editableContent ? onMouseOverHandler : undefined }
    >
      {props.children}
    </NavLink>
    </>
  )
}

export default EditableNavLink
