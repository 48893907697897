import React from 'react'
import { IonRow, IonCol, IonIcon, IonButton, IonCardHeader } from '@ionic/react'
import { removeCircleSharp, addCircleSharp } from 'ionicons/icons'
import './Accordion.scss'

export interface AccordionProps {
  list: Array<any>
  className?: string
  renderHeader: (item: any) => React.ReactNode
  renderPanel: (item: any) => React.ReactNode
  toggleVar?: boolean|Array<boolean>
  toggleFunction: any
  toggleIcons?: { iconOpen: any, iconClose: any }
  toggleIconPosition?: 'before' | 'after'
}

const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
  const {
    list,
    className,
    renderPanel,
    renderHeader,
    toggleVar,
    toggleFunction,
    toggleIcons,
    toggleIconPosition
  } = props

  const iconOpen = toggleIcons?.iconOpen ?? addCircleSharp
  const iconClose = toggleIcons?.iconClose ?? removeCircleSharp
  const position = toggleIconPosition ?? 'after'

  const headerClicked = (_event: any, index: number) => {
    _event.currentTarget.classList.toggle('active')
    /* Toggle close all panels, except on that was clicked */
    const allPanels = document.getElementsByClassName('panel')

    Array.from(allPanels).forEach((panel: any) => {
      if (_event.currentTarget.nextElementSibling !== panel) {
        panel.style.maxHeight = null
      }
      panel.previousElementSibling.classList.remove('active')
    })
    /* Toggle between hiding and showing the active panel */
    const panel = _event.currentTarget.nextElementSibling
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = null
      setTimeout(() => {
        panel.style.maxHeight = panel.scrollHeight + 'px'
      }, 100)
    }

    if ((typeof toggleVar === 'object')) {
      toggleFunction(index)
    } else {
      toggleFunction()
    }
  }

  return (
    <div>
      {list.map((item, index) => {
        return (
          <div key={index}>
            <button className={`accordion ${className}`} onClick={(e) => headerClicked(e, index)}>
              <IonCardHeader>
                <IonRow className="ion-align-items-center">
                { position === 'after' && renderHeader(item) }
                  <IonCol size-lg="1" size-md="6" size="6" className="ion-text-end">
                    <IonButton
                      mode="md"
                      fill="clear"
                      className="default_button"
                      aria-label="See more/less detail"
                      /* onIonFocus={() => {
                        const accordion = document.getElementsByClassName('accordion')
                        accordion.click()
                      }} */
                      >
                        {(typeof toggleVar === 'boolean') && <IonIcon icon={ toggleVar ? iconClose : iconOpen}></IonIcon> }
                        {(typeof toggleVar === 'object') && <IonIcon icon={toggleVar[index] ? iconClose : iconOpen}></IonIcon> }
                    </IonButton>
                  </IonCol>
                  { position === 'before' && renderHeader(item) }
                </IonRow>
              </IonCardHeader>
            </button>
            <div className="panel">{renderPanel(item)}</div>
          </div>
        )
      })}
    </div>
  )
}

export default Accordion
