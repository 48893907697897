import React from 'react'
import { IonRow, IonCol, useIonViewWillEnter } from '@ionic/react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import LoginForm from 'components/basepaws/account/LoginForm'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import contentEditableAware from 'components/basepaws/utils/hooks/contentEditableAware'

import { confirmUserAction } from 'util/utils'
import services from 'util/services'
import content from 'util/content'

const AccountLogin = () => {
  const user = useSelector(state => state.userInReducer.user)
  const editableContent = contentEditableAware()
  const history = useHistory()
  const location = useLocation()

  console.log('[BasePaws] v1.0.0')

  const load = async () => {
    const user = await services.user()
    if (user && user.id) {
      if (!user.roles.find((i) => i.name === 'admin') || !editableContent) {
        if (user == null || user?.id === '') history.push('/login')
        else history.push('/profile')
      }
    }
  }

  useIonViewWillEnter(async () => {
    console.log(user)
    if (!user?.id) {
      const query = location.hash
      await confirmUserAction(query)
      await load()
    } else {
      if (!user.roles.find((i) => i.name === 'admin') || !editableContent) {
        history.push('/profile')
      }
    }
  })

  return (
    <GeneralPage
      headerStyle='center'
      title={content.get('LOGIN.HEADER.TITLE')}
      titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom ion-text-center"
      user={user}
      showHomePageLink
    >
      <IonRow className="ion-justify-content-center">
        <IonCol size-lg="4" size-md="6" size="11">
          <LoginForm/>
        </IonCol>
      </IonRow>
    </GeneralPage>
  )
}

export default AccountLogin
