import React from 'react'
import { withIonLifeCycle, IonGrid } from '@ionic/react'
import { connect } from 'react-redux'

import content from 'util/content'
import SucessState from 'components/basepaws/general/SucessState'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'

class AccountCatNewConfirmation extends React.Component {
  render () {
    const user = this.props.user
    const handle = this.props.match.params.handle

    return (
      <GeneralPage
        user={user}
        isNotFixed={true}
        header='none'
        pageClass="page-404"
      >
        <IonGrid fixed>
          <SucessState
            title={content.get('MY_CATS.NEW.SUCCESS.TITLE')}
            text={content.get('MY_CATS.NEW.SUCCESS.MESSAGE')}
            content={
              <div className='d-flex ion-justify-content-center'>
                <EditableIonButton
                  mode="md"
                  color="primary"
                  shape="round"
                  fill='solid'
                  className="ion-margin-top big_button ion-margin-horizontal"
                  routerLink={`/pets/${handle}`}
                >
                  {content.get('MY_CATS.NEW.SUCCESS.BUTTON_TEXT')}
                </EditableIonButton>
                <EditableIonButton
                  mode="md"
                  color="primary"
                  shape="round"
                  fill='outline'
                  className="ion-margin-top big_button ion-margin-horizontal"
                  routerLink='/profile'
                >
                  {content.get('success_widget.right_cta.title')}
                </EditableIonButton>
              </div>
            }
          />
        </IonGrid>
      </GeneralPage>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(mapStateToProps, null)(withIonLifeCycle(AccountCatNewConfirmation))
