import React, { useState } from 'react'

import { IonRow, IonCol, IonList, IonItem, IonListHeader, IonInput, IonIcon } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import content from 'util/content'

import { Modal } from 'pages/account/AccountCatDetailEdit/Modal'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import services, { servicesNew } from 'util/services'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import Toaster from 'components/basepaws/utils/Toaster'

export const ModalSend = (props) => {
  const { modalState, close, report, reportName } = props
  const [sent, setSent] = useState(false)

  const modalHeaderDiv = (
    <div className="ion-margin-bottom">
      <div className="alert_message bg_grey text_dark_grey no-margin">
        <p className="Body_3">{content.get('MY_ORDERS.REPORT.SEND_MODAL.HEADER.MESSAGE')}</p>
      </div>
    </div>
  )

  const [isMailValid, setIsMailValid] = useState(true)
  const [friendInfo, setFriendInfo] = useState({
    name: 'Friend',
    email: '',
    phone: '',
    clinic: ''
  })

  const [isSubmitting, setSubmitting] = useState(false)
  const submitModalForm = async () => {
    services.trackEvent({
      category: 'Cat Detail Page - Reports - Send modal',
      action: `Send - ${reportName}`,
      label: 'Button'
    })
    setSubmitting(true)
    try {
      const match = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(friendInfo.email)
      if (match) {
        setIsMailValid(true)
        const share = await servicesNew.post(`/report/${report?.id}/share`, friendInfo)

        setSubmitting(false)
        // setIsChanged(false)
        setSent(true)
        close()
      } else {
        setIsMailValid(false)
        setSubmitting(false)
      }
    } catch (e) {
      console.log(e)
      setSubmitting(false)
      close()
    }
  }

  const modalInputs =
    <>
      <IonList className="form">
        <IonItem lines="none" className="item-no-padding">
          <IonListHeader className="ion-no-padding">
            <div>
              <h4 className="Heading_5 text_sad_purple">
              {content.get('MY_ORDERS.REPORT.SEND_MODAL.FORM.EMAIL_LABEL')}
              </h4>
            </div>
          </IonListHeader>
        </IonItem>
        <IonItem lines="none" className="ion-margin-bottom item-no-padding">
          <IonInput
            type="email"
            name="send_email"
            required
            onIonChange={(e) => setFriendInfo({ ...friendInfo, email: e.detail.value })}
            onIonFocus={() => { setIsMailValid(true) }}
          >
          </IonInput>
        </IonItem>
        {isMailValid === false &&
        <IonItem className="item-no-padding input-error-message">
          <IonRow>
            <IonCol>
              <div className="input-error-label">
                <IonIcon icon={closeCircle} color="danger" slot="start"/>
                {content.get('MY_ORDERS.REPORT.SHARE_MODAL.FORM.MAIL_ERROR')}
              </div>
            </IonCol>
          </IonRow>
        </IonItem>
        }
      </IonList>
    </>

  const buttonsDiv = (
    <div className="d-flex ion-justify-content-between">
      <EditableIonButton
        mode="md"
        fill="outline"
        shape="round"
        color="dark"
        aria-label="Close modal"
        onClick={close}
        className="default_button"
      >
        {content.get('MY_ORDERS.REPORT.SEND_MODAL.FORM.BUTTONS_DISCARD')}
      </EditableIonButton>
      <LoaderButton
        disabled={!friendInfo?.email}
        color="primary"
        className="default_button icon-start"
        isSubmitting={isSubmitting}
        aria-label="Send report"
        onClick={() => { submitModalForm() }}
      >
        {content.get('MY_ORDERS.REPORT.SEND_MODAL.FORM.BUTTONS_SUBMIT')}
      </LoaderButton>
    </div>
  )

  const contentWrapper =
    <>
      <IonRow className="ion-justify-content-center ion-justify-content-between">
        <IonCol size-md="12" size="12">
          {modalHeaderDiv}
          {modalInputs}
          {buttonsDiv}
        </IonCol>
      </IonRow>
    </>

  return (<>
    <Toaster
        status={sent}
        onDidDismiss={() => setSent(false)}
        header={content.get('MY_ORDERS.REPORT.SHARE_MODAL.TOAST.TITLE')}
        message={content.get('MY_ORDERS.REPORT.SHARE_MODAL.TOAST.TEXT')}
      />
    <Modal
      {...props}
      modalState={modalState}
      headingText='MY_ORDERS.REPORT.SEND_MODAL.TITLE'
      isContentTrue={false}
      close={close}
    >
      {contentWrapper}
    </Modal>
    </>)
}
