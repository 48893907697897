import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IonGrid, IonInput, withIonLifeCycle, IonCard, IonCol, IonRow, IonButton, IonItem, IonIcon } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import { Formik } from 'formik'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import content from 'util/content'
import services, { servicesNew } from 'util/services'
import LoaderButton from 'components/basepaws/utils/LoaderButton'
import useAdmin from 'components/basepaws/utils/hooks/useAdmin'
import { useHistory } from 'react-router'
import styles from './ContentPage.module.scss'
import { isHexColorValid } from 'util/constants'

const GetInner = (props: { field: string | any, last: string, afterDeleteFunction?: any }): any => {
  const { field, last, afterDeleteFunction } = props

  const keyPath = (key: string) => key.replace(/\./ig, '/').toLowerCase() + '/'

  const copyValue = (id: string, newValue: string) => {
    const el = document.getElementById(id) as HTMLInputElement
    el.value = newValue
  }

  const updateKey = (key: string, id: string) => {
    const el = document.getElementById(id) as HTMLInputElement
    const correctKey = keyPath(key)
    services.content(correctKey, el.value)
      .then((v) => console.log(v))
      .catch((e) => {
        console.log(e)
        setKeyChangeFailed(true)
      })
  }

  const deleteKey = (key: string) => {
    const correctKey = keyPath(key)
    services.contentDeleteKey(correctKey)
      .then((v) => {
        const newKeys = v['ui-account'] ?? v
        afterDeleteFunction(newKeys)
      })
      .catch((e) => {
        console.log(e)
        setKeyChangeFailed(true)
      })
  }

  const [keyChangeFail, setKeyChangeFailed] = useState(false)

  return Object.keys(field ?? '').map(subKey => {
    const newLast = last + subKey
    let ret: any = null
    if (typeof field[subKey] === 'object') {
      ret = <GetInner key={subKey} field={field[subKey]} last={newLast + '.'} afterDeleteFunction={afterDeleteFunction} />
    } else {
      if (typeof field[subKey] === 'string') {
        ret = (
            <IonRow key={subKey} className="form">
              <IonCol size-md="3" size="12">
                  <p className="Body_1">{newLast}</p>
              </IonCol>
              <IonCol size-md="3" size="12">
                  <p className="Body_1">{field[subKey]}</p>
              </IonCol>
              <IonCol size-md="3" size="12">
                <IonInput
                    id={newLast}
                    className="ion-margin-end"
                    onIonChange={(e) => {
                      field[subKey] = e.detail.value
                      console.log(field[subKey])
                    }}
                    onIonFocus={() => setKeyChangeFailed(false)}
                  />
              </IonCol>
              <IonCol size-md="3" size="12">
                <IonButton
                  className="default_button"
                  fill="solid"
                  shape="round"
                  color="primary"
                  onClick={() => updateKey(newLast, newLast)}
                >
                  Update
                </IonButton>
                <IonButton
                  className="default_button"
                  fill="clear"
                  shape="round"
                  color="primary"
                  onClick={() => copyValue(newLast, field[subKey])}
                >
                  Copy old value
                </IonButton>
                <IonButton
                  className="default_button"
                  fill="solid"
                  shape="round"
                  color="danger"
                  onClick={() => deleteKey(newLast)}
                >
                  Delete
                </IonButton>
                {keyChangeFail === true &&
                  <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                    <IonRow>
                      <IonCol>
                        <div className="input-error-label">
                          <IonIcon icon={closeCircle} color="danger" slot="start"/>
                          Something wrong happened, please try again later.
                        </div>
                      </IonCol>
                    </IonRow>
                </IonItem>
                }
              </IonCol>
            </IonRow>
        )
      } else {
        ret = field[subKey]
      }
    }
    return ret
  })
}

const ContentPage = (props: { user: any }) => {
  const { user } = props

  const history = useHistory()

  // although useAdmin has the "use" prefix, it is not a hook
  const isAdmin = useAdmin()
  useEffect(() => {
    // have to call an async function this way 'cause useEffect can't be async (it generates memory leaks)
    (async () => {
      if (isAdmin === false) {
        await servicesNew.logout()
        history.push('/login')
      }
    })()
  }, [isAdmin])

  const [contentTab, setContentTab] = useState('newKey') // newKey | importKey | exportKey
  const [copyContent, setCopyContent] = useState({ ...content })

  const [submitFailed, setSubmitFailed] = useState(false)

  const [topbarStatus, setTopbarStatus] = useState('')
  useEffect(() => {
    setTopbarStatus(content.get('NAVBAR.TOPBAR.ACTIVE'))
  }, [])

  const [isTopbarToggling, setTopbarToggling] = useState(false)

  const [topbarContent, setTopbarContent] = useState('')
  useEffect(() => {
    setTopbarContent(content.get('NAVBAR.TOPBAR.TEXT'))
  }, [])

  const [topbarColor, setTopbarColor] = useState(content.get('NAVBAR.TOPBAR.BACKGROUND.COLOR'))
  const [topbarTextColor, setTopbarTextColor] = useState(content.get('NAVBAR.TOPBAR.TEXT_COLOR'))

  const onSubmit = (values: any, setSubmitting: any): void => {
    console.log(values)
    if (values.key && values.value) {
      const key = values.key.replace(/\./ig, '/').toLowerCase() + '/'
      services.content(key, values.value)
        .then((v) => {
          setCopyContent({ ...copyContent, [values.key]: v })
          setSubmitting(false)
        })
        .catch((e) => {
          console.log(e)
          setSubmitting(false)
          setSubmitFailed(true)
        })
    }
  }

  const [uploadFile, setUploadFile] = useState()
  const [uploadKeyName, setUploadKeyName] = useState<string>()
  const [messageFile, setMessageFile] = useState('')

  const readContentFile = async (event: any) => {
    const file = event.target.files.item(0)
    if (file) {
      try {
        const text = await file.text()
        const json = JSON.parse(text)
        setUploadFile(json)
        setMessageFile('')
      } catch (e) {
        console.log(e)
        setUploadFile(undefined)
        setMessageFile('File is not a valid JSON file')
      }
    } else {
      // it comes here when cancelled
      setUploadFile(undefined)
    }
  }

  const onFileSubmit = async (event: any) => {
    event.preventDefault()

    if (uploadFile && uploadKeyName) {
      const newKeyResponse = await servicesNew.put(`/content/ui-account/${uploadKeyName}`, uploadFile, { headers: { 'Content-Type': 'application/json' } })
      setCopyContent({ ...copyContent, [uploadKeyName]: newKeyResponse })
    } else {
      setMessageFile('Please select a file and a key name')
    }
  }

  const checkFileExists = () => {
    let result
    try {
      result = content.get(uploadKeyName)
    } catch (e) {
      result = 'object'
    }
    return (result?.length > 0)
  }

  return (
    <GeneralPage
        user={user}
        pageClass={'page-404'}
        header={null}
      >
      <IonGrid fixed>
        <h1 className="Heading_3 text_sad_purple ion-margin-bottom ion-text-center">
          Content API
        </h1>
      </IonGrid>
      <IonRow className="ion-justify-content-center ion-margin-bottom ion-padding-bottom">
        <IonCol size-md="8">
          <IonCard className="card ion-margin-vertical ion-padding-vertical">
            <div className={styles.single_header}>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" className="ion-text-center"></IonCol>
                <nav className={styles.single_nav}>
                  <ul>
                    <li>
                      <button
                        className={`${contentTab === 'newKey' ? styles.active : ''}`}
                        onClick={() => setContentTab('newKey')}
                      >
                        New Key
                      </button>
                    </li>
                    <li>
                      <button
                        className={`${contentTab === 'importKey' ? styles.active : ''}`}
                        onClick={() => setContentTab('importKey')}
                      >
                        Import Key
                      </button>
                    </li>
                    <li>
                      <button
                        className={`${contentTab === 'exportKey' ? styles.active : ''}`}
                        onClick={() => setContentTab('exportKey')}
                      >
                        Export Key
                      </button>
                    </li>
                  </ul>
                </nav>
              </IonRow>
            </div>

            {contentTab === 'newKey' &&
            <Formik
              enableReinitialize={true}
              initialValues={{
                key: '',
                value: ''
              }}
              // validationSchema={registerSchema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmit(values, setSubmitting)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
                setFieldValue,
                setSubmitting
              }) => (
                <IonRow className="ion-justify-content-center">
                  <IonCol size="11">
                    <form className="form ion-text-center ion-padding-vertical" onSubmit={handleSubmit}>
                      <IonInput
                        id="key"
                        name="key"
                        onIonChange={handleChange}
                        onIonBlur={handleBlur}
                        onIonInput={() => setSubmitFailed(false)}
                        placeholder="Key Name"
                        className="ion-margin-bottom ion-text-start"
                      />
                      <IonInput
                        id="value"
                        name="value"
                        onIonChange={handleChange}
                        onIonBlur={handleBlur}
                        onIonInput={() => setSubmitFailed(false)}
                        placeholder="Content"
                        className="ion-margin-bottom ion-text-start"
                      />
                      <LoaderButton
                        type="submit"
                        href={''}
                        disabled={isSubmitting || values.key === '' || values.value === ''}
                        color="primary"
                        className="default_button"
                        isSubmitting={isSubmitting}
                      >
                        Save new key
                      </LoaderButton>
                      {submitFailed === true &&
                        <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                          <IonRow>
                            <IonCol>
                              <div className="input-error-label">
                                <IonIcon
                                  icon={closeCircle}
                                  color="danger"
                                  slot="start"
                                />
                                Something wrong happened, please try again later.
                              </div>
                            </IonCol>
                          </IonRow>
                      </IonItem>
                      }
                    </form>
                  </IonCol>
                </IonRow>
              )}
            </Formik>
            }

            {(contentTab === 'importKey' || contentTab === 'exportKey') &&
              <>
                <IonRow className="ion-justify-content-center">
                  <IonCol size="11">
                    <form className="form ion-text-center ion-padding-vertical" onSubmit={onFileSubmit}>

                      <IonRow>
                        <IonCol>
                          <IonInput
                            id="keyName"
                            name="keyName"
                            placeholder="Key name"
                            className="ion-margin-bottom ion-text-start"
                            onIonChange={(e) => setUploadKeyName(e.detail.value?.trim())}
                          />
                        </IonCol>
                      </IonRow>

                      {contentTab === 'importKey'
                        ? <IonRow className="ion-align-items-center">
                        <IonCol size-md='8' size="12" className="ion-text-start">
                          <input
                            id="file"
                            name="file"
                            type="file"
                            accept="application/json"
                            className={styles.file}
                            onChange={(e) => readContentFile(e)}
                          />
                        </IonCol>
                        <IonCol size-md='4' size="12" className="ion-text-end">
                          <LoaderButton
                            type="submit"
                            href={''}
                            disabled={!uploadFile || !uploadKeyName}
                            color="primary"
                            className="default_button"
                            isSubmitting={false}
                          >
                            Upload file
                          </LoaderButton>
                        </IonCol>
                      </IonRow>
                        : <IonCol size="4">

                        <IonButton
                          className="default_button"
                          shape="round"
                          href={`/2021-08/content/ui-account/${uploadKeyName?.replace(/\./ig, '/').toLowerCase() + '/'}`}
                          download={`${uploadKeyName}.json`}
                          disabled={!checkFileExists()}
                        >
                            Download
                        </IonButton>
                      </IonCol>
                      }
                    </form>
                  </IonCol>
                </IonRow>

                {messageFile && (
                <>
                <hr />
                  <IonRow className="ion-justify-content-center">
                    <IonCol size="11">
                      <p>{messageFile}</p>
                    </IonCol>
                  </IonRow></>
                )}
              </>
            }

          </IonCard>
        </IonCol>
      </IonRow>

      <IonRow className="ion-justify-content-center ion-margin-bottom ion-padding-bottom ion-padding-horizontal">
        <IonCol size-md="8">
          <hr/>
          <IonRow className="ion-align-items-center">
            <IonCol size-md="8">
              <p className="text_sad_purple"><span className="Body_1_bold">Adds topbar visibility:</span> <span className="Body_1">{topbarStatus}</span></p>
            </IonCol>
            <IonCol size-md="4" className="ion-text-end">
              <LoaderButton
                color='primary'
                disabled={isTopbarToggling}
                type={'button'}
                className=''
                href="#"
                isSubmitting={isTopbarToggling}
                onClick={() => {
                  setTopbarToggling(true)
                  console.log(content.get('NAVBAR.TOPBAR.ACTIVE'))
                  content.get('NAVBAR.TOPBAR.ACTIVE') === 'true'
                    ? services.content('navbar/topbar/active/', 'false')
                      .then((v) => {
                        console.log(v)
                        setTopbarStatus('false')
                        setTopbarToggling(false)
                        window.location.reload()
                      })
                      .catch((e) => {
                        console.log(e)
                      })
                    : services.content('navbar/topbar/active/', 'true')
                      .then((v) => {
                        console.log(v)
                        setTopbarStatus('true')
                        setTopbarToggling(false)
                        window.location.reload()
                      })
                      .catch((e) => {
                        console.log(e)
                      })
                }}
              >
                Switch visibility
              </LoaderButton>
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center form ion-margin-top">
            <IonCol size-md="8">
              <p className="Body_1 text_sad_purple"><span className="Body_1_bold">Changes topbar text (accepts HTML as well as plain text):</span></p>
              <IonInput
                value={content.get('NAVBAR.TOPBAR.TEXT')}
                onIonInput={(i: any) => {
                  setTopbarContent(i.target.value)
                }}
                className="ion-margin-bottom"
              />
            </IonCol>
            <IonCol size-md="4" className="ion-text-end">
              <LoaderButton
                color='primary'
                disabled={isTopbarToggling}
                type={'button'}
                className=''
                href="#"
                isSubmitting={isTopbarToggling}
                onClick={() => {
                  setTopbarToggling(true)
                  services.content('navbar/topbar/text/', topbarContent)
                    .then((v) => {
                      console.log(v)
                      setTopbarToggling(false)
                      window.location.reload()
                    })
                    .catch((e) => {
                      console.log(e)
                    })
                }}
              >
                Change text
              </LoaderButton>
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center form ion-margin-top">
            <IonCol size-md="8">
              <p className="Body_1 text_sad_purple"><span className="Body_1_bold">Changes topbar background color (accepts hex color codes. e.g. &quot;#ffffff&quot; for white) default=#25292E:</span></p>
              <IonInput
                value={content.get('NAVBAR.TOPBAR.BACKGROUND.COLOR')}
                onIonInput={(i: any) => {
                  setTopbarColor(i.target.value)
                }}
                className="ion-margin-bottom"
              />
            </IonCol>
            <IonCol size-md="4" className="ion-text-end">
              <LoaderButton
                color='primary'
                disabled={isTopbarToggling}
                type={'button'}
                className=''
                href="#"
                isSubmitting={isTopbarToggling}
                onClick={() => {
                  setTopbarToggling(true)
                  if (!isHexColorValid(topbarColor)) {
                    setTopbarToggling(false)
                    alert('Please enter a valid hex color code')
                    return
                  }
                  services.content('navbar/topbar/background/color', topbarColor)
                    .then((v) => {
                      console.log(v)
                      setTopbarToggling(false)
                      window.location.reload()
                    })
                    .catch((e) => {
                      console.log(e)
                    })
                }}
              >
                Change background color
              </LoaderButton>
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center form ion-margin-top">
            <IonCol size-md="8">
              <p className="Body_1 text_sad_purple"><span className="Body_1_bold">Changes topbar text color (accepts hex color codes. e.g. &quot;#ffffff&quot; for white) default=#fff:</span></p>
              <IonInput
                value={content.get('NAVBAR.TOPBAR.TEXT_COLOR')}
                onIonInput={(i: any) => {
                  setTopbarTextColor(i.target.value)
                }}
                className="ion-margin-bottom"
              />
            </IonCol>
            <IonCol size-md="4" className="ion-text-end">
              <LoaderButton
                color='primary'
                disabled={isTopbarToggling}
                type={'button'}
                className=''
                href="#"
                isSubmitting={isTopbarToggling}
                onClick={() => {
                  setTopbarToggling(true)
                  if (!isHexColorValid(topbarTextColor)) {
                    setTopbarToggling(false)
                    alert('Please enter a valid hex color code')
                    return
                  }
                  services.content('navbar/topbar/text_color', topbarTextColor)
                    .then((v) => {
                      console.log(v)
                      setTopbarToggling(false)
                      window.location.reload()
                    })
                    .catch((e) => {
                      console.log(e)
                    })
                }}
              >
                Change text color
              </LoaderButton>
            </IonCol>
          </IonRow>
          <hr/>
        </IonCol>
      </IonRow>
      <IonRow className="ion-margin-top">
        <IonCol size-md="3" size="12">
          <h6 className="Heading_6 text_black">
            Key
          </h6>
        </IonCol>
        <IonCol size-md="3" size="12">
          <h6 className="Heading_6 text_black">
            Current Value
          </h6>
        </IonCol>
        <IonCol size-md="3" size="12">
          <h6 className="Heading_6 text_black">
            New Value
          </h6>
        </IonCol>
        <IonCol size-md="3" size="12">
          <h6 className="Heading_6 text_black">
            Actions
          </h6>
        </IonCol>
      </IonRow>
      <GetInner field={copyContent} last={''} afterDeleteFunction={setCopyContent} />
    </GeneralPage>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.userInReducer.user
  }
}

export default connect(mapStateToProps, null)(withIonLifeCycle(ContentPage))
