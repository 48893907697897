import React from 'react'
import { IonRow, IonCol } from '@ionic/react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import content from 'util/content'

// import styles from './AccountProfile.module.scss'
import ProfileWelcomeCard from 'components/basepaws/account/ProfileWelcomeCard'
import ProfileAdvertisingCard from 'components/basepaws/account/ProfileAdvertisingCard'
import ProfileMyCatsCard from 'components/basepaws/account/ProfileMyCatsCard'
import ProfilePendingTasksCard from 'components/basepaws/account/ProfilePendingTasksCard'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'

const AccountProfile = () => {
  const user = useSelector(state => state.userInReducer.user)
  // const userConfirmedToast = useRef(false)

  // problem with replaceable content
  const headerTitle = content.get('dashboard.HEADER.TITLE', { name: user?.first_name })

  return (
    <GeneralPage
      title={headerTitle}
      titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom"
      user={user}
    >
      <IonRow>
        <IonCol size-lg="6" size="12">
          <ProfileWelcomeCard user={user}/>
        </IonCol>
        <IonCol size-lg="6" size="12" className="no-mobile">
          <ProfileAdvertisingCard/>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size-lg="6" size="12">
          <ProfileMyCatsCard user={user}/>
        </IonCol>
        <IonCol size-lg="6" size="12">
          { user && user.pets &&
          <ProfilePendingTasksCard
            pets={user?.pets?.filter((p) => !p.death_on)}
          />
          }
        </IonCol>
      </IonRow>
      <IonRow className="no-desktop">
        <IonCol size-lg="6" size="12">
          <ProfileAdvertisingCard/>
        </IonCol>
      </IonRow>
    </GeneralPage>
  )
}

export default AccountProfile
