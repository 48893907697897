import { updateObject } from '../../util/updateObject'
import { petRequiredFields, surveyCategories } from '../../components/basepaws/account/OrderListItem/constants'

const initialState = {
  pendingTasks: []
}

const setPendingTasks = (state = initialState, action) => {
  return updateObject(state, {
    pendingTasks: action.pendingTasks
  })
}

const catHasMissingFields = (cat) => {
  let isMissingField = false
  petRequiredFields.forEach((field) => {
    let catField = cat
    field.split('.').forEach((property) => {
      if (catField) { catField = catField[property] }
    })
    if (catField == null || catField === '') {
      isMissingField = true || isMissingField
    }
  })
  return isMissingField
}

const catHasPendingSurveys = (cat) => {
  const pendingSurvey = []
  if (cat.surveys.length > 0) {
    cat.surveys.forEach((survey) => {
      if (!survey.complete) {
        const pendingCategory = Object.values((surveyCategories())).find((c) => c.mappingIds.includes(survey.id))
        if (pendingCategory) {
          pendingSurvey.push({
            category: pendingCategory.category,
            ctaLink: survey.url
          })
        }
      }
    })
  }
  return pendingSurvey
}

const catHasAvailableResearchPrograms = (cat) => {
  const availableRP = []
  if (cat.organism === 'cat') { // https://github.com/basepaws/ui-accountApp/issues/178
    if (!cat.researchPrograms || cat.researchPrograms?.length === 0) {
      const rpData = surveyCategories().research_programs_survey
      availableRP.push({
        category: rpData.category
      })
    }
  }
  return availableRP
}

const calculatePendingTasks = (state, action) => {
  const pendingTasksLocal = []
  const pets = action.pets?.filter(pet => !pet.death_on)
  pets?.map((cat) => {
    if (catHasMissingFields(cat)) {
      pendingTasksLocal.push({
        category: 'complete_data',
        petHandle: cat.handle,
        action: 'complete'
      })
    }

    const pendingSurveys = catHasPendingSurveys(cat)
    if (pendingSurveys.length > 0) {
      pendingSurveys.forEach(element => {
        pendingTasksLocal.push({
          category: element.category,
          petHandle: cat.handle,
          ctaLink: element.ctaLink,
          action: 'answer'
        })
      })
    }

    const availableResearchPrograms = catHasAvailableResearchPrograms(cat)
    if (availableResearchPrograms.length > 0) {
      availableResearchPrograms.forEach(element => {
        pendingTasksLocal.push({
          category: element.category,
          petHandle: cat.handle,
          ctaLink: element.ctaLink,
          action: 'answer'
        })
      })
    }
    return true
  })
  action.pendingTasks = pendingTasksLocal
  return setPendingTasks(state, action)
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setPendingTasks': return setPendingTasks(state, action)
    case 'calculatePendingTasks': return calculatePendingTasks(state, action)
    default: return state
  }
}

export default reducer
