import services from './services'

interface IUserRole{
  name: string;

}
interface IUser{
  roles: IUserRole[];
}

function isUserAVet (user:IUser) {
  return user?.roles?.some((role) =>
    role.name.includes(':vet') ||
    role.name.includes(':admin'))
}

function getBasepawsPrefix (currentUrl:string) {
  let prefix = ''
  if (currentUrl.includes('/devs.')) {
    prefix = 'devs.'
  } else if (currentUrl.includes('/beta.')) {
    prefix = 'beta.'
  }

  // This is triggered only when you are running the app in local.
  // Since we can't know if (or where) you are locally running other basepaws apps, we return the "devs" prefix environment
  if (currentUrl.includes('http://localhost:')) {
    prefix = 'devs.'
  }

  return prefix
}

function getVetPortalUrl () {
  const currentUrl = window.location.href
  const prefix = getBasepawsPrefix(currentUrl)

  return `https://${prefix}vet.basepaws.com/`
}

function isUserAccessingReportWithoutAuth () {
  const error = new URLSearchParams(location.search).get('error')
  return error === 'not-authorized'
}

function isEmailAddressValid (potentialEmailAddress:string) {
  return !!potentialEmailAddress.match(/^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
}

async function confirmUserAction (query?:string) {
  if (query) {
    const data = query.split('/')
    const email = data[0].substring(1)
    const code = data[1]
    if (isEmailAddressValid(email) && code) {
      await services.post('/auth/confirm', { mail: email, code })
      return true
    }
  }
  return false
}

export { isUserAVet, getVetPortalUrl, isUserAccessingReportWithoutAuth, confirmUserAction }
