import React, { useRef, useState, useEffect } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonButton,
} from "@ionic/react";
import {
  addCircleSharp,
  chevronForwardOutline,
  chevronDownSharp,
} from "ionicons/icons";

import EditableIonButton from "components/basepaws/utils/EditableIonButton";
import EditableNavLink from "components/basepaws/utils/EditableNavLink";
import EmptyStateDashaboardCard from "components/basepaws/general/EmptyStateDashboardCard";
import content from "util/content";
import services from "util/services";

import ProfileCatCard from "./ProfileCatCard";
import styles from "./ProfileMyCatsCard.module.scss";

const ProfileMyCatsCard = (props) => {
  const { user } = props;
  const emptyPets = user?.pets?.filter((p) => !p.death_on).length === 0;
  const [lengthToShow, setLengthToShow] = useState(3);

  const [isLoadMore, setLoadMore] = useState(false);
  const cardRef = useRef(null);
  const cardContentRef = useRef(null);

  const cardContents = () => {
    if (!user || emptyPets) {
      return (
        <EmptyStateDashaboardCard
          text={content.get("DASHBOARD.MY_CATS.EMPTY.TITLE")}
        />
      );
    } else {
      return user?.pets
        ?.slice(0, lengthToShow)
        .filter((p) => !p.death_on)
        .map((pet, index) => <ProfileCatCard key={index} pet={pet} />);
    }
  };

  useEffect(() => {
    setLengthToShow(isLoadMore ? user?.pets?.length : 3);
  }, [isLoadMore]);

  useEffect(() => {
    setLoadMore(false);
  }, [user?.pets]);

  return (
    <IonCard
      ref={cardRef}
      className={`${styles.my_profile_welcome_card} ${
        emptyPets ? styles.my_profile_welcome_card_empty : ""
      } card card-dashboard-cats`}
    >
      <IonCardHeader>
        <div className="d-flex ion-align-items-center ion-justify-content-between">
          <EditableNavLink to="/pets" aria-label="Go to My pets page">
            <IonCardTitle className="Running_head_1 text_dark_grey mr-2">
              {content.get("DASHBOARD.MY_CATS.TITLE")}
            </IonCardTitle>
            <IonIcon slot="end" icon={chevronForwardOutline} />
          </EditableNavLink>
          <EditableIonButton
            mode="md"
            slot="end"
            shape="round"
            color="primary"
            fill="clear"
            onClick={() => {
              services.trackEvent({
                category: "My Profile Page",
                action: "Add a cat +",
                label: "Button",
              });
            }}
            routerLink={"/pet/new"}
            className="default_button"
          >
            <IonIcon slot="end" icon={addCircleSharp} />
            {content.get("DASHBOARD.MY_CATS.CTA_ADDCAT_TEXT")}
          </EditableIonButton>
        </div>
        {!emptyPets && (
          <div className={`${styles.message} alert_message bg_grey`}>
            <p>{content.get("DASHBOARD.MY_CATS.DESCRIPTION")}</p>
          </div>
        )}
      </IonCardHeader>
      <IonCardContent
        ref={cardContentRef}
        // style={ user?.pets?.length > 3 ? { height: cardDashboardHeight } : {}}
        className="card-dashboard-cats-content"
      >
        {cardContents()}
      </IonCardContent>
      <div className="ion-padding-start ion-padding-bottom">
        {user?.pets?.length > 3 && (
          <IonButton
            mode="md"
            slot="end"
            shape="round"
            color="primary"
            fill="clear"
            aria-label="Load more/less cats"
            onClick={() => {
              setLoadMore(!isLoadMore);
            }}
            className="icon-start default_button load_more"
          >
            <IonIcon
              slot="start"
              icon={chevronDownSharp}
              className={`${isLoadMore ? styles.icon_open : ""}`}
            ></IonIcon>
            {isLoadMore ? "Load Less" : "Load More"}
          </IonButton>
        )}
      </div>
    </IonCard>
  );
};

export default ProfileMyCatsCard;
