import React from 'react'
import {
  withIonLifeCycle,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel
} from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import { connect } from 'react-redux'

import services from 'util/services'
import content from 'util/content'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import CatListItem from 'components/basepaws/account/CatListItem'
import CatFilters from 'components/basepaws/account/catFilters'
import { withRouter } from 'react-router'
import styles from './AccountCat.module.scss'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import contentEditableAware from 'components/basepaws/utils/hooks/contentEditableAware'
import EmptyState from 'components/basepaws/general/EmptyState'

class AccountCat extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      categoryHandler: 0,
      filterHandler: null,
      sortingParameter: 'name',
      editableContent: contentEditableAware(),
      filteredOutput: []
    }
  }

  async ionViewWillEnter () {
    if (document.location.hash === '#inmemoriam') {
      this.setState({ categoryHandler: 1 })
      document.location.hash = ''
    } else {
      if (this.state.categoryHandler !== 0) {
        this.setState({ categoryHandler: 0 })
      }
    }
  }

  render () {
    const user = this.props.user
    const pets = this.props.user?.pets || []

    const sortingOptions = [
      {
        label: content.get('MY_CATS.SORT.OPTION1'),
        value: 'born_on'
      },
      {
        label: content.get('MY_CATS.SORT.OPTION2'),
        value: 'created'
      },
      {
        label: content.get('MY_CATS.SORT.OPTION3'),
        value: 'name'
      }
    ]

    const sortingMethod = (a, b) => {
      const parameter = this.state.sortingParameter
      if (parameter === 'name') {
        const name1 = a[parameter].toUpperCase()
        const name2 = b[parameter].toUpperCase()
        if (name1 === name2) return 0
        return name1 > name2 ? 1 : -1
      }
      const date1 = new Date(a[parameter])
      const date2 = new Date(b[parameter])
      return isNaN(date1) ? 1 : date2 > date1 ? 1 : -1
    }

    const categoryHandlers = [
      (element) => element.death_on === null || element.death_on === undefined,
      (element) => element.death_on !== null && element.death_on !== undefined
    ]

    const showLabels = pets.some(
      (pet) => pet.death_on !== null && pet.death_on !== undefined
    )

    return (
      <GeneralPage
        title={content.get('MY_CATS.HEADER.TITLE')}
        titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom"
        headerStyle="left"
        actionStyle="right"
        user={user}
        actions={
          <EditableIonButton
            mode="md"
            slot="end"
            color="primary"
            fill="clear"
            aria-label="Add a new cat"
            className={`${styles.toolbar_button} icon-end default_button`}
            onClick={() => {
              services.trackEvent({
                category: 'My Cats Page',
                action: 'Add a cat +',
                label: 'Button'
              })
            }}
            routerLink="/pet/new"
          >
            <IonLabel>{content.get('MY_CATS.HEADER.CTA_TITLE')}</IonLabel>
            <IonIcon color="primary" icon={addCircle} />
          </EditableIonButton>
        }
      >
        <IonRow className="ion-justify-content-center">
          {pets.length !== 0 && (
            <IonCol size="12">
              <CatFilters
                active={this.state.categoryHandler}
                categoryHandler={(i) => this.setState({ categoryHandler: i })}
                orderHandler={(i) => this.setState({ sortingParameter: i })}
                sortingOptions={sortingOptions}
                showFilters={showLabels}
              >
                {pets &&
                  pets
                    .filter(categoryHandlers[this.state.categoryHandler])
                    .sort(sortingMethod)
                    .map((pet) => {
                      return <CatListItem key={pet.id} pet={pet} />
                    })}
              </CatFilters>
            </IonCol>
          )}
          {pets.length === 0 ||
          (pets.find((pet) => !pet.death_on) === undefined &&
            this.state.categoryHandler === 0)
            ? (
            <EmptyState
              asset="assets/icons/data/pet.svg"
              assetType="image"
              content={
                <EditableIonButton
                  mode="md"
                  color="primary"
                  shape="round"
                  className="ion-margin-top big_button"
                  onClick={() => {
                    services.trackEvent({
                      category: 'My Cats Page',
                      action: 'Add your first cat',
                      label: 'Button'
                    })
                  }}
                  routerLink="/pet/new"
                >
                  {content.get('MY_CATS.EMPTY.CTA_TEXT')}
                </EditableIonButton>
              }
              text={content.get('MY_CATS.EMPTY.DESCRIPTION')}
              title={content.get('MY_CATS.EMPTY.TITLE')}
            />
              )
            : null}
        </IonRow>
      </GeneralPage>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(
  mapStateToProps,
  null
)(withRouter(withIonLifeCycle(AccountCat)))
