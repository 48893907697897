/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonListHeader,
  IonCheckbox,
  IonLabel,
  IonInput,
  IonIcon,
} from '@ionic/react'
import { closeCircle } from 'ionicons/icons'

import { Modal } from 'pages/account/AccountCatDetailEdit/Modal'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import Toaster from 'components/basepaws/utils/Toaster'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import services, { servicesNew } from 'util/services'
import content from 'util/content'
import { emailRegex } from 'util/constants'

export const ModalShare = (props) => {
  const {
    modalState,
    close,
    user,
    report,
    reportName,
    organism = 'cat',
  } = props

  const [vetInfo, setVetInfo] = useState({
    name: user?.vet?.name,
    email: user?.vet?.email,
    clinic: user?.vet?.clinic,
  })
  const [isMailValid, setIsMailValid] = useState(true)
  const [sent, setSent] = useState(false)
  const [isSavingToProfile, setIsSavingToProfile] = useState(false)
  const [toastKey, setToastKey] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const checkIsEmailValid = (email) => {
    const emailMatch = emailRegex.test(email)
    return emailMatch
  }

  useEffect(() => {
    if (isSavingToProfile) {
      setToastKey('MY_ORDERS.REPORT.SHARE_MODAL.TOAST.TEXT_DATA')
    } else {
      setToastKey('MY_ORDERS.REPORT.SHARE_MODAL.TOAST.TEXT')
    }
  }, [isSavingToProfile])

  useEffect(() => {
    if (vetInfo.email) {
      setIsMailValid(checkIsEmailValid(vetInfo.email))
    }
  }, [vetInfo.email])

  const modalHeaderDiv = (
    <div className="ion-margin-bottom">
      <div className="alert_message bg_grey text_dark_grey no-margin">
        <p className="Body_3">
          {content.get('MY_ORDERS.REPORT.SHARE_MODAL.HEADER.MESSAGE')}
        </p>
      </div>
    </div>
  )

  const submitModalForm = async () => {
    setSubmitting(true)
    services.trackEvent({
      category: 'Cat Detail Page - Reports - Share with Vet Modal',
      action: `Share with vet modal - ${reportName}`,
      label: 'Button',
    })
    try {
      if (checkIsEmailValid(vetInfo.email)) {
        setIsMailValid(true)

        let shareEndPoint = `/report/${report?.id}/share`
        const isNewSWV = organism !== 'cat'
        if (isNewSWV) {
          shareEndPoint = `/report/${report?.id}/sharewv`
        }

        await servicesNew.post(shareEndPoint, vetInfo)

        if (isSavingToProfile) {
          const newData = {
            ...user,
            vet: {
              name: vetInfo.name,
              email: vetInfo.email,
              clinic: vetInfo.clinic,
            },
          }
          const userEdited = await services.setUser(newData)
          setIsSavingToProfile(false)
        }

        setSent(true)
        setSubmitting(false)
        close()
      } else {
        setIsMailValid(false)
        setSubmitting(false)
      }
    } catch (e) {
      console.log(e)
      setSubmitting(false)
      close()
    }
  }

  const modalInputs = (
    <IonList className="form">
      <IonItem lines="none" className="item-no-padding">
        <IonListHeader className="ion-no-padding">
          <div>
            <h4 className="Heading_5 text_sad_purple">
              {content.get('PROFILE.FORM.VET.FIELDS.VET_NAME')}
            </h4>
          </div>
        </IonListHeader>
      </IonItem>
      <IonItem lines="none" className="ion-margin-bottom item-no-padding">
        <IonInput
          type="text"
          name="vet_name"
          required
          onIonInput={(event) => {
            setVetInfo({
              ...vetInfo,
              name: event.target.value,
            })
          }}
          value={user?.vet?.name}
        ></IonInput>
      </IonItem>
      <IonItem lines="none" className="item-no-padding">
        <IonListHeader className="ion-no-padding">
          <div>
            <h4 className="Heading_5 text_sad_purple">
              {content.get('PROFILE.FORM.VET.FIELDS.VET_CLINIC')}
            </h4>
          </div>
        </IonListHeader>
      </IonItem>
      <IonItem lines="none" className="ion-margin-bottom item-no-padding">
        <IonInput
          type="text"
          name="vet_clinic"
          onIonInput={(event) => {
            setVetInfo({
              ...vetInfo,
              clinic: event.target.value,
            })
          }}
          value={user?.vet?.clinic}
        ></IonInput>
      </IonItem>
      <IonItem lines="none" className="item-no-padding">
        <IonListHeader className="ion-no-padding">
          <div>
            <h4 className="Heading_5 text_sad_purple">
              {content.get('PROFILE.FORM.VET.FIELDS.VET_EMAIL')}
            </h4>
          </div>
        </IonListHeader>
      </IonItem>
      <IonItem lines="none" className="item-no-padding">
        <IonInput
          type="email"
          inputmode="email"
          name="vet_email"
          required
          onIonInput={(event) => {
            // if (event.target.value !== user?.vet?.email) { setIsChanged(true) } else (setIsChanged(false))
            setVetInfo({
              ...vetInfo,
              email: event.target.value,
            })
          }}
          value={user?.vet?.email}
        ></IonInput>
      </IonItem>

      {isMailValid === false && (
        <IonItem className="item-no-padding input-error-message">
          <IonRow>
            <IonCol>
              <div className="input-error-label">
                <IonIcon icon={closeCircle} color="danger" slot="start" />
                {content.get('MY_ORDERS.REPORT.SHARE_MODAL.FORM.MAIL_ERROR')}
              </div>
            </IonCol>
          </IonRow>
        </IonItem>
      )}
    </IonList>
  )

  const addVetInfo = (
    <IonItem lines="none" className="ion-margin-bottom item-no-padding">
      <IonLabel className="checkbox-label ion-no-margin">
        {content.get('MY_ORDERS.REPORT.SHARE_MODAL.FORM.CHECKBOX_SAVE')}
      </IonLabel>
      <IonCheckbox
        id="deceased"
        name="deceased"
        slot="start"
        color="primary"
        value={isSavingToProfile}
        onIonChange={(e) => {
          setIsSavingToProfile(e.detail.checked)
        }}
      />
    </IonItem>
  )

  const buttonsDiv = (
    <div className="d-flex ion-justify-content-between">
      <EditableIonButton
        mode="md"
        fill="outline"
        shape="round"
        color="dark"
        onClick={close}
        aria-label="Close modal"
        className="default_button"
      >
        {content.get('MY_ORDERS.REPORT.SHARE_MODAL.FORM.BUTTONS_DISCARD')}
      </EditableIonButton>
      <LoaderButton
        disabled={
          isSubmitting || !(vetInfo.email && vetInfo.name) || !isMailValid
        }
        color="primary"
        className="default_button icon-start"
        isSubmitting={isSubmitting}
        aria-label="Share report"
        onClick={() => {
          submitModalForm()
        }}
      >
        {content.get('MY_ORDERS.REPORT.SHARE_MODAL.FORM.BUTTONS_SUBMIT')}
      </LoaderButton>
    </div>
  )

  const modalContents = (
    <IonRow className="ion-justify-content-center ion-justify-content-between">
      <IonCol size-md="12" size="12">
        {modalHeaderDiv}
        {modalInputs}
        {addVetInfo}
        {buttonsDiv}
      </IonCol>
    </IonRow>
  )

  return (
    <>
      <Toaster
        status={sent}
        onDidDismiss={() => setSent(false)}
        header={content.get('MY_ORDERS.REPORT.SHARE_MODAL.TOAST.TITLE')}
        message={content.get(toastKey)}
      />
      <Modal
        {...props}
        modalState={modalState}
        headingText="MY_ORDERS.REPORT.SHARE_MODAL.TITLE"
        isContentTrue={false}
        close={close}
      >
        {modalContents}
      </Modal>
    </>
  )
}
