import React from 'react'
import { connect } from 'react-redux'
import { withIonLifeCycle, IonGrid } from '@ionic/react'

import SucessState from 'components/basepaws/general/SucessState'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import content from 'util/content'

class AccountRecoverPasswordFormEmailConfirmation extends React.Component {
  render () {
    const user = this.props.user

    return (
      <GeneralPage
        user={user}
        isNotFixed={true}
        header='none'
      >
        <IonGrid fixed>
          <SucessState
            title={`${content.get('RECOVERPASSWORD.EMAIL.REQUEST.TITLE')}`}
            text={`${content.get('RECOVERPASSWORD.EMAIL.REQUEST.CONTENT')}`}
          />
        </IonGrid>
      </GeneralPage>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(mapStateToProps, null)(withIonLifeCycle(AccountRecoverPasswordFormEmailConfirmation))
