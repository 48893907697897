import configureStore from 'store/configureStore'
import * as actions from 'store/actions/index'

const store = configureStore()

store.setUser = (user) => {
  store.dispatch(actions.setUser(user))
}

store.setPets = (pets) => {
  store.dispatch(actions.setPets(pets))
}

store.setPendingTasks = (pendingTasks) => {
  store.dispatch(actions.setPendingTasks(pendingTasks))
}

store.calculatePendingTasks = (payload) => {
  store.dispatch(actions.calculatePendingTasks(payload))
}

export default store
