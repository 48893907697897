import React, { useContext } from 'react'

import content from 'util/content'
import services from 'util/services.js'
import { arrowBackSharp } from 'ionicons/icons'
import { storeUrl } from 'util/constants.ts'

import { IonHeader, IonGrid, IonToolbar, IonButtons, IonTitle, IonButton, IonIcon } from '@ionic/react'
import BackButton from '../BackButton'

import styles from './Header.module.scss'

const Header = (props) => {
  const { headerStyle, title, actions, actionStyle, topbarStyle, goBackUrl, HandlerStepContext, showHomePageLink } = props

  const headerClass = `
  ${!actions ? 'no_button' : 'with_button'}
  ${headerStyle === 'center' ? styles.header_center : styles.header_left}
  ${(actionStyle === 'left' && headerStyle === 'center') ? styles.header_action_left : ''}
  `

  let setActualFormStep = null
  let actualFormStep = null
  if (HandlerStepContext) {
    [actualFormStep, setActualFormStep] = useContext(HandlerStepContext)
  }

  const isOptionalTopBarShown = !!services.topbarCheck(content.get('NAVBAR.TOPBAR.TEXT'))
  const toolBarAdditionalClass = isOptionalTopBarShown ? 'toolBarWithOptionalBar' : 'pageHeaderWithOptionalBarOff'

  return (
    <>
    <IonHeader mode='md' className={`${styles.header} ${headerClass} ${topbarStyle}`}>
      <IonGrid fixed>
        <IonToolbar class={toolBarAdditionalClass}>
          {showHomePageLink &&
            (<a className={styles.top_link} href={`${storeUrl}`}>
            <IonIcon onIcon icon={arrowBackSharp}></IonIcon>
            Go Back to Homepage
          </a>)}
          <IonTitle className="Display_4 text_sad_purple">{title}</IonTitle>
          {(actionStyle === 'left' && !HandlerStepContext) &&
          <IonButtons slot="start" className="no_mobile">
            <BackButton goBackUrl={goBackUrl}/>
          </IonButtons>
          }

          {(actionStyle === 'left' && HandlerStepContext) &&
          <IonButtons slot="start" className="no_mobile">
            <HandlerStepContext.Provider value={[actualFormStep, setActualFormStep]}>
              <BackButton HandlerStepContext={HandlerStepContext} goBackUrl={goBackUrl}/>
            </HandlerStepContext.Provider>
          </IonButtons>
          }

          {actionStyle === 'right' &&
            <IonButtons slot="end">
              {actions}
            </IonButtons>
          }
        </IonToolbar>
      </IonGrid>
    </IonHeader>
    </>
  )
}

export default Header
