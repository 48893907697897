import React, { useEffect, useState } from 'react'
import styles from './CatFilters.module.scss'
import { IonCol, IonCard, IonRow, IonGrid, IonButton, IonIcon, IonInput } from '@ionic/react'
import DateInputDesktop from 'components/basepaws/general/DateInputDesktop/'

import SortByButton from './SortByButton'
import Filters from './Filters'
import SearchBar from './SearchBar'

const SearchSortFilter = (props) => {
  const {
    enabledFeatures,
    // filter props
    activeFilter,
    filterNames,
    categoryHandler,
    // sort props
    defaultOrder,
    orderHandler,
    sortIn,
    sortingOptions,
    // search props
    searchIn,
    searchPlaceholder,
    fieldsToSearch,
    setResultFunction,
    align,
    rightElement
  } = props

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [searchText, setSearchText] = useState('')

  const pass = (input) => input

  useEffect(() => {
    const searchRegExp = new RegExp(searchText, 'i')
    let repFiltered = searchIn.filter(report => {
      return fieldsToSearch.map((field) => searchRegExp.test(report[field])).some(pass)}
    )
    if (startDate && endDate) {
      repFiltered = repFiltered.filter(report => {
        const date = new Date(Date.parse(report.date, "dd D MMM, H:mm A"))
        endDate.setHours(23, 59, 59, 999) // to include the last day
        return date >= startDate && date <= endDate
      })
    }
    setResultFunction([...repFiltered])
  }, [startDate, endDate, searchText])

  return (
    <IonCol size="12">
      <IonCard className={styles.card_cat_filters}>
        <IonGrid fixed>
          <IonRow className={`ion-align-items-center ion-justify-content-${align ?? 'between'}`}>
            {enabledFeatures.search &&
              <SearchBar
                placeholder={searchPlaceholder}
                searchIn={searchIn}
                fieldsToSearch={fieldsToSearch}
                setResultFunction={setResultFunction}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            }
            {enabledFeatures.filterByDate &&
              <DateInputDesktop
                customInput={<IonInput
                  className={`${styles.input_w_300} mb-2`}
                  name='filter_by_date'
                />}
                dateFormatCalendar="MMMM"
                dateOptions = {{ startDate, endDate, setStartDate, setEndDate }}
                format="MM/dd/yyyy"
                label="Select a date"
                maxDate={new Date()}
                name="filter_by_date"
                onChange={(dates) => {
                  const [start, end] = dates
                  setStartDate(start)
                  setEndDate(end)
                }}
                placeholder="Select a date"
                portal="#datepicker-portal"
                selectRange={true}
                selected={startDate}
                showYearDropdown
              />
            }
            {((startDate && endDate) || searchText) &&
            <IonButton
              fill="clear"
              mode="md"
              color="text_sad_purple"
              shape="round"
              onClick={() => {
                setStartDate(null)
                setEndDate(null)
                setSearchText('')
              }}
              >
              <IonIcon icon={close} slot="start" />
              Discard filter
            </IonButton>
            }
            {enabledFeatures.filter &&
              <Filters
                active={activeFilter}
                categoryHandler={categoryHandler}
                filterNames={filterNames}
              />
            }
            {enabledFeatures.sort &&
              <SortByButton
                defaultOrder={defaultOrder}
                orderHandler={orderHandler}
                options={sortingOptions}
                sortIn={sortIn}
                setResultFunction={setResultFunction}
              />
            }
            {(rightElement && ((startDate && endDate) || searchText) && sortIn.length > 0) && rightElement}
          </IonRow>
        </IonGrid>
      </IonCard>
    </IonCol>
  )
}

export default SearchSortFilter
