import React from 'react'
import { IonRow, IonCol } from '@ionic/react'

const EmptyStateDashaboardCard = (props) => {
  const { text } = props

  return <IonRow>
    <IonCol size="12">
      <h2 className="Heading_5 text_sad_purple">{text}</h2>
    </IonCol>
  </IonRow>
}

export default EmptyStateDashaboardCard
