import React from 'react'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonRow, IonCol } from '@ionic/react'
import content from 'util/content'
import styles from './ProfileAdvertisingCard.module.scss'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import contentEditableAware from 'components/basepaws/utils/hooks/contentEditableAware'
import services from 'util/services'

const AdvertisingCardItem = (props) => {
  const editableContent = contentEditableAware()
  return (
    <IonCard className={`${styles.my_profile_welcome_card} card`}>
    <IonCardHeader>
      <IonCardTitle className="Running_head_1 text_white">{content.get('DASHBOARD.BANNER.TITLE')}</IonCardTitle>
    </IonCardHeader>
    <IonCardContent>
      <IonRow>
        <IonCol size-lg="8" size-md="10" size="9">
          <h2 className="Heading_3 text_white">{content.get('DASHBOARD.BANNER.SUBHEAD')}</h2>
          <p className="Body_1 text_white">{content.get('DASHBOARD.BANNER.DESCRIPTION')}</p>
        </IonCol>
      </IonRow>
    </IonCardContent>
    <div className={styles.card_footer}>
      <EditableIonButton
        mode="md"
        slot="end"
        shape="round"
        color="light"
        fill="solid"
        onClick={() => {
          services.trackEvent({
            category: 'My Profile Page',
            action: 'Register a kit',
            label: 'Button'
          })
        }}
        routerLink={content.get('DASHBOARD.BANNER.CTA_LINK', [], editableContent)}
        className="mr-2 default_button"
        >
          {/* <IonIcon slot="start" icon={enterSharp} /> */}
          {content.get('DASHBOARD.BANNER.CTA_TITLE')}
        </EditableIonButton>
    </div>
    <img src="assets/images/basepaws-banner-dental-test.png" alt="Cat holding a Basepaws's dental kit with its claws" />
    </IonCard>
  )
}

export default AdvertisingCardItem
