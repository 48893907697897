import React from 'react'

import { IonCardContent, IonIcon, IonRow, IonCol, IonCardHeader, IonCardTitle } from '@ionic/react'
import { arrowForward, calendarNumberSharp, flashSharp, clipboardSharp, checkmarkSharp } from 'ionicons/icons'
import content from 'util/content'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

import styles from './SurveyCard.module.scss'
import services from 'util/services'

const SurveyCard = (props) => {
  const { survey } = props

  const ctaFullText = 'Complete'

  const categories = {
    phenotype_survey: {
      title: 'SURVEY_CARD.CATEGORY.PHENOTYPE.TITLE',
      text: 'SURVEY_CARD.CATEGORY.PHENOTYPE.TEXT',
      mainCategory: 'survey',
      ctaLink: 'ctaLink',
      icon: clipboardSharp
    },
    flash_survey: {
      title: 'SURVEY_CARD.CATEGORY.FLASH.TITLE',
      text: 'SURVEY_CARD.CATEGORY.FLASH.TEXT',
      mainCategory: 'survey',
      ctaLink: 'ctaLink',
      icon: flashSharp
    },
    recurring_survey: {
      title: 'SURVEY_CARD.CATEGORY.RECURRING.TITLE',
      text: 'SURVEY_CARD.CATEGORY.RECURRING.TEXT',
      mainCategory: 'survey',
      ctaLink: 'ctaLink',
      icon: calendarNumberSharp
    }
  }

  if (survey.title.toLowerCase().indexOf('phenotype') === 0) {
    survey.type = 'phenotype_survey'
  } else {
    if (survey.title.toLowerCase().indexOf('coach') === 0) {
      survey.type = 'recurring_survey'
    } else {
      survey.type = 'flash_survey'
    }
  }

  return (
    <>
      <IonCardHeader className={styles.cat_single_card_header}>
        <div><IonIcon color="primary" slot="start" icon={categories[survey.type].icon}/></div>
        <div className={`${styles.cat_single_card_subheader} w-100`}>
          <IonRow className="ion-justify-content-between ion-align-items-center">
            <IonCol size-lg="8" size="12">
              <IonCardTitle className="Heading_4 ion-text-start">{content.get(categories[survey.type].title)}</IonCardTitle>
            </IonCol>
            {survey.last_updated &&
            <IonCol size-lg="4" size="12" className="ion-text-end">
              <div className={`${styles.cat_single_card_date} d-flex ion-justify-content-end`}>
                <h4 className="Running_head_3 text_dark_grey ion-no-margin mr-3">{content.get('MY_ORDERS.SURVEYCARD.LAST_UPDATED')}</h4>
                <h4 className="Running_head_3 text_black ion-no-margin">{survey.last_updated}</h4>
              </div>
            </IonCol>
            }
          </IonRow>
        </div>
      </IonCardHeader>

      <IonCardContent className={styles.card_content}>
        <p className="Body_1 text_grey">{content.get(categories[survey.type].text)}</p>
      </IonCardContent>

      {/* Full state */}
      { (survey.status === 'completed')
        ? <div className={styles.card_footer}>
            <div className={`${styles.my_orders_card_header_state} d-flex ion-align-items-center ion-justify-content-between`}>
              <span className='tags completed d-flex ion-align-items-center'>
                <IonIcon color="light" slot="end" className="mr-2" icon={checkmarkSharp}/>
                {content.get('MY_ORDERS.SURVEYCARD.TAG_COMPLETED')}
              </span>
            </div>
        </div>
        : <div className={`${styles.card_footer} ${styles.card_footer_empty}`}>
        <EditableIonButton
          mode="md"
          color="primary"
          fill="solid"
          shape="round"
          onClick={() => {
            services.trackEvent({
              category: 'Cat Detail Page - Surveys',
              action: `${survey.title} Complete`,
              label: 'Button'
            })
          }}
          href={survey.url_complete}
          target="_blank"
          className="default_button"
        >
          {ctaFullText}
          <IonIcon color="light" slot="end" icon={arrowForward}/>
        </EditableIonButton>
      </div>
      }
    </>
  )
}

export default SurveyCard
