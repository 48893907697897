import {
  alertCircleSharp,
  checkmarkCircleSharp,
  refreshCircleSharp,
  calendarNumberSharp,
  clipboardSharp,
  flashSharp
} from 'ionicons/icons'

import content from 'util/content'
import contentEditableAware from 'components/basepaws/utils/hooks/contentEditableAware'

const editableContent = contentEditableAware()

const KitTypes = () => ({
  'whole genome sequencing': {
    includes: [
      {
        title: 'Breed + Health DNA Test',
        icon: '/assets/icon/basepaws-icon-report-type-health-markers.svg'
      },
      {
        title: 'Dental Health Test',
        icon: '/assets/icon/basepaws-icon-report-type-dental.svg'
      }
      // missing raw data here
    ]
  },
  'breed + health dna test': {
    title: 'Breed + Health DNA Test',
    includes: [
      {
        title: 'Breed + Health DNA Test',
        icon: '/assets/icon/basepaws-icon-report-type-health-markers.svg'
      }
    ]
  },
  breed: {
    includes: [
      {
        title: 'Breed',
        icon: '/assets/icon/basepaws-icon-report-type-health-markers.svg'
      }
    ]
  },
  'dental health': {
    includes: [
      {
        title: 'Dental Health',
        icon: '/assets/icon/basepaws-icon-report-type-dental.svg'
      }
    ]
  }
})

const KitStatus = () => [
  {
    text: content.get('KIT.STATUS.REGISTERED.TEXT'),
    simpleText: content.get('KIT.STATUS.REGISTERED.SIMPLE_TEXT'),
    icon: checkmarkCircleSharp,
    frontStatus: 'registered',
    backStatus: [
      'creating',
      'shipping',
      'registered',
      'activated'
    ],
    statusDetail: content.get('KIT.STATUS.REGISTERED.DESCRIPTION', [], editableContent)
  },
  {
    text: content.get('KIT.STATUS.RECEIVED.TEXT'),
    simpleText: content.get('KIT.STATUS.RECEIVED.SIMPLE_TEXT'),
    icon: checkmarkCircleSharp,
    frontStatus: 'received',
    backStatus: [
      'receiving'
    ],
    statusDetail: content.get('KIT.STATUS.RECEIVED.DESCRIPTION', [], editableContent)
  },
  {
    text: content.get('KIT.STATUS.PROCESSING.TEXT'),
    simpleText: content.get('KIT.STATUS.PROCESSING.SIMPLE_TEXT'),
    icon: refreshCircleSharp,
    frontStatus: 'processing',
    backStatus: [
      'extraction',
      'preparation',
      'sentToSequencing',
      'sequencing',
      'analysis',
      'in progress',
      'report',
      'reported'
    ],
    statusDetail: content.get('KIT.STATUS.PROCESSING.DESCRIPTION', [], editableContent)
  },
  {
    text: content.get('KIT.STATUS.COMPLETED.TEXT'),
    simpleText: content.get('KIT.STATUS.COMPLETED.SIMPLE_TEXT'),
    icon: checkmarkCircleSharp,
    frontStatus: 'completed',
    backStatus: [
      'completed',
      'released'
    ],
    statusDetail: content.get('KIT.STATUS.COMPLETED.DESCRIPTION', [], editableContent)
  },
  {
    text: content.get('KIT.STATUS.FAILED.TEXT'),
    simpleText: content.get('KIT.STATUS.FAILED.SIMPLE_TEXT'),
    icon: alertCircleSharp,
    frontStatus: 'failed',
    backStatus: [
      'failed',
      'fail'
    ],
    statusDetail: content.get('KIT.STATUS.FAILED.DESCRIPTION', [], editableContent)
  }
]

const adoptedOptions = [
  {
    id: 'street',
    label: 'CAT_FORM.FORM.FIELD.HOW_ADOPTED_OPTIONS.OPTION1'
  },
  {
    id: 'shelter',
    label: 'CAT_FORM.FORM.FIELD.HOW_ADOPTED_OPTIONS.OPTION2'
  },
  {
    id: 'friendFamily',
    label: 'CAT_FORM.FORM.FIELD.HOW_ADOPTED_OPTIONS.OPTION3'
  },
  {
    id: 'petShop',
    label: 'CAT_FORM.FORM.FIELD.HOW_ADOPTED_OPTIONS.OPTION4'
  },
  {
    id: 'breeder',
    label: 'CAT_FORM.FORM.FIELD.HOW_ADOPTED_OPTIONS.OPTION5'
  },
  {
    id: 'other',
    label: 'CAT_FORM.FORM.FIELD.HOW_ADOPTED_OPTIONS.OPTION6'
  }
]

const petRequiredFields = [
  'name',
  'born_on',
  'gender',
  'birth_location.city',
  'birth_location.adopted',
  'description',
  'images.0'
]

const surveyCategories = () => ({
  phenotype_survey: {
    mainCategory: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.SUBHEAD'),
    category: 'phenotype_survey',
    text: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.MESSAGE', { surveytype: 'Phenotype Survey', name: '__NAME__', action: '__ACTION__' }),
    ctaLink: 'ctaLink',
    ctaText: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.CTA_TEXT'),
    icon: clipboardSharp,
    mappingIds: [42, 'zcaiE5Cc']
  },
  flash_survey: {
    mainCategory: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.SUBHEAD'),
    category: 'flash_survey',
    text: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.MESSAGE', { surveytype: 'Flash Survey', name: '__NAME__', action: '__ACTION__' }),
    ctaLink: 'ctaLink',
    ctaText: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.CTA_TEXT'),
    icon: flashSharp,
    mappingIds: []
  },
  recurring_survey: {
    mainCategory: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.SUBHEAD'),
    category: 'recurring_survey',
    text: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.MESSAGE', { surveytype: 'Recurring Survey', name: '__NAME__', action: '__ACTION__' }),
    ctaLink: 'ctaLink',
    ctaText: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.CTA_TEXT'),
    icon: calendarNumberSharp,
    mappingIds: [33]
  },
  research_programs_survey: {
    mainCategory: content.get('DASHBOARD.PENDING_TASKS.TYPE.RESEARCH_PROGRAMS.SUBHEAD'),
    category: 'research_programs_survey',
    text: content.get('DASHBOARD.PENDING_TASKS.TYPE.RESEARCH_PROGRAMS.MESSAGE', { name: '__NAME__', action: '__ACTION__' }),
    ctaLink: '/pets/__HANDLE__#researchPrograms',
    ctaText: content.get('DASHBOARD.PENDING_TASKS.TYPE.RESEARCH_PROGRAMS.CTA_TEXT'),
    icon: clipboardSharp,
    mappingIds: [42]
  },
  complete_data: {
    mainCategory: content.get('DASHBOARD.PENDING_TASKS.TYPE.EMPTYDATA.SUBHEAD'),
    category: 'complete_data',
    text: content.get('DASHBOARD.PENDING_TASKS.TYPE.EMPTYDATA.MESSAGE', { name: '__NAME__' }),
    ctaLink: '/pets/__HANDLE__/edit',
    ctaText: content.get('DASHBOARD.PENDING_TASKS.TYPE.EMPTYDATA.CTA_TEXT'),
    icon: clipboardSharp,
    mappingIds: []
  }
})

export { KitStatus }
export { KitTypes }
export { surveyCategories }
export { adoptedOptions, petRequiredFields }
